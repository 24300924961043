<div id="header" [class.home]="type == 'home'" [class.search-page]="type == 'search-page'">
    <div id="header-container" [class.search-top]="type == 'search-top'">
        <div id="logo-container">
            <a href="/">
                <div id="dtyt-logo">
                    <img src="/assets/imgs/dtyt-logo.svg" class="white">
                    <img src="/assets/imgs/dtyt-logo-gray.svg" class="gray">
                </div>
            </a>
        </div>
        <div class="container-calendario">
            <a routerLink="/calendario">
                <span class=" btn-calendario ">ir a Calendario</span>
            </a>
        </div>
        <app-menu></app-menu>
        <div class="clearfix"></div>
    </div>
</div>