import { Component, OnInit } from '@angular/core';
import { ImageInfo } from 'src/app/interfaces/image-info.interface';

@Component({
  selector: 'app-privacidad',
  templateUrl: './privacidad.component.html',
  styleUrls: ['./privacidad.component.scss']
})
export class PrivacidadComponent implements OnInit {

  public sugerencias = [];
  public hasSugerenciaGrupo = false;
  public hasSugerenciaPaquete = false;
  public showModal = false;
  public modalType = '';
  public tutoqueCountrySelected = '';

  public loadingContact = false;
  public nameTxt;
  public phoneTxt;
  public mailTxt;
  public msgTxt;
  public successMsg = false;
  public errorMsg = false;
  public fieldsError = '';
  public myRecaptcha = false;

  public pageImgs =
    {
      'cabezal': new ImageInfo('/assets/imgs/rifas-universitarias-detoqueytoque2', ['_xs', '_sm', '_md', '_lg', '_xl'], '.png', '', '_xl'),
      //'cabezal': new ImageInfo('/assets/imgs/rifas-universitarias-detoqueytoque', ['_xs', '_sm', '_md', '_lg', '_xl'], '.png', 'Vista de ángulo bajo del edificio Capitol en gran vía Madrid España', '_xl'),
      'afiche': new ImageInfo('/assets/imgs/premios-rifa-detoqueytoque', ['_xs', '_sm', '_md'], '.png', 'Diferentes atracciones turísticas y premios de rifas', '_md'),
      'tuToqueFooter': new ImageInfo('/assets/imgs/viajes-a-medida', ['_xs', '_sm', '_md', '_lg'], '.png', '', '_lg'),
    };

    // SUbida a git con pagina nueva privacidad

  constructor() { }

  ngOnInit(): void {
  }

}
