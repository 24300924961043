import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { ContactService } from '../../services/contact.service';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ImageInfo } from 'src/app/interfaces/image-info.interface';
import { GruposService } from 'src/app/services/grupos.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public loadingContact = false;
  public successMsg = false;
  public errorMsg = false;
  public nameTxt = '';
  public phoneTxt = '';
  public mailTxt = '';
  public msgTxt = '';
  public fieldsError = '';
  public myRecaptcha = false;
  public viajesDestacados = [];

  public pageImgs = {
    cardExpediciones: new ImageInfo(
      '/assets/imgs/home/viajes-grupales',
      ['_xs', '_sm'],
      '.png',
      'Mercado Musulmán',
      '_xs'
    ),
    cardTutoque: new ImageInfo(
      '/assets/imgs/home/viajes-a-medida',
      ['_xs', '_sm'],
      '.png',
      'Mochilero viajando por la montaña',
      '_xs'
    ),
    cardUnivs: new ImageInfo(
      '/assets/imgs/home/viajes-universitarios',
      ['_xs', '_sm'],
      '.png',
      'Amigos viajando juntos',
      '_xs'
    ),
    card360: new ImageInfo(
      '/assets/imgs/home/pasajes-baratos',
      ['_xs', '_sm'],
      '.png',
      'Aeropuerto con muchos aviones',
      '_xs'
    ),
    sabanas: new ImageInfo(
      '/assets/imgs/home/viaje-africa',
      ['_xs', '_sm'],
      '.png',
      'Leones en un safari en África',
      '_sm'
    ),
    caminos: new ImageInfo(
      '/assets/imgs/home/viaje-machu-picchu',
      ['_xs', '_sm'],
      '.png',
      'Paisaje de Machu Picchu, una de las 7 maravillas del mundo',
      '_xs'
    ),
    postales: new ImageInfo(
      '/assets/imgs/home/viaje-nueva-zelanda',
      ['_xs', '_sm'],
      '.png',
      'Paisaje de Nueva Zelanda, recorrida en Motorhome',
      '_xs'
    ),
    cuba: new ImageInfo(
      '/assets/imgs/home/viaje-cuba',
      ['_xs', '_sm'],
      '.png',
      'Calle típica cubana, con sus autos antiguos y callejones ',
      '_sm'
    ),
    caminoSantiago: new ImageInfo(
      '/assets/imgs/home/viaje-camino-de-santiago',
      ['_xs', '_sm'],
      '.png',
      '',
      '_sm'
    ),
    egitpo: new ImageInfo(
      '/assets/imgs/home/viaje-egipto',
      ['_xs', '_sm'],
      '.png',
      '',
      '_sm'
    ),
    turquia: new ImageInfo(
      '/assets/imgs/home/viaje-turquia',
      ['_xs', '_sm'],
      '.png',
      '',
      '_sm'
    ),
    homeBottom1: new ImageInfo(
      '/assets/imgs/home/grupo-en-egipto',
      ['_xs', '_sm'],
      '.webp',
      'Grupo de viaje en Egipto',
      '_sm'
    ),
    homeBottom2: new ImageInfo(
      '/assets/imgs/home/viaje-bus-nepal',
      ['_xs', '_sm'],
      '.png',
      'Grupo de viaje en bus desde katmandú a Pokhara',
      '_xs'
    ),
    homeBottom3: new ImageInfo(
      '/assets/imgs/home/baobab-en-safari',
      ['_xs', '_sm'],
      '.png',
      'Árbol baobab y camioneta de Safari en Tanzania',
      '_xs'
    ),
    homeBottom4: new ImageInfo(
      '/assets/imgs/home/tuctuc-india',
      ['_xs', '_sm'],
      '.png',
      'Tuc Tuc en Delhi con grupo de viajeros',
      '_sm'
    ),
  };

  constructor(
    private router: Router,
    public globalConstants: GlobalConstants,
    private contactService: ContactService,
    private grupoService: GruposService,
    @Inject(PLATFORM_ID) private platformId: any,
    private title: Title,
    private meta: Meta
  ) {
    this.title.setTitle(
      this.globalConstants.getTitlePrefix() + 'Una nueva forma de viajar'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Viajes grupales y viajes a medida de una forma diferente. Conocé los destinos desde dentro y viví las experiencias y emociones que soñaste',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'viajes grupales, aventura, viajes Asia, viajes exóticos, viajes África, viajes grupales uruguay, salidas grupales desde uruguay, viajes alternativos, viajes de aventura',
    });

    this.globalConstants.setPageWithWhatsappBtn(true);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.add('home');
    }

    this.getViajesDestacados();
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.remove('home');
      document.body.classList.remove('smallHeader');
      document.body.classList.remove('fixed-column');
    }
    this.globalConstants.setPageWithWhatsappBtn(false);
  }

  getSlug(pTxt, separator = '_') {
    return pTxt
      .toLowerCase()
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u')
      .replace(/ñ/gi, 'n')
      .replace(/ \/ /gi, ' ')
      .split(' ')
      .join(separator);
  }

  getViajesDestacados() {
    this.grupoService.getViajes().subscribe((res: any) => {
      // console.log(res);
      this.viajesDestacados = res.data.filter(
        (item) => item.viaje.viajeDestacado
      );
      console.log(this.viajesDestacados);
    });
  }

  send() {
    this.fieldsError = '';

    if (
      this.nameTxt != '' &&
      this.mailTxt != '' &&
      this.phoneTxt != '' &&
      this.msgTxt != '' &&
      this.globalConstants.validateEmail(this.mailTxt) &&
      this.myRecaptcha
    ) {
      this.loadingContact = true;

      this.contactService
        .sendContact(this.nameTxt, this.phoneTxt, this.mailTxt, this.msgTxt)
        .subscribe((res: any) => {
          this.loadingContact = false;
          if (res.status == 'success') {
            this.successMsg = true;
          } else {
            this.errorMsg = true;
          }
        });
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }

  navigate(link: string) {
    if (link != undefined) {
      this.router.navigate([link]);
    }
  }
}
