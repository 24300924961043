import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() type;

  public menuOpened = false;
  public mobile = false;
  @Input() smallHeader = false;
  @Input() image = '';



  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768) {
        this.mobile = true;
        document.body.classList.add('mobile-device');
      } else {
        this.mobile = false;
        document.body.classList.add('desktop-device');
        // this.openSearch = true;
      }
    }


  }

  menuAction() {
    if (this.menuOpened ) {
      this.menuOpened = false;
    } else {
      this.menuOpened = true;
    }
  }

  @HostListener('window:scroll', ['$event'])
  smallHeaderAction(event) {
    if (isPlatformBrowser(this.platformId)) {

      let scrollTop = window.pageYOffset;
      let elementOffset:any = document.getElementsByClassName('search-bar-container')[0];
      if( elementOffset != null) {
        elementOffset = elementOffset.getBoundingClientRect().top;
      }
      let distance = (elementOffset - scrollTop);

      if (this.type == 'home') {
        if (distance < 0) {
          document.body.classList.add('smallHeader');
          if (distance > -50) {
            document.body.classList.remove('smallHeader');
          }
        } else {
          document.body.classList.remove('smallHeader');
        }
      } else if( this.type != 'search-page' ) {
        if (window.pageYOffset > window.innerHeight) {
          document.body.classList.add('smallHeader');
          if (distance > -50) {
            document.body.classList.remove('smallHeader');
          }
        } else {
          document.body.classList.remove('smallHeader');
        }
      }
    }
  }
}

