<app-header type="search-top"></app-header>
<!-- <app-top-full-banner title="Rifas Experiencia al Mundo" description="Una herramienta esencial para alcanzar sueños, teniendo en cuenta y considerando de la mejor forma a quienes colaboran." generalClass="rifas" [image]="pageImgs.cabezal"></app-top-full-banner>-->
<app-top-full-banner-rifas [image]="pageImgs.cabezal"></app-top-full-banner-rifas><br><br>
<div id="test" class="section-container column-section">
    <div class="section-wrapper container section-wrapper-medium-desktop">
        <div>
            <div class="title">Gracias por formar parte de este viaje.</div><br>
            <div class="description">Reconocemos que sin esta rifa viajar y festejar el fin de una etapa sería
                imposible.
                Por eso queremos agradecerles a todos los compradores por apoyar este sueño de explorar los lugares más
                recónditos del mundo. ¡¡Mucha suerte!!
            </div><br><br>
            <div class="section-title">
                Fechas de los sorteos
            </div><br>
            <div class="fechas-calendar">
                <div class="fecha-item" *ngFor="let sorteo of sorteos">
                    <div class="fecha-item-header">
                        {{sorteo.nombre}}
                    </div>
                    <div class="fecha-item-body">
                        <div class="sorteo-date">
                            {{sorteo.dia}}
                        </div>
                        <div class="sorteo-month">
                            de {{sorteo.mes}}
                        </div>
                    </div>
                </div>
            </div> <br><br>
            <div class="section-title">
                Números ganadores
            </div>
            <div class="row" *ngFor="let sorteo of sorteos">
                <div class="sorteo-list-header">
                    <div class="sorteo-list-number">
                        {{sorteo.nombre}}
                    </div>
                    <div class="sorteo-list-date">
                        {{sorteo.fecha}}
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="sorteo-list">
                    <div class="sorteo-row" [ngClass]="{'gray' : i%2==0, 'white' : i%2!=0}"
                        *ngFor="let premio of sorteo.premios; let i = index">
                        <div class="sorteo-row-number">
                            {{premio.posicion}}
                        </div>
                        <!--<div class="sorteo-row-icon">
                        </div>-->
                        <div class="sorteo-row-text" [ngClass]="{'extra' : premio.posicion == 'Extra'}">
                            {{premio.premio}}
                        </div>
                        <div class="sorteo-row-ganadoor">
                            {{premio.ganador}}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div><br>

            <div class="row">
                <div class="section-title">
                    Condiciones para el comprador:
                </div>
                <div class="section-description">
                    A los compradores de la rifa de Toque y Toque:
                    <br><br>
                    Antes que nada queremos agradecerles. Están ayudando a muchas personas a poder cumplir un sueño de
                    viajar con compañeros y compañeras de facultad luego de terminar una etapa larga y linda.
                    <br><br>
                    Desde Toque & Toque nos esforzamos año a año en poder realizar una rifa atractiva para que más allá
                    de la ayuda y la intención que hay en cada comprador, esté la posibilidad de ganar lindos y buenos
                    premios.
                    <br><br>
                    Todos los sorteos se realizarán bajo la supervisión de la División Inspección de la Dirección
                    Nacional de Loterías y Quinielas, escribano público por parte de la DNLQ y escribano público
                    particular de Toque & Toque.
                    <br><br>
                    Los mismos podrán ser presenciados en Cerrito 220 (Dirección Nacional de Loterías y Quinielas) o en
                    vivo vía Instagram (@viajes.detoqueytoque).
                    <br><br>
                    Válido para viajes hasta Diciembre de 2024.
                    <br><br>
                    Sujeto a disponibilidad.
                    <br><br>
                    Se debe solicitar por correo a info@dtyt360.com y con antelación no menor a 60 días previos al viaje.
                    <br><br>
                    Informamos que las fechas de los sorteos 2023 se vieron modificadas debido a la disponibilidad de
                    las instalaciones en la Dirección Nacional de Loterías y Quinielas.
                    Las fechas de los sorteos y el detalle de cada uno se encuentra a continuación:
                    <br>
                </div>
            </div>

            <div class="row">
                <div class="section-title">
                    Viaje Grupal a Egipto:
                </div>
                <div class="section-description">
                    El mismo se desarrollará entre los meses de Septiembre - Octubre 2024. El paquete incluye <br>
                    <div style="text-indent: 2em;">
                        - Vuelos Montevideo - Cairo - Montevideo<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Todos los hoteles en habitaciones dobles con desayuno incluido<br>
                    </div>
                    <div style="text-indent: 4em;">
                        - 4 noches de hotel en El Cairo<br>
                    </div>
                    <div style="text-indent: 4em;">
                        - 2 noches de hotel en Luxor<br>
                    </div>
                    <div style="text-indent: 4em;">
                        - 2 noches de hotel en Aswan<br>
                    </div>
                    <div style="text-indent: 4em;">
                        - 6 noches de hotel en Sharm el Sheik<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Todos los transportes internos (Buses y vuelos)<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Visa de Egipto<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Acompañamiento desde Montevideo durante todo el viaje<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Propinas<br>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="section-title">
                    Viaje a un all inclusive en Punta Cana para 2 personas
                </div>
                <div class="section-description">
                    <div style="text-indent: 2em;">
                        - Pasajes Montevideo - Punta Cana - Montevideo (Imp., Equipaje y tasas incluidos)<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - 7 noches de hotel all inclusive<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Seguro de viaje Assist Card<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Traslados aeropuerto - Hotel - aeropuerto<br><br>
                    </div>

                    Fechas a determinar con el ganador sujeto a disponibilidad.<br>
                </div>
            </div>

            <div class="row">
                <div class="section-title">
                    Viaje en crucero por Sudamérica
                </div>
                <div class="section-description">
                    <div style="text-indent: 2em;">
                        - 7 días de crucero con comidas incluidas (Desayuno, almuerzo, cena).
                        <br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Cabina interna para dos personas<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Incluye Agua y Jugos.<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Incluye propina.<br><br>
                    </div>
                    Fechas a determinar con el ganador sujeto a disponibilidad naviera.
                    <br>
                </div>
            </div>

            <div class="row">
                <div class="section-title">
                    Viaje a Río de Janeiro para 2 personas
                </div>
                <div class="section-description">
                    <div style="text-indent: 2em;">
                        - Vuelo Montevideo - Río de janeiro - Montevideo (Maletas, tasas e imp. incluidos)<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - 7 noches de alojamiento en régimen de media pensión<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Seguro de Viaje Assist Card<br><br>
                    </div>
                    Fechas a determinar con el ganador sujeto a disponibilidad.
                    <br>
                </div>
            </div>

            <div class="row">
                <div class="section-title">
                    Viaje grupal a Perú para 2 personas
                </div>
                <div class="section-description">
                    El mismo se desarrollará entre los meses de Septiembre - Octubre 2024.<br>
                    <div style="text-indent: 2em;">
                        - Pasaje aéreo Montevideo - Cuzco - Montevideo (Imp, Equipaje y Tasas incluidos)<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - 5 noches de alojamiento en Cuzco<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Camino de Salkantay de 5 días / 4 noches con todo incluído<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Acompañamiento desde Uruguay<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Seguro Assist Card<br>
                    </div>
                    <br>
                </div>
            </div>

            <div class="row">
                <div class="section-title">
                    Viaje a Maldivas para 2 personas
                </div>
                <div class="section-description">
                    <div style="text-indent: 2em;">
                        - Vuelo Montevideo - Male - Montevideo (Maletas, tasas e imp. incluidos)<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - 7 noches de alojamiento en régimen all inclusive<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Traslado aeropuerto - Hotel - aeropuerto<br>
                    </div>
                    <div style="text-indent: 2em;">
                        - Seguro Assist Card<br> <br>
                    </div>
                    Fechas a determinar con el ganador sujeto a disponibilidad.
                </div>
            </div>
        </div>
        <div class="clearfix small-screens"></div>
        <p class="clearfix"></p>
    </div>
</div>
<div class="section-container standard-section dark">
    <div class="section-wrapper container section-wrapper-medium-desktop">
        <div id="sugerencias" *ngIf="sugerencias.length > 0">
            <div class="row" *ngIf="hasSugerenciaGrupo">
                <app-swiper-wide tipo="Grupo" title="Sumate a nuestras expediciones grupales:"
                    [listadoItems]="sugerencias"></app-swiper-wide>
            </div>
            <div class="row" *ngIf="hasSugerenciaPaquete">
                <app-swiper-wide tipo="Paquete" title="¿Planeando un viaje?"
                    description="Acá te sugerimos algunos destinos para realizar tu viaje con nuestro toque"
                    [listadoItems]="sugerencias" (clickAction)="showContactModal('tutoque', $event)"></app-swiper-wide>
            </div>
        </div>
        <div class="row">
            <div class="col-1">
                <a href="/tu-toque">
                    <div class="btn-img large-btn-img">
                        <app-picture [image]="pageImgs.tuToqueFooter"></app-picture>
                        <div class="btn-img-info">
                            <div class="btn-img-text">
                                Ya sabés a donde ir?
                            </div>
                            <div class="btn-img-title">
                                Hacé tu viaje con nuestro toque.
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <p class="clearfix"></p>
        </div>
    </div>
</div>
<div class="contact-modal" *ngIf="showModal">
    <div id="contactForm" class="contact-containerr" #contactForm>
        <div class="close-btn" (click)="closeContactModal()">
            <img src="/assets/imgs/close.svg" alt="">
        </div>
        <app-loader *ngIf="loadingContact" type="small"></app-loader>
        <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
            <div class="contact-title section-title">Escribinos, nosotros te organizamos tu viaje a {{
                tutoqueCountrySelected }}.</div>
            <!-- <div class="contact-description">{{description}}</div> -->
            <div class="contact-input">
                <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
            </div>
            <div class="contact-input">
                <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
            </div>
            <div class="contact-input">
                <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
            </div>
            <div class="contact-input">
                <textarea name="msgTxt" placeholder="¿Qué lugar del mundo te gustaría conocer?" cols="" rows=""
                    [(ngModel)]="msgTxt"></textarea>
            </div>
            <div class="fields-error" *ngIf="fieldsError != ''">
                {{ fieldsError }}
            </div>
            <div class="recaptcha">
                <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
            </div>
            <div class="contact-btn btn-blue" [class.btn-blue]="modalType == 'tutoque'"
                [class.btn-yellow]="modalType == '360'" (click)="sendModal()">
                ENVIAR CONSULTA
            </div>
        </div>
        <div class="contact-content contact-message" *ngIf="!loadingContact && (successMsg || errorMsg)">
            <div class="contact-title section-title" *ngIf="successMsg">
                <img src="/assets/imgs/icons/success.svg" alt=""> Gracias por comunicarte, en breve nos ponemos en
                contacto!
            </div>
            <div class="contact-title section-title" *ngIf="errorMsg">
                <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error, llamanos al <a
                    href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a
                    href="https://wa.me/59892443898" target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>