<app-header type="search-page"></app-header>
<app-small-banner></app-small-banner>
<app-loader *ngIf="loading"></app-loader>

<div class="section-wrapper container section-wrapper-medium-desktop" *ngIf="!loading && wrongSearchValue">
    <div class="section-wrapper container section-wrapper-medium-desktop">
        <div class="center-column wrong-search">
            <div class="section-title" *ngIf="!loadingContact && !successMsg && !errorMsg">
                No hemos encontrado resultados para el destino que estás buscando, contanos cual es tu idea de viaje y nos ponemos en contacto.
            </div>
            <div class="clearfix"></div>
            <div class="row">
                <div class="contact-containerr">
                    <!-- <div class="close-btn" (click)="closeContactModal()">
                    <img src="/assets/imgs/close.svg" alt="">
                </div> -->
                    <app-loader *ngIf="loadingContact" type="small"></app-loader>
                    <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
                        <!-- <div class="contact-description">{{description}}</div> -->
                        <div class="contact-input">
                            <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
                        </div>
                        <div class="contact-input">
                            <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
                        </div>
                        <div class="contact-input">
                            <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
                        </div>
                        <div class="contact-input">
                            <textarea name="msgTxt" placeholder="¿Qué lugar del mundo te gustaría conocer?" cols="" rows="" [(ngModel)]="msgTxt"></textarea>
                        </div>
                        <div class="fields-error" *ngIf="fieldsError != ''">
                            {{ fieldsError }}
                        </div>
                        <div class="recaptcha">
                            <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
                        </div>
                        <div class="contact-btn btn-blue" [class.btn-blue]="modalType == 'tutoque'" [class.btn-yellow]="modalType == '360'" [class.btn-green]="modalType == ''" (click)="send()">
                            ENVIAR CONSULTA
                        </div>
                    </div>
                    <div class="contact-content contact-message" *ngIf="!loadingContact && (successMsg || errorMsg)">
                        <div class="contact-title section-title" *ngIf="successMsg">
                            <img src="/assets/imgs/icons/success.svg" alt=""> Gracias por comunicarte, en breve nos ponemos en contacto!
                        </div>
                        <div class="contact-title section-title" *ngIf="errorMsg">
                            <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898" target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>
<div id="test" class="section-container column-section" *ngIf="!loading && !wrongSearchValue">
    <div class="section-wrapper container section-wrapper-medium-desktop">
        <div class="left-column">
            <div class="section-title">
                Resultados de búsqueda para "{{txtBusqueda}}"...
            </div>
            <div class="row" *ngIf="grupos.length > 0">
                <div class="section-title green-text">
                    Grupos
                </div>
                <div class="section-description">
                    Sumate a nuestros grupos a los destinos que buscás. Estas son las salidas grupales que tenemos en el calendario que incluyen el destino que estás buscando!
                </div>
                <div class="search-grupo-list search-list">
                    <!-- <div class="search-item col-1 col-tablet-1" *ngFor="let grupo of grupos" (click)="navigate( '/grupos/' + getSlug(grupo.info.viajemadreName, '-') )"> -->
                    <div class="search-item col-1 col-tablet-1" *ngFor="let grupo of grupos">
                        <a routerLink="/grupos/{{getSlug(grupo.info.viajemadreName, '-')}}">

                            <div class="search-header">
                                <div class="search-thumbnail">
                                    <img src="/assets/imgs/grupos/{{ getSlug(grupo.info.viajemadreName) }}/thumbnail.png" alt="">
                                </div>
                                <div class="search-header-text">
                                    <div class="search-title">
                                        {{ grupo.info.viajeName }}
                                    </div>
                                    <div class="search-description">
                                        {{grupo.info.itinerarioCantdias}} días - <span *ngFor="let pais of grupo.paises; let i = index">{{pais.paisName}} <span *ngIf="(i+1) < grupo.paises.length">/ </span></span>
                                    </div>
                                </div>
                                <div class="search-price btn-green">
                                    USD {{ grupo.info.itinerarioPrice }}
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="search-description" [innerHTML]="grupo.info.viajeDesc"></div>
                        </a>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="separator"></div>
            <div class="row" *ngIf="strictName != ''">
                <div class="oferta-item col-1 col-tablet-1" (click)="showContactModal('tutoque')">
                    <div class="btn-img small-btn-img" style="background-image: url('/assets/imgs/tutoque-banner.png'); background-position: center center; background-repeat: no-repeat; background-size: cover;">
                        <div class="oferta-text">
                            <div class="oferta-tipo blue-text">Tu Toque</div>
                            <!-- <div class="oferta-title">Organizamos tu viaje a {{ strictName }} y a donde quieras a tu medida</div> -->
                            <div class="oferta-title">{{ strictName }} a tu medida</div>
                            <div class="oferta-description">También te organizamos el viaje a {{ strictName }} exclusivamente para ti, con tus fechas, tus destinos, tu presupuesto pero siempre con nuestro toque</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="strictName != ''">
                <div class="oferta-item col-1 col-tablet-1" (click)="showContactModal('360')">
                    <div class="btn-img small-btn-img" style="background-image: url('/assets/imgs/360-banner.png'); background-position: center center; background-repeat: no-repeat; background-size: cover;">
                        <div class="oferta-text">
                            <div class="oferta-tipo yellow-text">Pasajes 360</div>
                            <div class="oferta-title">Encontrá los mejores pasajes a {{ strictName }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix small-screens"></div>
        <p class="clearfix"></p>
    </div>
</div>
<div class="contact-modal" *ngIf="showModal">
    <div id="contactForm" class="contact-containerr" #contactForm>
        <div class="close-btn" (click)="closeContactModal()">
            <img src="/assets/imgs/close.svg" alt="">
        </div>
        <app-loader *ngIf="loadingContact" type="small"></app-loader>
        <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
            <div class="contact-title section-title">¿Querés viajar a {{strictName}}?</div>
            <div class="contact-description">Escribinos, nosotros te organizamos tu viaje.</div>
            <div class="contact-input">
                <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
            </div>
            <div class="contact-input">
                <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
            </div>
            <div class="contact-input">
                <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
            </div>
            <div class="contact-input">
                <textarea name="msgTxt" placeholder="¿Qué lugar del mundo te gustaría conocer?" cols="" rows="" [(ngModel)]="msgTxt"></textarea>
            </div>
            <div class="fields-error" *ngIf="fieldsError != ''">
                {{ fieldsError }}
            </div>
            <div class="recaptcha">
                <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
            </div>
            <div class="contact-btn btn-blue" [class.btn-blue]="modalType == 'tutoque'" [class.btn-yellow]="modalType == '360'" (click)="send()">
                ENVIAR CONSULTA
            </div>
        </div>
        <div class="contact-content contact-message" *ngIf="!loadingContact && (successMsg || errorMsg)">
            <div class="contact-title section-title" *ngIf="successMsg">
                <img src="/assets/imgs/icons/success.svg" alt=""> Gracias por comunicarte, en breve nos ponemos en contacto!
            </div>
            <div class="contact-title section-title" *ngIf="errorMsg">
                <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898" target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>