<div id="">
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!loading">
        <app-header type="search-top"></app-header>

        <app-top-full-banner title="360 Pasajes" [image]="pageImgs.cabezal" generalClass="viajes360" description="Pensando en todo y en vos.<br>Pasajes, paquetes, viajes corporativos y todo lo que precises, personalizado y a medida."></app-top-full-banner>
        <div id="test" class="section-container column-section">
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div class="left-column">
                    <div class="section-title">
                        ¿Qué es 360?
                    </div>
                    <div class="section-description">
                        <app-read-more [description]="description360"></app-read-more>
                        Es atención personalizada, con experiencia y compromiso las 24 hrs.
                        Es conexión junto al que viaje por trabajo o por placer junto a su familia, amigas o amigos.
                        Encontramos las mejores opciones en vuelos, paquetes y servicios para personas y empresas.
                        Es desentenderte y disfrutar... del viaje y del proceso.
                    </div>
                    <!-- <div class="row">
                        <div class="section-title">
                            Ofertas del mes
                        </div>
                        <div class="ofertas-list">
                            <div class="oferta-item col-1 col-tablet-1">
                                <div class="btn-img small-btn-img" style="background-image: url('/assets/imgs/ofertas/pasaje_cancun.png'); background-position: center center; background-repeat: no-repeat; background-size: cover;">
                                    <div class="oferta-airline">
                                        <img src="/assets/imgs/ofertas/copa.svg" alt="">
                                    </div>
                                    <div class="oferta-text">
                                        <div class="oferta-tipo">Pasajes</div>
                                        <div class="oferta-title">Cancún</div>
                                        <div class="oferta-description">Ida y vuelta*</div>
                                    </div>
                                    <div class="oferta-price">USD 524</div>
                                </div>
                                <div class="section-desciption oferta-comment">
                                    *Válido para compras hasta el 13 de mayo y para viajes finalizados máximo 30 junio 2021
                                </div>
                            </div>
                            <div class="oferta-item col-1 col-tablet-1">
                                <div class="btn-img small-btn-img" style="background-image: url('/assets/imgs/ofertas/pasaje_punta_cana.png'); background-position: center center; background-repeat: no-repeat; background-size: cover;">
                                    <div class="oferta-airline">
                                        <img src="/assets/imgs/ofertas/copa.svg" alt="">
                                    </div>
                                    <div class="oferta-text">
                                        <div class="oferta-tipo">Pasajes</div>
                                        <div class="oferta-title">Punta Cana</div>
                                        <div class="oferta-description">Ida y vuelta*</div>
                                    </div>
                                    <div class="oferta-price">USD 524</div>
                                </div>
                                <div class="section-desciption oferta-comment">
                                    *Válido para compras hasta el 13 de mayo y para viajes finalizados máximo 30 junio 2021
                                </div>
                            </div>
                            <div class="oferta-item col-1 col-tablet-1">
                                <div class="btn-img small-btn-img" style="background-image: url('/assets/imgs/ofertas/pasaje_miami.png'); background-position: center center; background-repeat: no-repeat; background-size: cover;">
                                    <div class="oferta-airline">
                                        <img src="/assets/imgs/ofertas/copa.svg" alt="">
                                    </div>
                                    <div class="oferta-text">
                                        <div class="oferta-tipo">Pasajes</div>
                                        <div class="oferta-title">Miami</div>
                                        <div class="oferta-description">Ida y vuelta*</div>
                                    </div>
                                    <div class="oferta-price">USD 550</div>
                                </div>
                                <div class="section-desciption oferta-comment">
                                    *Válido para compras hasta el 13 de mayo y para viajes finalizados máximo 30 junio 2021
                                </div>
                            </div>
                        </div>
                        <div class="separator clearfix"></div>
                        <div class="section-desciption">
                            *Incluye tasas e impuestos. Sujeto a disponibilidad según la fecha de viaje. <br> *No incluye maleta para despachar
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-1">
                            <div id="contactForm" class="contact-containerr" #contactForm>
                                <div class="contact-boxx">
                                    <app-loader *ngIf="loadingContact" type="small"></app-loader>
                                    <!-- <div class="contact-content" *ngIf="!loading && !successMsg && !errorMsg"> -->
                                    <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
                                        <div class="contact-title section-title">Escribinos, nosotros te organizamos el viaje.</div>
                                        <div class="contact-input">
                                            <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
                                        </div>
                                        <div class="contact-input">
                                            <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
                                        </div>
                                        <div class="contact-input">
                                            <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
                                        </div>
                                        <div class="contact-input">
                                            <textarea name="msgTxt" placeholder="¿Qué lugar del mundo te gustaría conocer?" cols="" rows="" [(ngModel)]="msgTxt"></textarea>
                                        </div>
                                        <div class="fields-error" *ngIf="fieldsError != ''">
                                            {{ fieldsError }}
                                        </div>
                                        <div class="recaptcha">
                                            <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
                                        </div>
                                        <div class="contact-btn btn-yellow" (click)="send()">
                                            ENVIAR
                                        </div>
                                    </div>
                                    <div class="contact-content contact-message" *ngIf="!loading && successMsg">
                                        <div class="contact-title section-title">
                                            <img src="/assets/imgs/icons/success.svg" alt=""> Gracias por comunicarte, en breve nos ponemos en contacto!
                                        </div>
                                        <!-- <div class="contact-btn btn-green" (click)="closeContact()">
                                            Cerrar
                                        </div> -->
                                    </div>
                                    <div class="contact-content contact-message" *ngIf="!loading && errorMsg">
                                        <div class="contact-title section-title">
                                            <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898"
                                                target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
                                        </div>
                                        <!-- <div class="contact-btn btn-green" (click)="closeContact()">
                                            Cerrar
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix small-screens"></div>
                <div class="right-column no-small-screens">
                    <div class="itinerario-list-right">
                        <div class="info-header">
                            <div class="info-header-text">
                                <div class="info-header-right-small">
                                    ¿A dónde te gustaría ir?
                                </div>
                                <div class="info-header-right-bigger">
                                    Nosotros lo organizamos
                                </div>
                                <div class="sumate-al-viaje btn-standard btn-yellow btn-left" (click)="contactState()">Escribinos</div>
                                <a href="https://wa.me/59892443898" target="_blank">
                                    <div class="btn-square btn-right whatsapp-btn"><img src="/assets/imgs/wp-icon.svg" alt=""></div>
                                </a>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <!-- <div class="small-text" *ngIf="itinerariosDB.length > 1">
                            Elegí que parte del viaje querés hacer:
                        </div>
                        <select *ngIf="itinerariosDB.length > 1" class="select-itinerarios" (change)="changeSelectItinerarios($event)">
                            <option [value]="i" [selected]="iti.selected" *ngFor="let iti of itinerariosDB; let i = index"><span *ngIf="iti.itinerario.itinerarioPrincipal == 0">{{ iti.itinerario.itinerarioName }}</span><span *ngIf="iti.itinerario.itinerarioPrincipal == 1">Todo el viaje</span></option>
                        </select> -->
                        <!-- <div class="countries-list">
                            <div class="country-item" *ngFor="let item of itinerarioSelected.paises">
                                <div class="country-name">
                                    {{ item.pais.paisName }}
                                </div>
                                <div class="country-days">
                                    {{ getCantDias( item ) }}
                                </div>
                                <p class="clearfix"></p>
                            </div>
                        </div> -->
                        <!-- <div class="separator"></div>
                        <div class="itinerario-right-price">
                            USD {{ itinerarioSelected.itinerario.itinerarioPrice }} <span>/ {{ itinerarioSelected.itinerario.itinerarioCantdias }} días</span>
                        </div> -->
                        <!-- <div class="sumate-al-viaje btn-standard btn-green" (click)="contact()">Sumate al viaje</div> -->
                    </div>
                </div>
                <p class="clearfix"></p>
            </div>
        </div>
        <div class="section-container standard-section dark">
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div id="sugerencias" *ngIf="sugerencias.length > 0">
                    <!-- <div class="row">
                        <div class="col-1">
                            <div class="section-title">
                                También te puede interesar
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <app-swiper-wide tipo="Grupo" title="Tenemos otras sugerencias para viajar en grupo:" [listadoItems]="sugerencias"></app-swiper-wide>
                        <!-- <div class="sugerencia-item" *ngFor="let sugerencia of sugerencias">
                            <div class="col-3" *ngIf="sugerencia.tipo == 'Grupo'">
                                <div class="btn-img small-btn-img">
                                    <img src="/assets/imgs/grupos/{{getSlug(sugerencia.item.viajemadre.viajemadreName)}}/desktop_portada_1.png" alt="">
                                    <div class="btn-img-info">
                                        <div class="btn-img-title">
                                            {{ sugerencia.item.viajemadre.viajemadreName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3" *ngIf="sugerencia.tipo == 'Paquete'">
                                <div class="btn-img small-btn-img">
                                    <img src="/assets/imgs/paises/{{getSlug(sugerencia.item.pais.paisName)}}.jpg" alt="">
                                    <div class="btn-img-info">
                                        <div class="btn-img-title">
                                            {{ sugerencia.item.pais.paisName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <app-swiper-wide tipo="Paquete" title="Te gustó {{ viajeName }}?" description="Genial. Antes y/o después del viaje, agregá mas destinos y viajá con nuestro toque!" [listadoItems]="sugerencias"></app-swiper-wide>
                    </div>
                </div>
                <div class="row">
                    <div class="col-1">
                        <a routerLink="/tu-toque">
                            <div class="btn-img large-btn-img">
                                <app-picture [image]="pageImgs.tuToqueFooter"></app-picture>
                                <div class="btn-img-info">
                                    <div class="btn-img-text">
                                        Ya sabés a donde ir?
                                    </div>
                                    <div class="btn-img-title">
                                        Hacé tu viaje con nuestro toque.
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <p class="clearfix"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-contacto title="Participá de las reuniones o preguntanos detalles por acá:" description="" [showContact]="showContact" (contactState)="showContact = $event" [viaje]="viajeName"></app-contacto> -->
<!-- <app-info-footer *ngIf="showInfoFooter && !loading" [changeItinerarioAnimation]="changeItinerarioAnimation" [itinerario]="itinerarioSelected.itinerario" [itinerariosDB]="itinerariosDB" (itinerarioSelected)="itinerarioSelected = $event" (itinerarioDBoutput)="itinerariosDB = $event"
    [icon]="icon" [showContact]="showContact" (contactSate)="showContact = $event"></app-info-footer> -->
<app-info-footer-simple *ngIf="showInfoFooter && !loading" color="yellow" [showContact]="showContact" (contactState)="contactState($event)"></app-info-footer-simple>
<!-- <div class="info-footer">
    <div class="info-footer-container">

        <div class="info-header">
            <div class="info-header-text">
                <div class="info-header-right-small">
                    ¿A dónde te gustaría ir?
                </div>
                <div class="info-header-right-bigger">
                    Nosotros lo organizamos
                </div>
            </div>
            <div class="clearfix"></div>
        </div> -->
<!-- <select class="select-itinerarios" (change)="changeSelectItinerarios($event)">
            <option [value]="i" [selected]="iti.selected" *ngFor="let iti of itinerariosDB; let i = index"><span *ngIf="iti.itinerario.itinerarioPrincipal == 0">{{ iti.itinerario.itinerarioName }}</span><span *ngIf="iti.itinerario.itinerarioPrincipal == 1">Todo el viaje</span></option>
            </select> -->
<!-- <div class="sumate-al-viaje btn-standard btn-green" (click)="contact()">Sumate</div> -->

<!-- <div class="sumate-al-viaje btn-standard btn-yellow btn-left" (click)="contact()">Escribinos</div>
        <div class="btn-square btn-right whatsapp-btn" (click)="contact()"><img src="/assets/imgs/wp-icon.svg" alt=""></div>

    </div>
</div> -->
<app-footer *ngIf="!loading"></app-footer>
<!-- <app-test></app-test> -->
<!-- <app-search-bar></app-search-bar> -->
