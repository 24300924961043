import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CdkOverlayContainer extends OverlayContainer {

  // constructor(
  //   @Inject(PLATFORM_ID) private platformId: any,
  //   @Inject(DOCUMENT) public _document: Document,
  // ) {
  //   super( _document, platformId );
  // }

   /**
   * Create overlay container and append to ElementRef from directive
   */
  public myCreateContainer(element: HTMLElement): void {
    // if (isPlatformBrowser(this.platformId)) {
      // console.log('bbbbbbbbb');
      let container = document.createElement('div');
      container.classList.add('my-custom-overlay-container-class');
    element.appendChild(container);
    this._containerElement = container;
    // }
  }
  /**
   * Prevent creation of the HTML element, use custom method above
   */
  // protected _createContainer(): void {
  //   console.log('cccccccccc');
  //     return;
  // }


}