import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-footer-simple',
  templateUrl: './info-footer-simple.component.html',
  styleUrls: ['./info-footer-simple.component.scss']
})
export class InfoFooterSimpleComponent {
  @Input() color;
  @Input() showContact = false;
  @Output('contactState') contactSate = new EventEmitter();
  @Input() changeItinerarioAnimation = false;
  
  constructor() { }

  contact() {
    this.showContact = true;
    this.contactSate.emit('mobile');
  }

}
