import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GruposService } from 'src/app/services/grupos.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { isPlatformBrowser } from '@angular/common';
import { ImageInfo } from 'src/app/interfaces/image-info.interface';

//PRUEBA PRIMER COMMIT

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  public slug = '';
  public viajes = [];
  public viajeDestacado;
  public loading = true;

  public paisNames = '';
  public paises;
  public ciudades = [];

  public count;

  public pageImgs = {
    'paso1': new ImageInfo('/assets/imgs/tu-toque/Comienza-organizar-tu-viaje', ['_xs', '_sm'], '.png', 'Artículos que son necesarios para organizar un viaje. Mapa, lápiz, cámara de fotos', '_xs'),
    'paso2': new ImageInfo('/assets/imgs/tu-toque/Elije-tu-destino', ['_xs', '_sm'], '.png', 'Persona eligiendo el destino al que quiere viajar', '_sm'),
    'paso3': new ImageInfo('/assets/imgs/tu-toque/Realiza-tu-viaje-planeado', ['_xs', '_sm'], '.png', 'Mochilero viajando por la montaña', '_sm'),

    sabanasAfricanas: new ImageInfo(
      '/assets/imgs/expediciones/viaje-Africa',
      ['_xs', '_sm', '_md'],
      '.png',
      'Leones en un safari en África',
      '_sm'
    ),
    tanzaniaExpress: new ImageInfo(
      '/assets/imgs/expediciones/viaje-Tanzania',
      ['_xs', '_sm', '_md'],
      '.png',
      '',
      '_md'
    ),
    viajeTurquia: new ImageInfo(
      '/assets/imgs/expediciones/viaje-a-turquia',
      ['_xs', '_sm', '_md'],
      '.png',
      '',
      '_md'
    ),
    caminoDeSantiago: new ImageInfo(
      '/assets/imgs/expediciones/camino-de-santiago',
      ['_xs', '_sm', '_md'],
      '.png',
      '',
      '_md'
    ),
    mojonesDeMedioOriente: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Egipto-Jordania-Israel-Turquia',
      ['_xs', '_sm', '_md'],
      '.png',
      'Una de las 3 pirámides de Egipto en el Cairo',
      '_sm'
    ),
    arenasDelIndico: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Tailandia-Camboya-Indonesia-Singapur',
      ['_xs', '_sm', '_md'],
      '.png',
      'Acantilados y rocas con vegetación en el medio del mar',
      '_sm'
    ),
    machuPicchu: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Peru',
      ['_xs', '_sm', '_md'],
      '.png',
      'Machu Picchu, una de las 7 maravillas del mundo',
      '_sm'
    ),
    lazosMeridionales: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Marruecos-Portugal',
      ['_xs', '_sm', '_md'],
      '.png',
      'Techo decorado con arabescos, ventanas con vitrales y una lampara de techo colgando',
      '_sm'
    ),
    latitudCero: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Ecuador',
      ['_xs', '_sm', '_md'],
      '.png',
      'Viajero caminando por un camino de tierra en subida en el medio de la montaña hacia Lodge con un pico nevado de fondo',
      '_sm'
    ),
    rumboCubano: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Cuba',
      ['_xs', '_sm', '_md'],
      '.png',
      'Clasico auto antiguo cubano por las calle de La Habana Vieja',
      '_sm'
    ),
    postalesDeOceania: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Nueva-Zelanda-Australia',
      ['_xs', '_sm', '_md'],
      '.png',
      'Vista aérea de la serpenteante ruta sobre la costa en Nueva Zelanda',
      '_sm'
    ),
    rutasTicas: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Costa-Rica',
      ['_xs', '_sm', '_md'],
      '.png',
      'Catarata La Fortuna en medio de la tupida selva de Costa Rica',
      '_sm'
    ),
    sendasMisticas: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-India-Nepal-Tailandia',
      ['_xs', '_sm', '_md'],
      '.png',
      'Turistas ingresando al Taj Mahal, una de las 7 maravillas del mundo',
      '_sm'
    ),
    avataresDelSudeste: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Malasia-Singapur-Vietnam-Filipinas',
      ['_xs', '_sm', '_md'],
      '.png',
      'Bahía de Halong con rosado atardecer vista desde un bote entre las grandes rocas',
      '_sm'
    ),
    culturasOrientales: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Japon-Corea-del-Sur-Hong-Kong-China',
      ['_xs', '_sm', '_md'],
      '.png',
      'Turistas posando para la foto en la Gran Muralla China',
      '_sm'
    ),
    usaExpress: new ImageInfo(
      '/assets/imgs/expediciones/viaje-usa',
      ['_xs', '_sm', '_md'],
      '.jpg',
      '',
      '_md'
    ),
    egiptoExpress: new ImageInfo(
      '/assets/imgs/expediciones/Viaje-Egipto',
      ['_xs', '_sm', '_md'],
      '.png',
      '',
      '_sm'
    ),
    ascensoKilimanjaro: new ImageInfo(
      '/assets/imgs/expediciones/viaje-kilimanjaro',
      ['_xs', '_sm', '_md'],
      '.png',
      '',
      '_sm'
    ),
    cabezal: new ImageInfo(
      '/assets/imgs/viajes-grupales',
      ['_xs', '_sm', '_md', '_lg', '_xl'],
      '.png',
      '',
      '_xl'
    ),
    tuToqueFooter: new ImageInfo(
      '/assets/imgs/viajes-a-medida',
      ['_xs', '_sm', '_md', '_lg'],
      '.png',
      '',
      '_lg'
    ),
  };

  constructor(
    private _grupoService: GruposService,
    public sanitizer: DomSanitizer,
    private router: Router,
    public globalConstants: GlobalConstants,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    //this.changeText = false;
    this.globalConstants.setPageWithWhatsappBtn(true);
  }

  getImageSEO(pStr: string) {
    const slug = this.getSlug(pStr);

    switch (slug) {
      case 'sabanas_africanas':
        return this.pageImgs.sabanasAfricanas;
      case 'eeuu_edicion_especial':
        return this.pageImgs.usaExpress;
      case 'viaje_a_turquia':
        return this.pageImgs.viajeTurquia;
      case 'camino_de_santiago':
        return this.pageImgs.caminoDeSantiago;
      case 'tanzania_otro_viaje_express':
        return this.pageImgs.tanzaniaExpress;
      case 'ascenso_al_kilimanjaro':
        return this.pageImgs.ascensoKilimanjaro;
      case 'egipto_express':
        return this.pageImgs.egiptoExpress;
      case 'lazos_meridionales':
        return this.pageImgs.lazosMeridionales;
      case 'mojones_de_medio_oriente':
        return this.pageImgs.mojonesDeMedioOriente;
      case 'latitud_cero':
        return this.pageImgs.latitudCero;
      case 'arenas_del_indico':
        return this.pageImgs.arenasDelIndico;
      case 'caminos_a_caminar':
        return this.pageImgs.machuPicchu;
      case 'rumbo_cubano':
        return this.pageImgs.rumboCubano;
      case 'postales_de_oceania':
        return this.pageImgs.postalesDeOceania;
      case 'rutas_ticas':
        return this.pageImgs.rutasTicas;
      case 'sendas_misticas':
        return this.pageImgs.sendasMisticas;
      case 'avatares_del_sudeste':
        return this.pageImgs.avataresDelSudeste;
      case 'culturas_orientales':
        return this.pageImgs.culturasOrientales;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.count = 0;

    this._grupoService.getViajes().subscribe((res: any) => {
      if (res.status == 'success') {
        res.data.forEach((element) => {
          let cc = 0;
          console.log(element);
          element.ciudadViaje = "";
          let ciudadesStr = "";
          this.paises = element.paises;
          // console.log("LISTA PAISES: " +this.paises[0].pais.paisName);
          element.paises.forEach((el) => {
            if (cc === 0) {
              element.paisesString = '';
              if (el.pais.paisName != 'Uruguay') {
                //console.log("NO ES Uruguay");
                element.paisesString += el.pais.paisName;
              }

            } else {
              //SI no esta en la lista de paisesString
              if (element.paisesString.indexOf(el.pais.paisName) == -1) {
                element.paisesString += ' - ' + el.pais.paisName;
              }
            }
            cc++;

            let i = 0;
            el.ciudades.forEach((ciu) => {
              if (
                !this.ciudades.some(
                  (e) => e.ciudad.ciudadName === ciu.ciudad.ciudadName
                )
              ) {
                //console.log("CIUDADES NOMBRE: " + ciu.ciudad.ciudadName + " INDICE: " + i);
                let cadena = ciu.ciudad.ciudadName;
                let termino = "Vuelo";
                let posicion = cadena.indexOf(termino);
                if (posicion !== -1) {
                  //console.log("La palabra está en la posición " + posicion);
                } else {
                  //element.ciudadViaje += ciu.ciudad.ciudadName +' /';
                  ciudadesStr += ciu.ciudad.ciudadName + ' /';
                  this.ciudades.push(ciu);
                  i++;
                }
              }
            });

          });

          ciudadesStr = ciudadesStr.substring(0, ciudadesStr.length - 1);
          element.ciudadViaje = ciudadesStr;
          this.viajes.push(element);

        });

        console.log("Viajes del Calendario");
        console.log(this.viajes);

        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.globalConstants.setPageWithWhatsappBtn(false);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      document.body.classList.remove('smallHeader');
    }
  }

  getSanitizedUrl(pTxt, folder = 'grupos', file = 'banner.png') {
    const txt = this.getSlug(pTxt);

    return '/assets/imgs/' + folder + '/' + txt + '/banner.png';
  }

  getSanitizedUrlGif(pTxt = '', folder = 'grupos', file = 'banner.gif') {
    if (pTxt == '') {
      pTxt = this.slug;
    }
    return '/assets/imgs/' + folder + '/' + pTxt + '/' + file;
  }

  getSlug(pTxt, separator = '_') {
    pTxt = pTxt
      .toLowerCase()
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u')
      .replace(/ñ/gi, 'n')
      .replace(/ \/ /gi, ' ')
      .split(' ')
      .join(separator);

    pTxt = pTxt.replace(/\_\+\_/gi, separator);
    pTxt = pTxt.replace(/\-\+\-/gi, separator);

    return pTxt;
  }

  navigate(link: string) {
    if (link != undefined) {
      this.router.navigate([link]);
    }
  }

  getCantDias(pPaises) {
    let cant = 0;

    pPaises.forEach((pais) => {
      pais.ciudades.forEach((ciudad) => {
        cant += ciudad.itinerariociudadCantdias;
      });
    });

    return cant;
  }

  sumar(i): boolean {
    let ini = i;
    //console.log("VALOR Ini: " + ini + " VALOR I: " + i + " COUNT: " + this.count);
    if (this.count == 0) {
      //ini = i;
      this.count = ini;
      console.log("VALOR NUEVO: " + ini);
      //this.count++;
      return true;

    } else {
      return false;
    }
    // return 1;
  }
}
