import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-top-full-banner-slide',
  templateUrl: './top-full-banner-slide.component.html',
  styleUrls: ['./top-full-banner-slide.component.scss'],
})
export class TopFullBannerSlideComponent implements OnInit {
  @Input() title = '';
  @Input() description = '';
  @Input() smallDescription = '';
  @Input() type = '';
  @Input() precio = '';
  @Input() image = '';
  @Input() imageMobile = '';
  @Input() itinerario;/* Esto es nuevo */
  @Input() showInfo = true;
  

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // document.getElementById('top-full-banner').style.background = 'url(' + this.image + ') center center no-repeat';
      // document.getElementById('top-full-banner').style.backgroundSize = 'cover';
    }
  }
}
