<!--<cdk-virtual-scroll-viewport itemSize="50" class="block-list-num">
    <div *cdkVirtualFor="let n of listNumbers" class="block-num">
        <span> {{n}}</span>
    </div>
</cdk-virtual-scroll-viewport>-->

<!--<cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport">
    <div *cdkVirtualFor="let item of items" class="example-item">{{item}}</div>
</cdk-virtual-scroll-viewport>-->
<cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">
    <div class="example-item">
        <!-- <div class="row" *ngIf="viajeWebType == 'paises'">
            <div class="section-title">
                Itinerario
            </div>
            <div class="row wide" *ngFor="let item of listPaises; let i = index">
                <div class="iti-country" [ngClass]="{'par' : i%2==0, 'impar' : i%2!=0, 'first': i==0, 'last': (i+1)==listPaises.length }">
                    <div class="left-panel" *ngIf="i%2==0">
                        <div class="img-panel-container">
                        </div>
                    </div>
                    <div class="left-panel" *ngIf="i%2!=0">
                        <div class="iti-country-name">
                            {{ item.pais.paisName }}
                        </div>
                        <div class="iti-ciudad" *ngFor="let ciu of item.ciudades">
                            {{ ciu.ciudad.ciudadName }} <span>/ {{ciu.itinerariociudadCantdias}} 
                                <span *ngIf="ciu.itinerariociudadCantdias > 1">días</span><span *ngIf="ciu.itinerariociudadCantdias == 1">día</span></span>
                        </div>
                    </div>
                    <div class="right-panel" *ngIf="i%2==0">
                        <div class="iti-country-name">
                            {{ item.pais.paisName }}
                        </div>
                        <div class="iti-ciudad" *ngFor="let ciu of item.ciudades">
                            {{ ciu.ciudad.ciudadName }} <span>/ {{ciu.itinerariociudadCantdias}} 
                                <span *ngIf="ciu.itinerariociudadCantdias > 1">días</span><span *ngIf="ciu.itinerariociudadCantdias == 1">día</span></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>-->
        <div class="row" *ngIf="viajeWebType == 'ciudades'">
            <div class="section-title">
                Itinerario
            </div>
            <div class="row wide" *ngFor="let item of listPaises; let x = index;">
                <div *ngFor="let ciu of item.ciudades; let i = index">
                    <div class="iti-country" [ngClass]="{'par' : i%2==0, 'impar' : i%2!=0, 'first': (i==0 && x == 0), 'last': ((i+1)==item.ciudades.length && (x+1)==listPaises.length) }">
                        <div class="left-panel" *ngIf="i%2==0">

                        </div>
                        <div class="right-panel" *ngIf="i%2==0">
                            <div class="iti-country-name">
                                {{ ciu.ciudad.ciudadName }}
                            </div>
                            <div class="iti-ciudad" *ngIf="viaje.viajeShowdiasweb">
                                <span>{{ciu.itinerariociudadCantdias}} 
                                    <span *ngIf="ciu.itinerariociudadCantdias > 1">días</span>
                                <span *ngIf="ciu.itinerariociudadCantdias == 1">día</span>
                                </span>
                            </div>
                        </div>
                        <!--<div class="right-panel" *ngIf="i%2!=0">
                            IMPAR
                        </div>-->
                        <div class="left-panel" *ngIf="i%2!=0">
                            <div class="iti-country-name">
                                {{ ciu.ciudad.ciudadName }}
                            </div>
                            <div class="iti-ciudad" *ngIf="viaje.viajeShowdiasweb">
                                <span>{{ciu.itinerariociudadCantdias}} 
                                    <span *ngIf="ciu.itinerariociudadCantdias > 1">días</span>
                                <span *ngIf="ciu.itinerariociudadCantdias == 1">día</span>
                                </span>
                            </div>
                        </div>

                        <!--<div class="left-panel" *ngIf="i%2!=0">
                            <div class="iti-country-name">
                                {{ ciu.ciudad.ciudadName }}
                            </div>
                            <div class="iti-ciudad" *ngIf="viaje.viajeShowdiasweb">
                                <span>{{ciu.itinerariociudadCantdias}} 
                                <span *ngIf="ciu.itinerariociudadCantdias > 1">días</span><span *ngIf="ciu.itinerariociudadCantdias == 1">día</span>
                                </span>
                            </div>
                        </div>
                        <div class="right-panel" *ngIf="i%2!=0">

                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</cdk-virtual-scroll-viewport>