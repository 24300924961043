import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GruposService {

  constructor(private http: HttpClient) { }

  getViaje( pViaje ) {
    const json = {
      viaje: pViaje
    };

    const params =
      'json=' +
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/grupo/get-viaje',
      params,
      { headers }
    );
  }

  getViajes() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/grupo/get-viajes',
      { headers }
    );
  }
  
}
