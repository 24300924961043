<div class="search-icon mobile" (click)="openMobileSearch()">
    <img src="/assets/imgs/search-white.svg" alt="" class="white">
    <img src="/assets/imgs/search-gray.svg" alt="" class="gray">
</div>

<div class="serarch-container" *ngIf="openSearch" [class.mobile-search]="mobile" [class.desktop-search]="!mobile">
    <div class="close-btn" (click)="closeMobileSearch()">
        <img src="/assets/imgs/close.svg" alt="">
    </div>
    <!-- <form [formGroup]="stateForm" [class.desktop]="!openSearch">aaa -->
    <form [formGroup]="stateForm">
        <!-- <mat-form-field *ngIf="stateGroupOptions"> -->
        <!-- <div id="search-bar-container" matAutocompleteOrigin #{{ matName }}="matAutocompleteOrigin"> -->
        <div class="search-bar-container" [id]="matName" matAutocompleteOrigin #origin="matAutocompleteOrigin">
            <div class="search-btn" (click)="searchAction()">
                <img src="/assets/imgs/search.svg" alt="">
            </div>
            <input type="text" (keydown.enter)='searchAction()' placeholder="¿En qué lugar fue el sueño?" type="text" formControlName="stateGroup" required [matAutocomplete]="autoGroup" [matAutocompleteConnectedTo]="origin" matAutocompletePosition="auto" [(ngModel)]="searchValue">
            <!-- <input type="text" (keydown.enter)='searchAction()' placeholder="¿En qué lugar fue el sueño?" type="text" formControlName="stateGroup" required [matAutocomplete]="autoGroup" [matAutocompleteConnectedTo]="matName" matAutocompletePosition="auto" [(ngModel)]="searchValue"> -->
            <mat-autocomplete (opened)="opened()" #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                    <mat-option *ngFor="let name of group.names" [value]="name" (click)="searchAction()">
                        {{name}}
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
            <div class="clearfix"></div>
        </div>
    </form>
</div>

<!-- <div class="serarch-container mobile-search" *ngIf="false">bbb
    <form [formGroup]="stateForm">
        <mat-form-field *ngIf="stateGroupOptions">
        <div id="search-bar-container-mobile" matAutocompleteOrigin #origin="matAutocompleteOrigin">
            <input type="text" (keydown.enter)='searchAction()' placeholder="¿En qué lugar fue el sueño?" type="text" formControlName="stateGroup" required [matAutocomplete]="autoGroup" [matAutocompleteConnectedTo]="origin" matAutocompletePosition="auto" [(ngModel)]="searchValue">
            <mat-autocomplete (opened)="opened()" #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                    <mat-option *ngFor="let name of group.names" [value]="name">
                        {{name}}
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
            <div class="clearfix"></div>
        </div>
    </form>
</div> -->