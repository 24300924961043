import { Component, Input, OnInit } from '@angular/core';
import { ImageInfo } from '../../interfaces/image-info.interface';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss']
})
export class PictureComponent implements OnInit {

  @Input() image: ImageInfo;
  @Input() inputClass = '';

  constructor() { }

  ngOnInit() {
      // console.log('PICTURE COMPONENT');
      // console.log(this.image);
  }

}
