import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent {

  public isCollapsed = true;
  @Input() description = '';
  @Input() wordLimit:number;
  showMore:boolean;

  constructor() {
    this.showMore=false;
   }

   ngOnInit() {
  
    //console.log("LARGO DE TEXTO: "+this.description.length);

    //console.log("LIMITE DE TEXTO: "+this.wordLimit);

  }

   

}
