<div class="info-footer" [class.changing]="changeItinerarioAnimation">
    <div class="info-footer-container">
        <div class="info-header">
            <div class="trip-icon">
                <img [src]="icon" alt="">
            </div>
            <div class="info-header-text">
                USD {{ itinerario.itinerarioPrice }} <span>/ {{ itinerario.itinerarioCantdias }} días</span>
                <!-- <div class="info-header-trip-name">
                    {{ itinerario.viaje.viajemadre.viajemadreName }}
                </div> -->
            </div>
            <div class="clearfix"></div>
        </div>
        <!-- <select class="select-itinerarios" (change)="changeSelectItinerarios($event)">
            <option [value]="i" [selected]="iti.selected" *ngFor="let iti of itinerariosDB; let i = index"><span *ngIf="iti.itinerario.itinerarioPrincipal == 0">{{ iti.itinerario.itinerarioName }}</span><span *ngIf="iti.itinerario.itinerarioPrincipal == 1">Todo el viaje</span></option>
            </select> -->
        <div class="sumate-al-viaje btn-standard btn-green" (click)="contact()">Sumate</div>
    </div>
</div>