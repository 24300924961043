<div class="top-full-banner {{generalClass}}">
    <app-picture [image]="image"></app-picture>
    <div class="top-full-banner-text">
        <div class="title" *ngIf="title != ''">
            {{ title }}
        </div>
        <div class="description" *ngIf="description != ''" [innerHTML]="description">
            <!-- {{description}} -->
        </div>
    </div>
</div>
