import { Component, Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

/*@Component({
  selector: 'app-inner-html',
  templateUrl: './inner-html.component.html',
  styleUrls: ['./inner-html.component.scss']
})*/

@Pipe({
  name:"html"
})

export class HtmlPipe implements PipeTransform {

  constructor (private sanitizer: DomSanitizer) {
  }
  transform(style) {
  return this.sanitizer.bypassSecurityTrustHtml(style);
  }

}
