<div class="top-full-banner" id="top-full-banner">
    <!-- <app-picture [image]="image"></app-picture> -->
    <img [class.desktop]="imageMobile != ''" [src]="image">
    <img *ngIf="imageMobile != ''" class="mobile" [src]="imageMobile">
    <div class="top-full-banner-text" *ngIf="showInfo">
        <a routerLink="/calendario" *ngIf="type == 'Grupos'">
            <div class="type">
                {{type}}
            </div>
        </a>
        <!-- <div class="type" *ngIf="type != '' && type != 'Grupos'">
            {{type}}
        </div> -->
        <div class="title" *ngIf="title != ''">
            <!--{{title | uppercase }}-->
            {{title }}
        </div>
        <!-- <div class="description" *ngIf="description != ''">
            {{ description }}
        </div> Se sacó este div -->
        <!-- Div nuevo -->
        <div class="precio" *ngIf="precio   != ''">
            USD {{ precio }}
        </div>
        <div class="smallDescription" *ngIf="smallDescription != ''">
            {{smallDescription}}
            <br> Mínimo: 15 personas
        </div>
    </div>

</div>
<div id="main-banner-container" #mainSlide *ngIf="!showInfo">
    <div id="main-banner-content">
        <div class="main-text">
            <div class="floating-text-img mundial-text-img">
                <img src="/assets/imgs/Mundial_titulo.svg" alt="">
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>