<div id="calendar">
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!loading">
        <app-header type="search-top" image="/assets/imgs/top-full-banner.png"></app-header>
        <app-top-full-banner [image]="pageImgs.cabezal" title="Expediciones" description="<strong>Creamos grupos</strong> de personas con la misma intención: explorar lugares, recorrer, conocernos, compartir una cerveza mientras la brisa cálida de la noche nos despeina... todo eso al tiempo de cada uno... y mejor aún por países de Asia, África, América, Oceanía y Europa.<br><br>De eso se trata...">
        </app-top-full-banner>

        <div class="section-container standard-section">
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <br><br>
                <div class="section-title">
                    ¡Bienvenidos al calendario 2024!
                </div>
                <div class="section-description">
                    Encontrá las distintas expediciones con sus correspondientes detalles para conocer la raíz, ver opciones y sobre todo... imaginar qué sentimos en cada lugar.
                    <br><b>Conectá expediciones y viajá más!</b> Si tenés más días para viajar, aprovechá que vas a estar allá, seguí viajando con nosotros y ahorrá hasta 2000 usd!

                </div>
                <div class="buttons-items ">
                    <div class="row">
                        <div class="section-title-months">
                            <p> Viajes del Primer semestre </p>
                        </div>

                        <div class="tutoque-steps-container" [ngClass]="{'first': i==0, 'last': (i+1)==viajes.length }" *ngFor="let item of viajes; let i = index">
                            <div *ngVar="{ x: 0}; let variable">
                                <div *ngIf="item.itiPrincipal.itinerarioName == 'Tanzania'">
                                    <div class="section-title-months">
                                        <p> Viajes del Segundo semestre </p>
                                    </div>
                                </div>

                                <div *ngIf="item.itiPrincipal.itinerarioName == 'Machu Picchu'">
                                    <div class="section-title-months">
                                        <p> Viaje especial</p>
                                    </div>
                                </div>
                                <div class="tutoque-step step-1" [ngClass]="{ 'first': i==0 }">
                                    <div class="tutoque-step-img ">
                                        <a routerLink="/grupos/{{getSlug(item.viaje.viajemadre.viajemadreName, '-')}} ">
                                            <div class="btn-img small-btn-img ">
                                                <img class="center" src="{{getSanitizedUrl(item.viaje.viajemadre.viajemadreName)}}">
                                                <div class="btn-img-info ">
                                                    <div class="btn-img-text ">
                                                        {{ item.itiPrincipal.itinerarioSalida | uppercase }}
                                                        <!-- RUTA: {{getSlug(item.viaje.viajemadre.viajemadreName, '-')}} -->
                                                    </div>
                                                    <div class="btn-img-title ">
                                                        {{ item.itiPrincipal.itinerarioName | uppercase }}
                                                    </div>
                                                    <!-- <td scope="row">{{item.viaje.viajeStartdate.timestamp | date:'mediumDate'}}</td>-->
                                                    <!-- {{item.viaje.viajeStartdate.timestamp | date:'yyyy-MM-dd HH:mm:ss-SS' }}-->
                                                    <!-- <div class="btn-img-text-city ">
                                                    {{item.ciudadViaje }}
                                                </div>-->

                                                    <div class="calendar-price ">
                                                        USD {{ item.itiPrincipal.itinerarioPrice }} <span class="span">/</span> <br class="br"> <span class="btn-img-text2 "> {{
                                                        item.itiPrincipal.itinerarioCantdias
                                                        }} días </span>
                                                    </div>
                                                </div>

                                                <div class="mobile-btn ">

                                                    <div class="btn-img-diagonal">
                                                        <div class="btn-img-text ">{{ item.itiPrincipal?.itinerarioSalida | uppercase }}
                                                            <br> <span class="btn-img-text2 "> {{
                                                            item.itiPrincipal.itinerarioCantdias }} días </span>
                                                        </div>
                                                    </div>

                                                    <div class="widthTitle">
                                                        <div class="btn-img-title ">
                                                            {{ item.itiPrincipal.itinerarioName | uppercase }}
                                                        </div>
                                                    </div>

                                                    <div class="calendar-price ">
                                                        USD {{ item.itiPrincipal.itinerarioPrice }}
                                                    </div>

                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <p class="clearfix "></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>