import { isPlatformServer } from '@angular/common';
import { Directive, Inject, PLATFORM_ID, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';

@Directive({
    selector: "[appShellNoRender]"
})
export class AppShellNoRenderDirective implements OnInit {
    
    constructor(
            @Inject(PLATFORM_ID) private platformId, 
            private templateRef: TemplateRef<any>,
            private viewContainer: ViewContainerRef 
        ) {}

    ngOnInit() {
        if ( isPlatformServer(this.platformId) ) {
            this.viewContainer.clear();
            // console.log('appShellNoRender - server');
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
            // console.log('appShellNoRender - browser');
        }
    }

}