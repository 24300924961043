<div id="main-banner-container" #mainSlide>
    <div id="main-banner-content">
        <a routerLink="/">
        <div class="main-text">
            <div class="floating-text-img mobile">
            </div>
            <div class="title">
                &nbsp;
            </div>
            <div class="subtitle">
                &nbsp;
            </div>
        </div>
        </a>
        <a routerLink="/calendario">
            <div class="calendar-btn" routerLink="/calendario">
                Ver calendario 2024
            </div>
        </a>
    </div>
    <div id="main-banner-content" *ngIf="false">
        <div class="main-text">
            <div class="title">
                Viajes que dejen huellas
            </div>
            <div class="subtitle">
                Empezó sin saberlo... al soñarlo, al imaginarlo...
            </div>
        </div>
        <a routerLink="/grupos/eeuu-edicion-especial">
            <div class="calendar-btn" routerLink="/calendario">
                Mirá el calendario 2024
            </div>
        </a>
    </div>
    <div class="clearfix"></div>
</div>
