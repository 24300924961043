
export class ImageInfo {
    public url: string;
    public sizes: string[];
    public originalFormat: string;
    public alt: string;
    public default: string;
    
    constructor( pUrl, pSizes, pOriginalFormat, pAlt, pDefault ) {
        this.url = pUrl;
        this.sizes = pSizes;
        this.originalFormat = pOriginalFormat;
        this.alt = pAlt;
        this.default = pDefault;
    }

    getSizesPx( pSize ) {
        if ( pSize === '_xs' ) {
            return { min: '0px', max: '480px' };
        } else if( pSize === '_sm' ) {
            return { min: '481px', max: '768px' };
        } else if( pSize === '_md' ) {
            return { min: '769px', max: '1024px' };
        } else if( pSize === '_lg' ) {
            return { min: '1025px', max: '1200px' };
        } else if( pSize === '_xl' ) {
            return { min: '1201px', max: '5000px' };
        }
        return;
    } 


}