import { Component, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
// import { StandardItem } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-swiper-small',
  templateUrl: './swiper-small.component.html',
  styleUrls: ['./swiper-small.component.scss'],
})
export class SwiperSmallComponent {
  @Input()
  title = '';
  @Input()
  description = '';
  @Input()
  theme = 'light';
  @Input()
  listadoItems: any[] = [];

  public randomNumber = Math.floor(Math.random() * 100) + 1;

  public configSwiper: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1.6,
    centeredSlides: false,
    spaceBetween: 10,
    slidesOffsetAfter: 100,
    breakpoints: {
      640: {
        slidesPerView: 1.6,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2.6,
        spaceBetween: 20,
        centeredSlides: false,
      },
    },
    navigation: {
      nextEl: '.swiper-custom-next-'+this.randomNumber,
      prevEl: '.swiper-custom-prev-'+this.randomNumber,
    },
    pagination: true,
    uniqueNavElements: true
  };

  constructor() {}

}
