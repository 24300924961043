<div id="calendar">
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!loading">
        <app-header type="search-top"></app-header>

        <!-- <app-top-full-banner title="Tu Toque" generalClass="tutoque" description="Creamos grupos de personas con la misma intención: explorar lugares, recorrer, conocernos, compartir una cerveza mientras la brisa cálida de la noche nos despeina... todo eso al tiempo de cada uno... y mejor aún por países de Asia, África, América, Oceanía y Europa.<br><br>De eso se trata..."></app-top-full-banner> -->
        <app-top-full-banner title="Tu Toque" [image]="pageImgs.cabezal" generalClass="tutoque" description="A través de esta herramienta podemos organizarte el viaje a medida con la garantía de vivir las mismas experiencias que en nuestras expediciones.<br> Tu elegís la fecha<br> Tu elegís el presupuesto<br>Tu elegís los destinos<br> Y nosotros le damos nuestro Toque armándote el viaje o sugiriendo mientras lo armamos en conjunto.<br> Tu viaje, nuestro Toque.<br> Tu Toque"></app-top-full-banner>
        <app-loader *ngIf="loading" type="small"></app-loader>

        <div id="test" class="section-container column-section">
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div class="left-column">
                    <!-- <div class="section-title">
                        Tu viaje, nuestro toque.
                    </div> -->
                    <!-- <div class="section-description">
                        A través de esta herramienta podemos organizarte el viaje a medida con la garantía de vivir las mismas experiencias que tenemos en los viajes donde vamos nosotros con los grupos.<br> Tu elegís la fecha<br> Tu elegís el presupuesto<br>                        Tu elegís los destinos<br> Y nosotros le damos nuestro Toque armándote el viaje o sugiriendo mientras lo armamos en conjunto.<br> Tu viaje, nuestro Toque.<br> Tu Toque
                    </div> -->
                    <div class="row tutoque-steps">
                        <div class="section-title">
                            Cómo viajar con Tu Toque?
                        </div>
                        <div class="tutoque-steps-container">
                            <div class="tutoque-step step-1">
                                <div class="section-title">
                                    Contanos a donde te gustaría viajar.
                                </div>
                                <div class="tutoque-step-img">
                                    <app-picture [image]="pageImgs.paso1"></app-picture>
                                </div>
                            </div>
                            <div class="tutoque-step step-2">
                                <div class="section-title">
                                    Nos juntamos y armamos una propuesta.
                                </div>
                                <div class="tutoque-step-img">
                                    <app-picture [image]="pageImgs.paso2"></app-picture>
                                </div>
                            </div>
                            <div class="tutoque-step step-3">
                                <div class="section-title">
                                    Arma la mochila pero no te arregles el pelo.<br>¡Tu viaje empieza acá!
                                </div>
                                <div class="tutoque-step-img">
                                    <app-picture [image]="pageImgs.paso3"></app-picture>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="section-title">Estos son algunos de los destinos más visitados:</div>
                    </div>
                    <div class="row" *ngFor="let cont of categorias">
                        <!-- {{ cont.items | json }} -->
                        <!-- <div class="continente-name"></div> -->
                        <app-swiper-wide tipo="json" [title]="cont.categoria" description="" mainColor="blue" [listadoItems]="cont.items"></app-swiper-wide>
                    </div>
                    <div id="contactForm" class="contact-containerr" #contactForm>
                        <app-loader *ngIf="loadingContact" type="small"></app-loader>
                        <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
                            <div class="contact-title section-title">Escribinos, nosotros te organizamos tu viaje.</div>
                            <!-- <div class="contact-description">{{description}}</div> -->
                            <div class="contact-input">
                                <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
                            </div>
                            <div class="contact-input">
                                <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
                            </div>
                            <div class="contact-input">
                                <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
                            </div>
                            <div class="contact-input">
                                <textarea name="msgTxt" placeholder="¿Qué lugar del mundo te gustaría conocer?" cols="" rows="" [(ngModel)]="msgTxt"></textarea>
                            </div>
                            <div class="fields-error" *ngIf="fieldsError != ''">
                                {{ fieldsError }}
                            </div>
                            <div class="recaptcha">
                                <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
                            </div>
                            <div class="contact-btn btn-blue" (click)="send()">
                                ENVIAR CONSULTA
                            </div>
                        </div>
                        <div class="contact-content contact-message" *ngIf="!loadingContact && (successMsg || errorMsg)">
                            <div class="contact-title section-title" *ngIf="successMsg">
                                <img src="/assets/imgs/icons/success.svg" alt=""> Gracias por comunicarte, en breve nos ponemos en contacto!
                            </div>
                            <div class="contact-title section-title" *ngIf="errorMsg">
                                <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898" target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix small-screens"></div>
                <div class="right-column no-small-screens">
                    <div class="itinerario-list-right">
                        <div class="info-header">
                            <div class="info-header-text">
                                <div class="info-header-right-small">
                                    ¿A dónde te gustaría ir?
                                </div>
                                <div class="info-header-right-bigger">
                                    Nosotros lo organizamos
                                </div>
                                <div class="sumate-al-viaje btn-standard btn-blue btn-left" (click)="contactState()">Escribinos</div>
                                <a href="https://wa.me/59892383898" target="_blank">
                                    <div class="btn-square btn-right whatsapp-btn"><img src="/assets/imgs/wp-icon.svg" alt=""></div>
                                </a>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <!-- <div class="small-text" *ngIf="itinerariosDB.length > 1">
                            Elegí que parte del viaje querés hacer:
                        </div>
                        <select *ngIf="itinerariosDB.length > 1" class="select-itinerarios" (change)="changeSelectItinerarios($event)">
                            <option [value]="i" [selected]="iti.selected" *ngFor="let iti of itinerariosDB; let i = index"><span *ngIf="iti.itinerario.itinerarioPrincipal == 0">{{ iti.itinerario.itinerarioName }}</span><span *ngIf="iti.itinerario.itinerarioPrincipal == 1">Todo el viaje</span></option>
                        </select> -->
                        <!-- <div class="countries-list">
                            <div class="country-item" *ngFor="let item of itinerarioSelected.paises">
                                <div class="country-name">
                                    {{ item.pais.paisName }}
                                </div>
                                <div class="country-days">
                                    {{ getCantDias( item ) }}
                                </div>
                                <p class="clearfix"></p>
                            </div>
                        </div> -->
                        <!-- <div class="separator"></div>
                        <div class="itinerario-right-price">
                            USD {{ itinerarioSelected.itinerario.itinerarioPrice }} <span>/ {{ itinerarioSelected.itinerario.itinerarioCantdias }} días</span>
                        </div> -->
                        <!-- <div class="sumate-al-viaje btn-standard btn-green" (click)="contact()">Sumate al viaje</div> -->
                    </div>
                </div>
                <p class="clearfix"></p>
            </div>
        </div>
    </div>
</div>
<app-info-footer-simple *ngIf="showInfoFooter && !loading" color="blue" [showContact]="showContact" (contactState)="contactState($event)"></app-info-footer-simple>
<app-footer></app-footer>