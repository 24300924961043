import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-see-more-imgs',
  templateUrl: './see-more-imgs.component.html',
  styleUrls: ['./see-more-imgs.component.scss']
})
export class SeeMoreImgsComponent {

  public isCollapsed = true;
  @Input() description = '';
  @Input() ciudades;

  constructor() { }

  getSquareSmallImg(ciudadName) {
    let str = ciudadName
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join('_');
    
    return '/assets/imgs/grupos/square-small/'+ str + '.png';
  }

}
