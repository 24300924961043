import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendContactGrupo( pName, pPhone, pMail, pViaje, pMsg ) {
    const json = {
      name: pName,
      phone: pPhone,
      mail: pMail,
      viaje: pViaje,
      msg: pMsg,
    };

    const params =
      'json=' +
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/contact/web-grupo',
      params,
      { headers }
    );
  }
  
  sendContact( pName, pPhone, pMail, pMsg ) {
    const json = {
      name: pName,
      phone: pPhone,
      mail: pMail,
      msg: pMsg,
    };

    const params =
      'json=' +
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/contact/web-contact',
      params,
      { headers }
    );
  }
  
  sendContactTutoque( pName, pPhone, pMail, pMsg ) {
    const json = {
      name: pName,
      phone: pPhone,
      mail: pMail,
      msg: pMsg,
    };

    const params =
      'json=' +
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/contact/web-tutoque',
      params,
      { headers }
    );
  }
  
  sendContact360( pName, pPhone, pMail, pMsg ) {
    const json = {
      name: pName,
      phone: pPhone,
      mail: pMail,
      msg: pMsg,
    };

    const params =
      'json=' +
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/contact/web-360',
      params,
      { headers }
    );
  }
}
