import { Component, ElementRef, HostListener, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GruposService } from '../../services/grupos.service';
import { SugerenciaService } from '../../services/sugerencia.service';
import { ContactService } from '../../services/contact.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { isPlatformBrowser } from '@angular/common';
import { ImageInfo } from 'src/app/interfaces/image-info.interface';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-interna-viaje',
  templateUrl: './interna-viaje.component.html',
  styleUrls: ['./interna-viaje.component.scss']

})
export class InternaViajeComponent implements OnInit, OnDestroy {
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`); // Carga Example SCROLL
  public loading = true;
  public loadingContact = false;
  public viajePortada: string;
  public videoUrl: string;
  public videoScreenshot: string;
  public icon: string;
  public itinerarioSelected;
  public showInfoFooter = false;
  public itinerariosDB;
  public itinerarioPrincipal;
  public viaje;
  public viajeName;
  public incluyes;
  public paises;
  public sugerencias = [];
  public ciudades = [];
  public paisNames = '';
  public showContact = false;
  public showModal = false;
  public mobile = false;
  public changeItinerarioAnimation = false;
  public myRecaptcha = false;
  public slug = '';
  public viajeWebType = 'paises';
  public showVideo = false;
  public imageMobile = '';
  public precio; //Nuevo
  public wordLimit=800;


  public hasSugerenciaGrupo = false;
  public hasSugerenciaCombinado = false;
  public hasSugerenciaPaquete = false;

  public nameTxt;
  public phoneTxt;
  public mailTxt;
  public msgTxt;
  public successMsg = false;
  public errorMsg = false;
  public fieldsError = '';
  private fragment: string;

  public theHtmlString;

  public modalType = '';

  public pageImgs = {
    cabezal: new ImageInfo(
      '/assets/imgs/grupos/avatares_del_sudeste/Viaje-Malasia-Singapur-Vietnam-Filipinas',
      ['_xs', '_sm', '_md', '_lg', '_xl'],
      '.png',
      'Bahía de Halong con rosado atardecer vista desde un bote entre las grandes rocas',
      '_sm'
    ),
    mapaDesktop: new ImageInfo(
      '/assets/imgs/grupos/avatares_del_sudeste/Itinerario-Malasia-Singapur-Vietnam-Filipinas-des',
      ['_xs', '_sm', '_md', '_lg'],
      '.png',
      'Mapa donde se muestra el itinerario a realizar en el viaje Avatares del Sudeste',
      '_sm'
    ),
    mapaMobile: new ImageInfo(
      '/assets/imgs/grupos/avatares_del_sudeste/Itinerario-Malasia-Singapur-Vietnam-Filipinas-mob',
      ['_xs'],
      '.png',
      'Mapa donde se muestra el itinerario a realizar en el viaje Avatares del Sudeste',
      '_xs'
    ),
    tuToqueFooter: new ImageInfo(
      '/assets/imgs/viajes-a-medida',
      ['_xs', '_sm', '_md', '_lg'],
      '.png',
      '',
      '_lg'
    ),
    paises: [],
    ciudades: [],
  };

  public tutoqueCountrySelected = '';

  @ViewChild('contactForm', { static: true }) contactFormEl!: ElementRef;

  public beneficiosItems: any[] = [
    {
      image: new ImageInfo(
        '/assets/imgs/beneficios/beneficio-dtyt-dhl',
        ['_xs'],
        '.jpg',
        'Caja amarilla con cinta de DHL',
        '_xs'
      ),
      title: 'DHL',
      description:
        'Sin salir del hotel, mandá tus compras a Uruguay por DHL y a precios especiales para viajeros de dTyT.',
    },
    {
      image: new ImageInfo(
        '/assets/imgs/beneficios/beneficio-dtyt-openapp',
        ['_xs'],
        '.jpg',
        'Persona sentada utilizando un celular',
        '_xs'
      ),
      title: 'Beneficios 2x1',
      description:
        'Disfrutá de beneficios 2x1 en varios lugares de montevideo con la app “dTyT Beneficios”',
    },
    // {
    //   image: new ImageInfo('/assets/imgs/beneficios/beneficio-dtyt-ramon', ['_xs'], '.jpg', 'Brindis con vasos de cerveza', '_xs') ,
    //   title: 'Cervecería Ramón',
    //   description:
    //   'Vení a casa y disfrutá de un 2x1 en cerveza todos los días.',
    // },
    // {
    //   image: '/assets/imgs/beneficios/beneficios_trota.jpg',
    //   title: '“Trotamundos: la tienda del viajero”',
    //   description:
    //     'Una tienda de accesorios pensada para viajerxs a disposición para nosotros y a los grupos. Tenemos descuento en todos sus accesorios entre 15% y 50% dependiendo del accesorio. Ubicada en: Bulevar España 2085 esq Maldonado.',
    // },
    {
      image: new ImageInfo(
        '/assets/imgs/beneficios/beneficio-dtyt-app-viaje',
        ['_xs'],
        '.jpg',
        'Mujer utilizando un celular',
        '_xs'
      ),
      title: 'APP de viaje',
      description:
        'Una app desarrollada especialmente para los viajeros de dTyT con los vuelos actualizados, información de las ciudades, piques de cada lugar entre muchas otras cosas!',
    },
    {
      image: new ImageInfo(
        '/assets/imgs/beneficios/beneficio-dtyt-buceo',
        ['_xs'],
        '.jpg',
        'Persona sumergida realizando buceo',
        '_xs'
      ),
      title: 'Curso de Buceo',
      description:
        'Andate de Uruguay con el curso de buceo realizado y buceá en las mejores aguas del mundo con certificado, ahorrando tiempos y dinero en los destinos además de disfrutar mucho mas de la experiencia!',
    },
    {
      image: new ImageInfo(
        '/assets/imgs/beneficios/beneficio-dtyt-ingles',
        ['_xs'],
        '.jpg',
        'Dados con letras con la palabra Teach',
        '_xs'
      ),
      title: 'Curso de Inglés',
      description:
        'Para aprender o practicar, cursos de inglés enfocados 100% a viajes. Un plus a la hora de viajar y poder sumar mas de esos momentos únicos.',
    },
    {
      image: new ImageInfo(
        '/assets/imgs/beneficios/beneficio-dtyt-fotografia',
        ['_xs'],
        '.jpg',
        'Persona contemplando un paisaje con cámara en mano',
        '_xs'
      ),
      title: 'Curso de fotografía para viajes',
      description:
        'Un curso de fotografía enfocado a los viajes… para que los recuerdos que nos quedan en la memoria sean acompañados por imágenes increíbles ya sea en nuestra cámara o en nuestro celular!',
    },
  ];

  constructor(
    private _grupoService: GruposService,
    private _sugerenciaService: SugerenciaService,
    private _contactService: ContactService,
    private route: ActivatedRoute,
    public globalConstants: GlobalConstants,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private title: Title,
    private meta: Meta
  ) {
    // this.itinerariZZoSelected = this.itinerarios[0];
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        document.querySelector('#' + this.fragment).scrollIntoView();
      } catch (e) { }
    }
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });

    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768) {
        this.mobile = true;
      }

      /*****  LIMITE DE TEXTO EN DESCRIPCION DE CIUDADES ************/ 
      if (window.innerWidth == 1280) {
        this.wordLimit = 490;
      }

      if (window.innerWidth == 1920) {
        this.wordLimit = 1173;
      }

    }

    this.route.params.subscribe(async (params) => {
      const param = params['viaje'];

      console.log('VIAJE: ' + param);
      await this.setPageImgs(param);
      this._grupoService.getViaje(param).subscribe((res: any) => {
        console.log('STATUS: ' + res.status);
        console.log('DATA: ');
        console.log(res.data);
        if (res.status == 'success') {

          this.getSugerencias();
          this.itinerariosDB = res.data.itinerarios;
          this.viaje = this.itinerariosDB[0].itinerario.viaje;
          this.viajeName = this.viaje.viajemadre.viajemadreName;
          this.viajeWebType = this.viaje.viajemadre.viajemadreWebtype;
          //console.log('ITINERARIO PRINCIPAL: ' + res.data.itinerarioPrincipal);
          this.itinerarioPrincipal = res.data.itinerarioPrincipal;
          this.incluyes = res.data.incluye;
          this.itinerarioSelected = res.data.itinerarioComboweb;
          this.paises = res.data.paises;
          console.log("********** PAISES *******************");
          console.log(res.data.paises);
          this.precio = this.itinerarioSelected.itinerario.itinerarioPrice;
          this.setViajeSlug(this.viaje.viajemadre.viajemadreName);
          this.showVideo = this.viaje.viajeShowvideo;
          //this.videoUrl = '/assets/videos/' + this.toSlug(this.viaje.viajemadre.viajemadreName) + '.mp4';
         
          this.videoScreenshot =  this.getSanitizedUrlVideo(this.viaje.viajemadre.viajemadreName,'videos','.png');

          if (this.mobile) {
            this.viajePortada =
              '/assets/imgs/grupos/' +
              this.getViajeSlug() +
              '/mobile_portada_1.png';
          } else {
            this.viajePortada =
              '/assets/imgs/grupos/' +
              this.getViajeSlug() +
              '/desktop_portada_1.png';
          }
          this.icon = '/assets/imgs/icons/' + this.getViajeSlug() + '-icon.svg';

          let cc = 0;
          this.paises.forEach((el) => {
           // console.log("RESULTADO PAIS IMAGEN: " + el.pais.paisName);
            el.image = this.getImagePais(this.toSlug(el.pais.paisName, '-'));

            if (cc === 0) {
              this.paisNames += el.pais.paisName;
            } else {
              // console.log( this.paisNames.indexOf(el.pais.paisName) );
              if (this.paisNames.indexOf(el.pais.paisName) == -1) {
                this.paisNames += ' - ' + el.pais.paisName;
              }
            }

            cc++;

            el.ciudades.forEach((ciu) => {
              //console.log("CIUDADES ID: " + ciu.ciudad.ciudadId);
              //console.log("CIUDADES NOMBRE: " + ciu.ciudad.ciudadName);
              ciu.image = this.getImageCiudad(
                this.toSlug(ciu.ciudad.ciudadName.trim(), '-')
              );
              if (
                !this.ciudades.some(
                  (e) => e.ciudad.ciudadName === ciu.ciudad.ciudadName
                )
              ) {
                let cadena = ciu.ciudad.ciudadName;
                let termino = "Vuelo";
                //let descripcion = ciu.ciudad.ciudadDescription.replace(/\n/gi, "SEPARADOR ").replace(/\r/gi, "");

                //let descripcion = "ALGO";
                //ciu.ciudad.ciudadDescription = descripcion;
                let posicion = cadena.indexOf(termino);

                //console.log("DESCRIPCION LIMPIA: "+descripcion);
                if (posicion !== -1) {
                  //console.log("La palabra está en la posición " + posicion);
                } else {
                  this.ciudades.push(ciu);

                }
              }
            });
          });

          if (this.viaje.viajeMobilebanner) {
            this.imageMobile = this.getSanitizedUrl(this.viajeName, 'grupos', 'banner_mobile.gif')
          }

          this.itinerariosDB.forEach((element) => {
            console.log(
              'element.itinerario element.itinerario element.itinerario element.itinerario'
            );
            console.log(element.itinerario);
            console.log(element.itinerario.itinerarioComboweb === 1);
            console.log(element.itinerario.itinerarioComboweb == 1);
            if (element.itinerario.itinerarioComboweb == 1) {
              element.selected = true;
            } else {
              element.selected = false;
            }
          });
        }

        this.loading = false;
      });
    });
  }

  getSanitizedUrl(pTxt = '', folder = 'grupos', file = 'banner.gif') {
    const txt = this.getSlug(pTxt);

    return '/assets/imgs/' + folder + '/' + txt + '/' + file;
  }

  getSanitizedUrlPng(pTxt = '', folder = 'grupos', file = 'banner.png') {
    if (pTxt == '') {
      pTxt = this.slug;
    }
    return '/assets/imgs/' + folder + '/' + pTxt + '/' + file;
  }

  getSanitizedUrlSugerenciaPng(pTxt = '', folder = 'grupos', file = 'sugerencia.png') {
    if (pTxt == '') {
      pTxt = this.slug;
    }
    return '/assets/imgs/' + folder + '/' + pTxt + '/' + file;
  }
  getSanitizedUrlImgCiudad(pTxt = '', folder = 'grupos', file = 'itinerario.png') {

    folder = this.getSlug(folder);
    if (pTxt == '') {
      pTxt = this.slug;
    }
    return '/assets/imgs/grupos/' + folder + '/ciudades/' + pTxt + '/' + file;
  }
   //this.videoUrl = '/assets/videos/' + this.toSlug(this.viaje.viajemadre.viajemadreName) + '.mp4';
  getSanitizedUrlVideo(pTxt = '', folder = 'videos', file = '.mp4') {
    const txt = this.getSlug(pTxt);
    return '/assets/' + folder + '/' + txt  + file;
  }

  async setPageImgs(viajeSlug) {
    switch (viajeSlug) {
      case 'eeuu-edicion-especial':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/eeuu_edicion_especial/viaje-usa',
          ['_lg', '_xl'],
          '.png',
          'Persona contemplando la vista del Cañon Colorado',
          '_xl'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/eeuu_edicion_especial/itinerario-usa_des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el recorrido a realizar en EEUU',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/eeuu_edicion_especial/itinerario-usa_mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el recorrido a realizar en EEUU',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'estados-unidos',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-estados-unidos',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'los-angeles',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/los-angeles',
              ['_xs'],
              '.jpg',
              'Vista de Los Angeles en un atardecer',
              '_xs'
            ),
          },
          {
            name: 'williams',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/williams',
              ['_xs'],
              '.jpg',
              'Ruta de Williams con cielo despejado',
              '_xs'
            ),
          },
          {
            name: 'moab',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/moab',
              ['_xs'],
              '.jpg',
              'Vita de parque nacional Arches',
              '_xs'
            ),
          },
          {
            name: 'panguitch',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/panguitch',
              ['_xs'],
              '.jpg',
              'Vista panorámica del parque Bryce Canyon',
              '_xs'
            ),
          },
          {
            name: 'st-george',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/st-george',
              ['_xs'],
              '.jpg',
              'Vista panorámica del Zion Park',
              '_xs'
            ),
          },
          {
            name: 'las-vegas',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/las-vegas',
              ['_xs'],
              '.jpg',
              'Cartel de bienvenida de Las Vegas',
              '_xs'
            ),
          },
          // {
          //   name: 'los-angeles',
          //   value: new ImageInfo('/assets/imgs/grupos/square-small/los-angeles', ['_xs'], '.jpg', 'Ciudad de Dar es Salam, donde entre autos, bicicletas y motos las distintas culturas conviven', '_xs'),
          // },
          {
            name: 'fresno',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/fresno',
              ['_xs'],
              '.jpg',
              'Vista panorámica de la ciudad de Fresno',
              '_xs'
            ),
          },
          {
            name: 'yosemite',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/yosemite',
              ['_xs'],
              '.jpg',
              'Vista panorámica del Parque Nacional de Yosemite',
              '_xs'
            ),
          },
          {
            name: 'merced',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/merced',
              ['_xs'],
              '.jpg',
              'Vista de ruta y montaña del Parque Nacional Yosemite',
              '_xs'
            ),
          },
          {
            name: 'san-francisco',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/san-francisco',
              ['_xs'],
              '.jpg',
              'Vista de la ciudad de San Francisco con un cable car train',
              '_xs'
            ),
          },
          {
            name: 'san-luis-obispo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/san-luis-obispo',
              ['_xs'],
              '.jpg',
              'Paisaje con puente y costa con playa',
              '_xs'
            ),
          },
          // {
          //   name: 'san-luis-obispo',
          //   value: new ImageInfo('/assets/imgs/grupos/square-small/san-luis-obispo', ['_xs'], '.jpg', 'cebras en el crater ngorongoro dentro de safari', '_xs'),
          // },
        ];
        break;
      case 'tanzania-otro-viaje-express':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/tanzania_otro_viaje_express/viaje-tanzania',
          ['_lg', '_xl'],
          '.png',
          '',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/tanzania_otro_viaje_express/itinerario-tanzania_des',
          ['_xs', '_sm'],
          '.png',
          '',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/tanzania_otro_viaje_express/itinerario-tanzania_mob',
          ['_xs'],
          '.png',
          '',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'tanzania',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-tanzania',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'vuelo-montevideo-a-dar-es-salaam',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.png',
              'Vista de Los Angeles en un atardecer',
              '_xs'
            ),
          },
          {
            name: 'vuelo-dar-es-salaam-a-montevideo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.jpg',
              'Vista de Los Angeles en un atardecer',
              '_xs'
            ),
          },
          {
            name: 'dar-es-salaam',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/dar-es-salaam',
              ['_xs'],
              '.jpg',
              'Vista de Los Angeles en un atardecer',
              '_xs'
            ),
          },
          {
            name: 'moshi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/moshi',
              ['_xs'],
              '.jpg',
              'Ruta de Williams con cielo despejado',
              '_xs'
            ),
          },
          {
            name: 'tarangire',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/tarangire',
              ['_xs'],
              '.jpg',
              'Vita de parque nacional Arches',
              '_xs'
            ),
          },
          {
            name: 'ngorongoro',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/ngorongoro',
              ['_xs'],
              '.jpg',
              'Vista panorámica del parque Bryce Canyon',
              '_xs'
            ),
          },
          {
            name: 'zanzibar-/-paje',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/zanzibar-paje',
              ['_xs'],
              '.jpg',
              'Vista panorámica del Zion Park',
              '_xs'
            ),
          },
          {
            name: 'zanzibar-/-stone-town',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/zanzibar-stone-town',
              ['_xs'],
              '.jpg',
              'Cartel de bienvenida de Las Vegas',
              '_xs'
            ),
          },
          // {
          //   name: 'los-angeles',
          //   value: new ImageInfo('/assets/imgs/grupos/square-small/los-angeles', ['_xs'], '.jpg', 'Ciudad de Dar es Salam, donde entre autos, bicicletas y motos las distintas culturas conviven', '_xs'),
          // },
          {
            name: 'zanzibar-/-nungwi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/zanzibar-nungwi',
              ['_xs'],
              '.jpg',
              'Vista panorámica de la ciudad de Fresno',
              '_xs'
            ),
          },
          // {
          //   name: 'san-luis-obispo',
          //   value: new ImageInfo('/assets/imgs/grupos/square-small/san-luis-obispo', ['_xs'], '.jpg', 'cebras en el crater ngorongoro dentro de safari', '_xs'),
          // },
        ];
        break;
      case 'ascenso-al-kilimanjaro':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/ascenso_al_kilimanjaro/viaje-kilimanjaro',
          ['_lg', '_xl'],
          '.png',
          '',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/ascenso_al_kilimanjaro/itinerario-kilimanjaro_des',
          ['_xs', '_sm'],
          '.png',
          '',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/ascenso_al_kilimanjaro/itinerario-kilimanjaro_mob',
          ['_xs'],
          '.png',
          '',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'tanzania',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-tanzania',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'vuelo-montevideo-a-dar-es-salaam',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.png',
              '',
              '_xs'
            ),
          },
          {
            name: 'vuelo-dar-es-salaam-a-montevideo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'dar-es-salaam',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/dar-es-salaam',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'moshi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/moshi',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'marangu-gate',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/marangu-gate',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'mandara-hut',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/mandara-hut',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'horombo-hut',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/horombo-hut',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'kibo-hut',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/kibo-hut',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'uhuru-peak',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/uhuru-peak',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];
        break;
      case 'viaje-a-turquia':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/viaje_a_turquia/viaje-a-turquia',
          ['_lg', '_xl'],
          '.jpg',
          '',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/viaje_a_turquia/itinerario-turquia_des',
          ['_xs', '_sm'],
          '.png',
          '',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/viaje_a_turquia/itinerario-turquia_mob',
          ['_xs'],
          '.png',
          '',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'turquia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-turquia',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'vuelo-estambul-a-montevideo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.png',
              '',
              '_xs'
            ),
          },
          {
            name: 'vuelo-montevideo-a-estambul',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'estambul',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/estambul',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'antalya',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/antalya',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'pamukkale',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/pamukkale',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'capadoccia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/capadoccia',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];
        break;
      case 'camino-de-santiago':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/camino_de_santiago/camino-de-santiago',
          ['_lg', '_xl'],
          '.jpg',
          '',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/camino_de_santiago/itinerario-santiago_des',
          ['_xs', '_sm'],
          '.png',
          '',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/camino_de_santiago/itinerario-santiago_mob',
          ['_xs'],
          '.png',
          '',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'turquia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/camino-de-santiago',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];
        this.pageImgs.ciudades = [
          {
            name: 'vuelo-madrid-a-montevideo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.png',
              '',
              '_xs'
            ),
          },
          {
            name: 'vuelo-montevideo-a-madrid',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'madrid',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/madrid',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'triacastela',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/triacastela',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'sarria',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/sarria',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'portomarin',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/portomarin',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'palas-de-rey',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/palas-de-rey',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'melide',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/melide',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'arzua',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/arzua',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'o-pedrouzo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/o-pedrouzo',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'santiago-de-compostela',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/santiago-de-compostela',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
          {
            name: 'portomarin',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/portomarin',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];
        break;
      case 'egipto-express':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/egipto_express/Viaje-Egipto',
          ['_lg', '_xl'],
          '.png',
          '',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/egipto_express/itinerario-egipto_des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          '',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/egipto_express/itinerario-egipto_mob',
          ['_xs'],
          '.png',
          '',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'egipto',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Egipto',
              ['_xs'],
              '.jpg',
              '',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'vuelo-montevideo-a-el-cairo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.png',
              '',
              '_xs'
            ),
          },
          {
            name: 'vuelo-el-cairo-a-montevideo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/vuelo',
              ['_xs'],
              '.png',
              '',
              '_xs'
            ),
          },
          {
            name: 'el-cairo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/El_cairo',
              ['_xs'],
              '.jpg',
              'Vista de Los Angeles en un atardecer',
              '_xs'
            ),
          },
          {
            name: 'giza',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Giza',
              ['_xs'],
              '.jpg',
              'Ruta de Williams con cielo despejado',
              '_xs'
            ),
          },
          {
            name: 'luxor',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Luxor',
              ['_xs'],
              '.jpg',
              'Vita de parque nacional Arches',
              '_xs'
            ),
          },
          {
            name: 'asuan',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Asuan',
              ['_xs'],
              '.jpg',
              'Vista panorámica del parque Bryce Canyon',
              '_xs'
            ),
          },
          {
            name: 'sharm-el-sheikh',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Sharm-el-Sheik',
              ['_xs'],
              '.png',
              'Vista panorámica del Zion Park',
              '_xs'
            ),
          },
        ];
        break;
      case 'sabanas-africanas':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/sabanas_africanas/viaje-Africa',
          ['_lg', '_xl'],
          '.png',
          'Leones en un safari en África',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/sabanas_africanas/itinerario-Africa-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el recorrido a realizar en Sabanas Africanas',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/sabanas_africanas/itinerario-Africa-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el recorrido a realizar en Sabanas Africanas',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'sudafrica',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/Conoce-sudafrica',
              ['_xs'],
              '.png',
              'La Table Mountain, una de las 7 maravillas naturales del mundo',
              '_xs'
            ),
          },
          {
            name: 'zimbaue',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/recorre-zimbabwe',
              ['_xs'],
              '.png',
              'Manada de elefantes en un Safari por el Chobe National Park',
              '_xs'
            ),
          },
          {
            name: 'tanzania',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-tanzania',
              ['_xs'],
              '.png',
              '2 masai caminando por las playas de agua turquesa de Zanzibar',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'ciudad-del-cabo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/ciudad-del-cabo',
              ['_xs'],
              '.png',
              'Vista aérea de Ciudad del Cabo',
              '_xs'
            ),
          },
          {
            name: 'johanesburgo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Johanesburgo',
              ['_xs'],
              '.png',
              'Edificio de Johanesburgo con la cara pintada de Mandela',
              '_xs'
            ),
          },
          {
            name: 'nelspruit',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Nelspruit',
              ['_xs'],
              '.png',
              'Piscina de un Lodge en Nelspruit',
              '_xs'
            ),
          },
          {
            name: 'kruger-park',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Kruger-park',
              ['_xs'],
              '.png',
              '2 jirafas cruzando frente a los autos de viajeros en el Kruger Park',
              '_xs'
            ),
          },
          {
            name: 'bulawayo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Bulawayo',
              ['_xs'],
              '.png',
              'Calles en la ciudad de Bulawayo',
              '_xs'
            ),
          },
          {
            name: 'victoria-falls',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Victoria-Falls',
              ['_xs'],
              '.png',
              'Las cataratas victoria con el arcoíris',
              '_xs'
            ),
          },
          {
            name: 'dar-es-salaam',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Dar-Es-Salam',
              ['_xs'],
              '.png',
              'Ciudad de Dar es Salam, donde entre autos, bicicletas y motos las distintas culturas conviven',
              '_xs'
            ),
          },
          {
            name: 'zanzibar',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Zanzibar',
              ['_xs'],
              '.png',
              'Playas paradisíacas de Zanzibar en Tanzania',
              '_xs'
            ),
          },
          {
            name: 'arusha',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Arusha',
              ['_xs'],
              '.png',
              'mercado de comidas local en Arusha',
              '_xs'
            ),
          },
          {
            name: 'parque-taranguire',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Parque-Taranguire',
              ['_xs'],
              '.png',
              'safari en el parque Taranguire en Tanzania',
              '_xs'
            ),
          },
          {
            name: 'crater-ngorongoro',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Crater-Ngorongoro',
              ['_xs'],
              '.png',
              'cebras en el crater ngorongoro dentro de safari',
              '_xs'
            ),
          },
        ];
        break;
      case 'lazos-meridionales':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/lazos_meridionales/Viaje-Marruecos-Portugal',
          ['_lg', '_xl'],
          '.png',
          'Techo decorado con arabescos, ventanas con vitrales y una lampara de techo colgando',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/lazos_meridionales/Itinerario-Peninsula-Iberica-Norte-Africa-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Lazos Meridionales',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/lazos_meridionales/Itinerario-Peninsula-Iberica-Norte-Africa-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Lazos Meridionales',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'portugal',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/recorre-Portugal',
              ['_xs'],
              '.png',
              'Vista de la ciudad de Oporto a orillas de Rio Duero atravesado por el Puente Luis I ',
              '_xs'
            ),
          },
          {
            name: 'marruecos',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Marruecos',
              ['_xs'],
              '.png',
              'Fachada del fuerte de arcilla Kasbah de Taourirt en Ouarzazate',
              '_xs'
            ),
          },
        ];
        this.pageImgs.ciudades = [
          {
            name: 'marrakech',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Marrakech',
              ['_xs'],
              '.png',
              'Pared de estilo árabe del jardin interior de la Madrasa de Ben Youssef',
              '_xs'
            ),
          },
          {
            name: 'desierto-erg-chebbi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Desierto-Erg-Chebbi',
              ['_xs'],
              '.png',
              'Arena y dunas anaranjadas del Desierto de Erg Chebbi',
              '_xs'
            ),
          },
          {
            name: 'fez',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Fez',
              ['_xs'],
              '.png',
              'Piscinas de tintas de colores en Curtiduría Chouwara en medio de la Medina de Fez ',
              '_xs'
            ),
          },
          {
            name: 'rabat',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Rabat',
              ['_xs'],
              '.png',
              'Fachada del fuerte de arcilla Kasbah de Taourirt en Ouarzazate',
              '_xs'
            ),
          },
          {
            name: 'oporto',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Oporto',
              ['_xs'],
              '.png',
              'Vista del Puente Luis I de la iluminada ciudad de Oporto al anochecer',
              '_xs'
            ),
          },
          {
            name: 'lisboa',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Lisboa',
              ['_xs'],
              '.png',
              'Famoso tranvia de Lisboa subiendo la colina por las estrechas calles de la ciudad',
              '_xs'
            ),
          },
          {
            name: 'albufeira',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Albufeira',
              ['_xs'],
              '.png',
              'Tipicas construcciones blancas con terrazas al borde de los barrancos a las costas del Atlantico ',
              '_xs'
            ),
          },
        ];
        break;
      case 'mojones-de-medio-oriente':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/mojones_de_medio_oriente/Viaje-Egipto-Jordania-Israel-Turquia',
          ['_lg', '_xl'],
          '.png',
          'Una de las 3 pirámides de Egipto en el Cairo',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/mojones_de_medio_oriente/Itinerario-Medio-Oriente-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Mojones de Medio Oriente',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/mojones_de_medio_oriente/Itinerario-Medio-Oriente-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Mojones de Medio Oriente',
          '_sm'
        );

        this.pageImgs.paises = [
          {
            name: 'egipto',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Egipto',
              ['_xs'],
              '.png',
              'Las pirámides de Egipto junto con la esfinge, admiradas por los viajeros',
              '_xs'
            ),
          },
          {
            name: 'jordania',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/recorre-Jordania',
              ['_xs'],
              '.png',
              'Paseo en globo por el desierto de wadi rum en Jordania',
              '_xs'
            ),
          },
          {
            name: 'israel',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/conoce-israel',
              ['_xs'],
              '.png',
              'ciudad de tel aviv desde el aire',
              '_xs'
            ),
          },
          {
            name: 'turquia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/explora-turquia',
              ['_xs'],
              '.png',
              'Paseo en globo en la ciudad de Cappadoccia',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'el-cairo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/El_cairo',
              ['_xs'],
              '.png',
              'Las pirámides de Egipto junto con la esfinge, admiradas por los viajeros',
              '_xs'
            ),
          },
          {
            name: 'giza',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Giza',
              ['_xs'],
              '.png',
              'Una persona local traslada un camello con las pirámides de fondo',
              '_xs'
            ),
          },
          {
            name: 'luxor',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Luxor',
              ['_xs'],
              '.png',
              'Templo de Luxor',
              '_xs'
            ),
          },
          {
            name: 'asuan',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Asuan',
              ['_xs'],
              '.png',
              'Templo de Abu Simbel',
              '_xs'
            ),
          },
          {
            name: 'sharm-el-sheikh',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Sharm-el-Sheik',
              ['_xs'],
              '.png',
              'Playas paradisíacas de la ciudad costera de Egipto Sharm el Sheik',
              '_xs'
            ),
          },
          {
            name: 'aqaba',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Aqaba',
              ['_xs'],
              '.png',
              'Desierto en Aqaba',
              '_xs'
            ),
          },
          {
            name: 'wadi-rum',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Wadi-Rum',
              ['_xs'],
              '.png',
              'Camellos en el desierto de Wadi Rum',
              '_xs'
            ),
          },
          {
            name: 'aman',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Aman',
              ['_xs'],
              '.png',
              'Bandera de Jordania sobresale entre los edificio de la capital de Jordania, Amman',
              '_xs'
            ),
          },
          {
            name: 'jerusalem',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Jerusalem',
              ['_xs'],
              '.png',
              'Atardecer en la ciudad de Jerusalem',
              '_xs'
            ),
          },
          {
            name: 'tel-aviv',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Tel-Aviv',
              ['_xs'],
              '.png',
              'Vista aérea de Tel Aviv en Israel',
              '_xs'
            ),
          },
          {
            name: 'capadoccia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Capadoccia',
              ['_xs'],
              '.png',
              'Paseo en globo Aerostático ',
              '_xs'
            ),
          },
          {
            name: 'estambul',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Estambul',
              ['_xs'],
              '.png',
              'Vista de Estambul desde un barco en el Bósforo ',
              '_xs'
            ),
          },
        ];
        break;
      case 'latitud-cero':
        // console.log('START this.pageImgs');
        // console.log(this.pageImgs);
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/latitud_cero/Viaje-Ecuador',
          ['_lg', '_xl'],
          '.png',
          'Viajero caminando por un camino de tierra en subida en el medio de la montaña hacia Lodge con un pico nevado de fondo',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/latitud_cero/Itinerario-Ecuador-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Latitud Cero',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/latitud_cero/Itinerario-Ecuador-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Latitud Cero',
          '_xs'
        );
        this.pageImgs.paises = [
          {
            name: 'ecuador',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Ecuador',
              ['_xs'],
              '.png',
              '',
              '_xs'
            ),
          },
        ];
        this.pageImgs.ciudades = [
          {
            name: 'quito',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Quito',
              ['_xs'],
              '.png',
              'Calle en bajada de la ciudad de Quito con edificios antiguos de 2 pisos con blacones ',
              '_xs'
            ),
          },
          {
            name: 'otavalo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Otavalo',
              ['_xs'],
              '.png',
              'Vista de la ciudad de Otavalo con sus montañas de fondo tapadas por las nubes',
              '_xs'
            ),
          },
          {
            name: 'quilotoa',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Quilotoa',
              ['_xs'],
              '.png',
              'Laguna turquesa de Quilotoa en medio del antiguo volcán en la cordillera ecuatoriana',
              '_xs'
            ),
          },
          {
            name: 'banos',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Banos',
              ['_xs'],
              '.png',
              'Puente peatonal colgante que atraviesa el cañon frente a la cascada de Palion del Diablo en Baños',
              '_xs'
            ),
          },
          {
            name: 'cuenca',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Cuenca',
              ['_xs'],
              '.png',
              'Calle de la ciudad colonial de Cuena con sus casas color pastel',
              '_xs'
            ),
          },
          {
            name: 'guayaquil',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Guayaquil',
              ['_xs'],
              '.png',
              'Vista de una Iglesia en la ciudad de Guayaquil y sus casas coloridas de fondo',
              '_xs'
            ),
          },
          {
            name: 'montanita',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Montanita',
              ['_xs'],
              '.png',
              'Acantilado al borde de la paya de Montañita un día gris',
              '_xs'
            ),
          },
          {
            name: 'galapagos',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Galapagos',
              ['_xs'],
              '.png',
              'Tortuga marina nadando bajo el agua entre los corales en Galapagos',
              '_xs'
            ),
          },
        ];

        // console.log('END this.pageImgs');
        // console.log(this.pageImgs);
        break;

      case 'arenas-del-indico':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/arenas_del_indico/Viaje-Tailandia-Camboya-Indonesia-Singapur',
          ['_lg', '_xl'],
          '.png',
          'Acantilados y rocas con vegetación en el medio del mar',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/arenas_del_indico/Itinerario-Sudeste-Asiatico-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          '',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/arenas_del_indico/Itinerario-Sudeste-Asiatico-mob',
          ['_xs'],
          '.png',
          '',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'tailandia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/Conoce-Tailandia',
              ['_xs'],
              '.png',
              'Barcos Longtail tipicos en playa de Phi Phi en tailandia',
              '_xs'
            ),
          },
          {
            name: 'camboya',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/recorre-Camboya',
              ['_xs'],
              '.png',
              'Templo Angkor Wat con su reflejo reverso en el lago',
              '_xs'
            ),
          },
          {
            name: 'indonesia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/conoce-Indonesia',
              ['_xs'],
              '.png',
              'Playa Dreamland de agua turquesa y grandes olas en Bali',
              '_xs'
            ),
          },
          {
            name: 'singapur',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/explora-Singapur',
              ['_xs'],
              '.png',
              'Hotel Marina Bay Sands al atardecer y la ciudad de fondo',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'bangkok',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Bangkok',
              ['_xs'],
              '.png',
              'Edificio principal del Palacio Real de Bangkok',
              '_xs'
            ),
          },
          {
            name: 'phi-phi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Phi-Phi',
              ['_xs'],
              '.png',
              'Vista aérea de la costa de la isla de Phi phi y sus rutas entre las montañas',
              '_xs'
            ),
          },
          {
            name: 'phuket',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Phuket',
              ['_xs'],
              '.png',
              'Playa de agua turquesa con bacos',
              '_xs'
            ),
          },
          {
            name: 'siem-reap',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Siem-Reap',
              ['_xs'],
              '.png',
              'Cara del Templo Bayon en Angkor Wat ',
              '_xs'
            ),
          },
          {
            name: 'bali',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Bali',
              ['_xs'],
              '.png',
              'Terrazas de arrozales en Ubud',
              '_xs'
            ),
          },
          {
            name: 'gili',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Gili',
              ['_xs'],
              '.png',
              'Mujer nadando entre ronda de estatuas bajo el mar en Gili',
              '_xs'
            ),
          },
          {
            name: 'singapur',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Singapur',
              ['_xs'],
              '.png',
              'Arboles artificiales iluminados en violeta del Garden by the Bay de Singapur',
              '_xs'
            ),
          },
        ];
        break;
      case 'caminos-a-caminar':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/caminos_a_caminar/Viaje-Peru',
          ['_lg', '_xl'],
          '.png',
          'Vista de Machu Picchu',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/caminos_a_caminar/Itinerario-Peru-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Caminos a Caminar',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/caminos_a_caminar/Itinerario-Peru-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Caminos a Caminar',
          '_sm'
        );

        this.pageImgs.paises = [
          {
            name: 'peru',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Peru',
              ['_xs'],
              '.png',
              'Dos cholitas sentadas con una baby alpaca en el medio',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'cusco',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Cuzco',
              ['_xs'],
              '.png',
              'Panoramica de una ciudad colonial y la cordillera de fondo',
              '_xs'
            ),
          },
          {
            name: 'trekking-salkantay',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Trekking-Salkantay',
              ['_xs'],
              '.png',
              'Cuatro viajeros haciendo trekking por las montañas',
              '_xs'
            ),
          },
        ];
        break;
      case 'rumbo-cubano':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/rumbo_cubano/Viaje-Cuba',
          ['_xs', '_sm', '_md', '_lg', '_xl'],
          '.png',
          'Clasico auto antiguo cubano por las calle de La Habana Vieja',
          '_sm'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/rumbo_cubano/Itinerario-Cuba-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Latitud Cero',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/rumbo_cubano/Itinerario-Cuba-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Latitud Cero',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'cuba',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Cuba',
              ['_xs'],
              '.png',
              'Ciudad cubana de edificios antiguos y plazas arboladas',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'la-habana',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/La-Habana',
              ['_xs'],
              '.png',
              'Casas de estilo colonial coloridas en La Habana',
              '_xs'
            ),
          },
          {
            name: 'cienfuegos',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Cienfuegos',
              ['_xs'],
              '.png',
              'Fachada lateral del edificio del Municipio de la Ciudad de Cienfuegos',
              '_xs'
            ),
          },
          {
            name: 'trinidad',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Trinidad',
              ['_xs'],
              '.png',
              'Vista desde lo alto de la Iglesia y la Plaza Mayor de Trinidad Cuba',
              '_xs'
            ),
          },
          {
            name: 'santa-clara',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Santa-Clara',
              ['_xs'],
              '.png',
              'Busto de  Ernesto Che Guevara en el Mausoleo de Santa Clara',
              '_xs'
            ),
          },
          {
            name: 'cayo-guillermo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Cayo-Guillermo',
              ['_xs'],
              '.png',
              'Playa paradisiaca de agua turquesa y arena blanca en Cayo Guillermo Cuba',
              '_xs'
            ),
          },
          {
            name: 'cayo-santa-maria',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Cayo-Santa-Maria',
              ['_xs'],
              '.png',
              'Playa de agua turquesa, arena blanca y palmeras en Cayo Santa María Cuba',
              '_xs'
            ),
          },
          {
            name: 'varadero',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Varadero',
              ['_xs'],
              '.png',
              'Resort sobre la playa paradisiaca de Varadero ',
              '_xs'
            ),
          },
        ];

        break;
      case 'postales-de-oceania':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/postales_de_oceania/Viaje-Nueva-Zelanda-Australia',
          ['_lg', '_xl'],
          '.png',
          'Vista aérea de la serpenteante ruta sobre la costa en Nueva Zelanda',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/postales_de_oceania/Itinerario-Oceania-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Postales de Oceania',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/postales_de_oceania/Itinerario-Oceania-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Postales de Oceania',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'nueva-zelanda',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/Viaja-a-Nueva-Zelanda',
              ['_xs'],
              '.png',
              'Motorhome acampando en la noche en algun lugar de Nueva Zelanda',
              '_xs'
            ),
          },
          {
            name: 'australia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/Recorre-Australia',
              ['_xs'],
              '.png',
              'Barcos navegando en Bahía de Sydney con la Ópera de fondo',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'auckland',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Auckland',
              ['_xs'],
              '.png',
              'Ciudad de Auckland al atardecer desde la Bahía',
              '_xs'
            ),
          },
          {
            name: 'tauranga',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Tauranga',
              ['_xs'],
              '.png',
              'Vista aérea de la costa de la ciudad de Tauranga',
              '_xs'
            ),
          },
          {
            name: 'rotorua',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Rotorua',
              ['_xs'],
              '.png',
              'Piscina de roca volcánica del Parque de orgen volcánico de Wai o Tapu',
              '_xs'
            ),
          },
          {
            name: 'taupo',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Taupo',
              ['_xs'],
              '.png',
              'Pequeñas cascadas de gran caudal de las llamadas Huka Falls ',
              '_xs'
            ),
          },
          {
            name: 'wellington',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Wellington',
              ['_xs'],
              '.png',
              'Playa desertica rodeada de cerros en Wellington',
              '_xs'
            ),
          },
          {
            name: 'nelson',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Nelson',
              ['_xs'],
              '.png',
              'Vista de la costa y las sierras de la ciudad de Nelson ',
              '_xs'
            ),
          },
          {
            name: 'christchurch',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Christchurch',
              ['_xs'],
              '.png',
              'Coloridas tiendas de la peatonal New Regent Street de la ciudad de Christchurch',
              '_xs'
            ),
          },
          {
            name: 'lago-pukaki',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Lago-Pukaki',
              ['_xs'],
              '.png',
              'Lago Pukaki color turquesa al pie del nevado Monte Cook',
              '_xs'
            ),
          },
          {
            name: 'queenstown',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Queenstown',
              ['_xs'],
              '.png',
              'Vista aérea de la ciudad de Queentown rodeada de lagos y montañas',
              '_xs'
            ),
          },
          {
            name: 'te-anau',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Te-Anau',
              ['_xs'],
              '.png',
              'Muelle de madera en el lago Te Anau frente a las montañas',
              '_xs'
            ),
          },
          {
            name: 'franz-josef-glacier',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Franz-Josef-Glacier',
              ['_xs'],
              '.png',
              'Reflejo anaranjado del sol sobre el glaciar Franz Josef',
              '_xs'
            ),
          },
          {
            name: 'greymouth',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Greymouth',
              ['_xs'],
              '.png',
              'Vista aérea de la desembocadura del Rio Grey en la costa de la ciudad de Greymouth',
              '_xs'
            ),
          },
          {
            name: 'sydney',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Sydney',
              ['_xs'],
              '.png',
              'Opera de Sydney iliminada en la noche vista desde el otro lado de la bahia',
              '_xs'
            ),
          },
        ];
        break;
      case 'rutas-ticas':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/rutas_ticas/Viaje-Costa-Rica',
          ['_lg', '_xl'],
          '.png',
          'Catarata La Fortuna en medio de la tupida selva de Costa Rica',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/rutas_ticas/Itinerario-Costa-Rica-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Latitud Cero',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/rutas_ticas/Itinerario-Costa-Rica-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Latitud Cero',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'costa-rica',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Costa-Rica',
              ['_xs'],
              '.png',
              'Viajera atravesando el puente colgante en la selva costarricense',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'san-jose',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/San-Jose',
              ['_xs'],
              '.png',
              'Fachada iluminada del Teatro Nacional Costa Rica al anochecer en San José',
              '_xs'
            ),
          },
          {
            name: 'rio-tarcoles',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Rio-Tarcoles',
              ['_xs'],
              '.png',
              'Río Tarcoles y su margen entre la vegetación de la selva',
              '_xs'
            ),
          },
          {
            name: 'parque-manuel-antonio',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Parque-Manuel-Antonio',
              ['_xs'],
              '.png',
              'Isla unida al continente por un brazo de tierra rodeada de agua turquesa en el Parque Manuel Anotnio',
              '_xs'
            ),
          },
          {
            name: 'monteverde',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Monteverde',
              ['_xs'],
              '.png',
              'Puente colgante de hierro en la selva costarricense',
              '_xs'
            ),
          },
          {
            name: 'guanacaste',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Guanacaste',
              ['_xs'],
              '.png',
              'Camino de tierra sobre las sierra de Guanacaste en Costa Rica',
              '_xs'
            ),
          },
          {
            name: 'rio-celeste',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Rio-Celeste',
              ['_xs'],
              '.png',
              'Cascada que forma una piscina de agua turquesa en medio de la selva ',
              '_xs'
            ),
          },
          {
            name: 'parque-volcan-arenal',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Parque-Volcan-Arenal',
              ['_xs'],
              '.png',
              'Volcan ',
              '_xs'
            ),
          },
          {
            name: 'catarata-la-fortuna',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Catarata-La-Fortuna',
              ['_xs'],
              '.png',
              'Catarata La Fortuna en medio de la tupida selva de Costa Rica',
              '_xs'
            ),
          },
          {
            name: 'parque-tortuguero',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Parque-Tortuguero',
              ['_xs'],
              '.png',
              'Bote navegando uno de los canales del Parque Nacional Tortuguero',
              '_xs'
            ),
          },
          {
            name: 'parque-cahuita',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Parque-Cahuita',
              ['_xs'],
              '.png',
              'Playa bordeada por exhubertante vegetacion en el Parque Nacional Cahuita',
              '_xs'
            ),
          },
        ];
        break;
      case 'sendas-misticas':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/sendas_misticas/Viaje-India-Nepal-Tailandia',
          ['_lg', '_xl'],
          '.png',
          'Turistas ingresando al Taj Mahal',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/sendas_misticas/Itinerario-India-Nepal-Tailandia-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Sendas Misticas',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/sendas_misticas/Itinerario-India-Nepal-Tailandia-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Sendas Misticas',
          '_xs'
        );

        this.pageImgs.paises = [
          {
            name: 'india',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/Viaja-a-India',
              ['_xs'],
              '.png',
              'Viajera admirando un templo hindu',
              '_xs'
            ),
          },
          {
            name: 'nepal',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/Recorre-Nepal',
              ['_xs'],
              '.png',
              'Monjes budistas nepalies caminando en la montaña',
              '_xs'
            ),
          },
          {
            name: 'tailandia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/Conoce-Tailandia',
              ['_xs'],
              '.png',
              'Barcos Longtail tipicos en playa de Phi Phi en tailandia',
              '_xs'
            ),
          },
        ];

        this.pageImgs.ciudades = [
          {
            name: 'delhi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Delhi',
              ['_xs'],
              '.png',
              'Anciano hindu caminando las estrechas calles de Old Delhi',
              '_xs'
            ),
          },
          {
            name: 'agra',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Agra',
              ['_xs'],
              '.png',
              'El  inmenso Taj Mahal de marmol blanco y sus jardines ornamentados al amanecer',
              '_xs'
            ),
          },
          {
            name: 'jaipur',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Jaipur',
              ['_xs'],
              '.png',
              'Fachada de arenisca roja del Palacio de los Vientos en Jaipur',
              '_xs'
            ),
          },
          {
            name: 'varanasi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Varanasi',
              ['_xs'],
              '.png',
              'Botes navegando el Rio Ganges observando los rituales en las escalinatas de Varanasi',
              '_xs'
            ),
          },
          {
            name: 'pokhara',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Pokhara',
              ['_xs'],
              '.png',
              'Pico nevado perteneciente a la cordillera del Himalaya',
              '_xs'
            ),
          },
          {
            name: 'dampus-trekking',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Dampus-Trekking',
              ['_xs'],
              '.png',
              'Cadena del Annapurna nevada vista desde el View Point de Dampus',
              '_xs'
            ),
          },
          {
            name: 'katmandu',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Katmandu',
              ['_xs'],
              '.png',
              'Cupula dorada de la pagoda de Swayambhunath con los ojos de Buda y los banderines de Nepal',
              '_xs'
            ),
          },
          {
            name: 'bangkok',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Bangkok',
              ['_xs'],
              '.png',
              'Edificio principal del Palacio Real de Bangkok',
              '_xs'
            ),
          },
          {
            name: 'phi-phi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Phi-Phi',
              ['_xs'],
              '.png',
              'Vista aérea de la costa de la isla de Phi phi y sus rutas entre las montañas',
              '_xs'
            ),
          },
        ];
        break;
      case 'avatares-del-sudeste':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/avatares_del_sudeste/Viaje-Malasia-Singapur-Vietnam-Filipinas',
          ['_lg', '_xl'],
          '.png',
          'Bahía de Halong con rosado atardecer vista desde un bote entre las grandes rocas',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/avatares_del_sudeste/Itinerario-Malasia-Singapur-Vietnam-Filipinas-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Avatares del Sudeste',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/avatares_del_sudeste/Itinerario-Malasia-Singapur-Vietnam-Filipinas-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Avatares del Sudeste',
          '_xs'
        );
        this.pageImgs.paises = [
          {
            name: 'malasia',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Malasia',
              ['_xs'],
              '.png',
              'Vista de la silueta de los eificios de la ciudad de Kuala Lumpur al atardecer',
              '_xs'
            ),
          },
          {
            name: 'singapur',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/recorre-Singapur',
              ['_xs'],
              '.png',
              'Hotel Marina Bay Sands al atardecer y la ciudad de fondo',
              '_xs'
            ),
          },
          {
            name: 'vietnam',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/conoce-Vietnam',
              ['_xs'],
              '.png',
              'Cyclo vietnamita de transporte de pasajeros por las calles de la ciudad de Hanoi',
              '_xs'
            ),
          },
          {
            name: 'filipinas',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-Filipinas',
              ['_xs'],
              '.png',
              'Vista aérea de la playa turquesa de El Nido y sus montañas de fondo',
              '_xs'
            ),
          },
        ];
        this.pageImgs.ciudades = [
          {
            name: 'kuala-lumpur',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Kuala-Lumpur',
              ['_xs'],
              '.png',
              'Las torres petronas iluminadas por la noche',
              '_xs'
            ),
          },
          {
            name: 'singapur',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Singapur',
              ['_xs'],
              '.png',
              'Arboles artificiales iluminados en violeta del Garden by the Bay de Singapur',
              '_xs'
            ),
          },
          {
            name: 'ho-chi-minh',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Ho-Chi-Minh',
              ['_xs'],
              '.png',
              'Tuneles Cu Chi bajo tierra iluminados usadps en la guerra de Vietnam',
              '_xs'
            ),
          },
          {
            name: 'hoian',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Hoian',
              ['_xs'],
              '.png',
              'Tienda tipica de estilo oriental Hoian ofrece plantas y lamparas',
              '_xs'
            ),
          },
          {
            name: 'hanoi',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Hanoi',
              ['_xs'],
              '.png',
              'Vietnamita sentado en medio de la via del tren que atravisa el Old Quarter de la ciudad de Hanoi',
              '_xs'
            ),
          },
          {
            name: 'halong-bay',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Halong-Bay',
              ['_xs'],
              '.png',
              'Cruceros navegando en la Bahía de Halong entre las grandes islas de piedra y vegetacion',
              '_xs'
            ),
          },
          {
            name: 'puerto-princesa',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Puerto-Princesa',
              ['_xs'],
              '.png',
              'Bote entrando a la cueva donde corre el Río Subterraneo',
              '_xs'
            ),
          },
          {
            name: 'el-nido',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/El-Nido',
              ['_xs'],
              '.png',
              'Tipico bote filipino en la playa de El Nido rodeado de las grandes rocas con vegetacion',
              '_xs'
            ),
          },
          {
            name: 'manila',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-small/Manila',
              ['_xs'],
              '.png',
              'Coloridos edificios de diferentes alturas de la ciudad de Manila',
              '_xs'
            ),
          },
        ];
        break;
      case 'culturas-orientales':
        this.pageImgs.cabezal = new ImageInfo(
          '/assets/imgs/grupos/culturas_orientales/Viaje-Japon-Corea-del-Sur-Hong-Kong-China',
          ['_lg', '_xl'],
          '.png',
          'Turistas posando para la foto en la Gran Muralla China',
          '_lg'
        );
        this.pageImgs.mapaDesktop = new ImageInfo(
          '/assets/imgs/grupos/culturas_orientales/Itinerario-Japon-Corea-del-Sur-Hong-Kong-China-des',
          ['_xs', '_sm', '_md', '_lg'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Avatares del Sudeste',
          '_sm'
        );
        this.pageImgs.mapaMobile = new ImageInfo(
          '/assets/imgs/grupos/culturas_orientales/Itinerario-Japon-Corea-del-Sur-Hong-Kong-China-mob',
          ['_xs'],
          '.png',
          'Mapa donde se muestra el itinerario a realizar en el viaje Avatares del Sudeste',
          '_xs'
        );

        (this.pageImgs.paises = [
          {
            name: 'japon',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-a-Japon',
              ['_xs'],
              '.png',
              'Turistas y locales recorriendo el centro de una ciudad de Japon',
              '_xs'
            ),
          },
          {
            name: 'corea-del-sur',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/recorre-Corea-del-Sur',
              ['_xs'],
              '.png',
              'La torre se Seul vista de lejos al atardecer',
              '_xs'
            ),
          },
          {
            name: 'hong-kong',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/conoce-Hong-Kong',
              ['_xs'],
              '.png',
              'Calle tipica del centro de Hong Kong por la noche',
              '_xs'
            ),
          },
          {
            name: 'china',
            value: new ImageInfo(
              '/assets/imgs/grupos/square-horizontal/viaja-China',
              ['_xs'],
              '.png',
              'Edificios del distrito financiero iluminados en la noche de Shanghai visto desde el otro lado del Bund',
              '_xs'
            ),
          },
        ]),
          (this.pageImgs.ciudades = [
            {
              name: 'tokio',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Tokio',
                ['_xs'],
                '.png',
                'Calle comercial de Tokio iluminada por las marquesinas de colores',
                '_xs'
              ),
            },
            {
              name: 'fujiyoshida',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Fujiyoshida',
                ['_xs'],
                '.png',
                'Santuario de estilo japones redeado de cerezos con vistas al monte Fuji',
                '_xs'
              ),
            },
            {
              name: 'kioto',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Kioto',
                ['_xs'],
                '.png',
                'Geishas paseando por una xalle del barrio histórico Higashiyama en la ciudad de Kioto',
                '_xs'
              ),
            },
            {
              name: 'nara',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Nara',
                ['_xs'],
                '.png',
                'Ciervo entre dos esculturas de un templo de la ciudad de Nara',
                '_xs'
              ),
            },
            {
              name: 'osaka',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Osaka',
                ['_xs'],
                '.png',
                'Canal Dotonbori en medio de la iluminada y colorida ciudad de Osaka',
                '_xs'
              ),
            },
            {
              name: 'seul',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Seul',
                ['_xs'],
                '.png',
                'Viajeros caminando por una calle iluminada por maqeusinas de colores en la ciudad de Seul',
                '_xs'
              ),
            },
            {
              name: 'jeju',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Jeju',
                ['_xs'],
                '.png',
                'Vista aérea del extinto volcan de Jeju redeado por el mar de Japon',
                '_xs'
              ),
            },
            {
              name: 'busan',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Busan',
                ['_xs'],
                '.png',
                'Turistas disfrutando de la playa en la ciudad de Busan Corea del Sur',
                '_xs'
              ),
            },
            {
              name: 'hong-kong',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Hong-Kong',
                ['_xs'],
                '.png',
                'Puerta de Entrada al Temple Street Night Market de Hong Kong',
                '_xs'
              ),
            },
            {
              name: 'yangshuo',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Yangshuo',
                ['_xs'],
                '.png',
                'Bote tipicos en embarcadero del Rio Li entre las montañas de Yangshuo',
                '_xs'
              ),
            },
            {
              name: 'pekin',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Pekin',
                ['_xs'],
                '.png',
                'La Gran Muralla china vista en su extension rodeada de verde vegetacion',
                '_xs'
              ),
            },
            {
              name: 'xian',
              value: new ImageInfo(
                '/assets/imgs/grupos/square-small/Xian',
                ['_xs'],
                '.png',
                'Estatuas de terracota tamaño real de los guerreros del emperador de Xian',
                '_xs'
              ),
            },
          ]);
        break;
      default:
        break;
    }
  }

  // async setSeoMetas() {

  //   const slug = this.getViajeSlug('-');
  //   this.meta.updateTag({name: 'description', content: 'Un viaje expeditivo, con el fin de conocer y descubrir lugares nuevos, de conocer gente, de sentarse a tomar una cerveza al final del día, de reir, de caminar'});

  //   switch ( slug ) {
  //     case 'sabanas-africanas':
  //     break;
  //     case 'lazos-meridionales':
  //     break;
  //     case 'mojones-de-medio-oriente':
  //     break;
  //     case 'latitud-cero':
  //       this.title.setTitle( this.globalConstants.getTitlePrefix() + 'Viajamos a Ecuador: 20 días - Octubre 2021' );
  //       this.meta.updateTag({name: 'keywords', content: 'Paisajes, selva, zona andina, isla galapagos, fauna, costa, avistamiento, tortugas marinas, lagos, aguas termales, mitad del mundo, laguna quilotoa, quito, otavalo, baños, cuenca, guayaquil, montañita, volcanes, cataratas, playas'});
  //       break;
  //       case 'arenas-del-indico':
  //         break;
  //         case 'caminos-a-caminar':
  //           break;
  //           case 'rumbo-cubano':
  //             break;
  //             case 'postales-de-oceania':
  //               this.meta.updateTag({name: 'keywords', content: 'paisajes,acantilados,vistas,montañas,glaciares,motorhome,queenstown,glaciar fox'});
  //               break;
  //               case 'rutas-ticas':
  //       this.meta.updateTag({name: 'keywords', content: 'Tico, pura vida, fauna, flora, selva, deportes extremos, playa, caribe, pacifico, gallo pinto, bosques tropicales, volcanes, rio tarcoles, parque manuel antonio, monteverde, tirolesas, canopy, guanacaste, rio celeste, volcan arenal, catarata la fortuna, tortuguero, cahuita, rio celeste, biodiversidad, mono araña, centro de rescate jaguar, rafting, perezosos, clima tropical, naturaleza, bosque nuboso, puentes colgantes'});
  //       break;
  //       case 'sendas-misticas':
  //       this.meta.updateTag({name: 'keywords', content: 'cultura,rio ganges,taj mahal,senderismo,himalaya,rafting,india,agra,bunggee,islas Phi Phi, Bangkok, Playas paraíso'});
  //     break;
  //     case 'avatares-del-sudeste':
  //     break;
  //     case 'culturas-orientales':
  //     break;
  //     default:
  //     break;
  //     }
  // }

  closeContactModal() {
    this.showModal = false;
    this.loadingContact = false;
    this.successMsg = false;
    this.errorMsg = false;
    this.nameTxt = '';
    this.mailTxt = '';
    this.phoneTxt = '';
    this.msgTxt = '';
    this.fieldsError = '';
  }

  showContactModal(type, country = '') {
    this.showModal = true;

    this.modalType = type;

    this.tutoqueCountrySelected = country;
  }

  contact() {
    this.showContact = true;
  }

  getViajeSlug(pSeparator = undefined) {
    if (pSeparator == undefined) {
      return this.slug;
    } else {
      return this.slug.replace(/_/gi, pSeparator);
    }
  }

  getSlug(pTxt, separator = '_') {
    pTxt = pTxt
      .toLowerCase()
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u')
      .replace(/ñ/gi, 'n')
      .replace(/ \/ /gi, ' ')
      .split(' ')
      .join(separator);

    pTxt = pTxt.replace(/\_\+\_/gi, separator);
    //console.log("RUTA: "+ pTxt);
    return pTxt;
  }

  getSlugImg(pTxt, separator = '-') {
    pTxt = pTxt
      .toLowerCase()
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u')
      .replace(/ñ/gi, 'n')
      .replace(/ \/ /gi, ' ')
      .split(' ')
      .join(separator);

    pTxt = pTxt.replace(/\_\+\_/gi, separator);

    console.log("RUTA: " + pTxt);

    return pTxt;
  }


  setViajeSlug(pTxt, pSeparator = '_') {
    this.slug = pTxt
      .toLowerCase()
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u')
      .replace(/ñ/gi, 'n')
      .split(' ')
      .join(pSeparator);

    this.slug = this.slug.replace(/\_\+\_/gi, '_');
  }

  toSlug(pTxt, pSeparator = '_') {
    return (
      pTxt
        .toLowerCase()
        .replace(/á/gi, 'a')
        .replace(/é/gi, 'e')
        .replace(/í/gi, 'i')
        .replace(/ó/gi, 'o')
        .replace(/ú/gi, 'u')
        .replace(/ñ/gi, 'n')
        .replace(/\./gi, '')
        // .replace(/./gi, '')
        .split(' ')
        .join(pSeparator)
    );
  }

  getImagePais(pSlug) {
    const countries = this.pageImgs.paises;
    // console.log(countries);
    let lCountry: any;

    countries.forEach((country) => {
      if (country.name == pSlug) {
        lCountry = country.value;
      }
    });

    return lCountry;
  }

  getImageCiudad(pSlug) {
    const cities = this.pageImgs.ciudades;
    // console.log(cities);
    let lCity: any;

    cities.forEach((city) => {
      if (city.name.trim() == pSlug.trim()) {
        lCity = city.value;
      }
    });

    return lCity;
  }

  @HostListener('window:scroll', ['$event'])
  infoFooter(event) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset > 150) {
        this.showInfoFooter = true;
        if (
          window.innerHeight + window.scrollY + 100 >=
          document.body.offsetHeight
        ) {
          this.showInfoFooter = false;
        } else {
          this.showInfoFooter = true;
        }
      } else {
        this.showInfoFooter = false;
      }

      let scrollTop = window.pageYOffset;
      let elementOffset: any = document.getElementById('test');

      if (elementOffset != null && elementOffset != undefined) {
        elementOffset = elementOffset.getBoundingClientRect().top;
      }
      // let distance = (elementOffset - scrollTop);

      if (elementOffset < 100 && !this.loading) {
        document.body.classList.add('fixed-column');
      } else {
        document.body.classList.remove('fixed-column');
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.remove('fixed-column');
    }
  }

  changeSelectItinerarios(event) {
    const val = event.target.value;
    let aux = 0;

    this.itinerariosDB.forEach((element) => {
      element.selected = false;

      if (val == aux) {
        this.itinerarioSelected = element;
        element.selected = true;
      }

      aux++;
    });
  }

  getCantDias(pais) {
    let sum = 0;

    pais.ciudades.forEach((ciu) => {
      sum += ciu.itinerariociudadCantdias;
    });

    if (sum == 1) {
      return sum + ' día';
    } else {
      return sum + ' días';
    }
  }

  getSquareHorizontalImg(paisName) {
    let str = paisName
      .trim()
      .toLowerCase()
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u')
      .replace(/ñ/gi, 'n')
      .split(' ')
      .join('_');

    return '/assets/imgs/grupos/square-horizontal/' + str + '.png';
  }

  getSquareSmallImg(ciudadName) {
    let str = ciudadName
      .trim()
      .toLowerCase()
      .toLowerCase()
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u')
      .replace(/ñ/gi, 'n')
      .split(' ')
      .join('_');

    return '/assets/imgs/grupos/square-small/' + str + '.png';
  }

  getSugerencias() {
    this.route.params.subscribe(async (params) => {
      const param = params['viaje'];

      this._sugerenciaService.getSugerencias(param).subscribe((res: any) => {
        if (res.status == 'success') {
          this.sugerencias = res.data;
          console.log("SUGERENCIAS: " + this.sugerencias);
          this.sugerencias.forEach((sug) => {
            console.log('sug :>> ', sug);
            if (sug.tipo == 'Grupo') {
              this.hasSugerenciaGrupo = true;
            } else if (sug.tipo == 'Paquete') {
              this.hasSugerenciaPaquete = true;
            } else if (sug.tipo == 'Combinado') {
              this.hasSugerenciaCombinado = true;
            }
          });
        }
      });
    });
  }

  send() {
    this.fieldsError = '';
    if (
      this.nameTxt != '' &&
      this.mailTxt != '' &&
      this.phoneTxt != '' &&
      this.msgTxt != '' &&
      this.globalConstants.validateEmail(this.mailTxt) &&
      this.myRecaptcha
    ) {
      this.loadingContact = true;
      this._contactService
        .sendContactGrupo(
          this.nameTxt,
          this.phoneTxt,
          this.mailTxt,
          this.viaje.viajeName,
          this.msgTxt
        )
        .subscribe((res: any) => {
          this.loadingContact = false;
          if (res.status == 'success') {
            this.successMsg = true;
          } else {
            this.errorMsg = true;
          }
        });
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }

  sobreViaje(event = 'desktop') {
    if (isPlatformBrowser(this.platformId)) {
      document
        .querySelector('#sobreViaje')
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
    }
  }

  sobreCombinalos(event = 'desktop') {
    if (isPlatformBrowser(this.platformId)) {
      document
        .querySelector('#sobreCombinalos')
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
    }
  }

  sobreItinerario(event = 'desktop') {
    if (isPlatformBrowser(this.platformId)) {
      document
        .querySelector('#sobreItinerario')
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
    }
  }

  contactState(event = 'desktop') {
    if (isPlatformBrowser(this.platformId)) {
      document
        .querySelector('#contact')
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
    }
  }

  getCiudadDescription(descripcion) {

  }



  sendModal() {
    this.fieldsError = '';

    if (
      this.nameTxt != '' &&
      this.mailTxt != '' &&
      this.phoneTxt != '' &&
      this.msgTxt != '' &&
      this.globalConstants.validateEmail(this.mailTxt) &&
      this.myRecaptcha
    ) {
      this.loadingContact = true;

      if (this.modalType == 'tutoque') {
        this._contactService
          .sendContactTutoque(
            this.nameTxt,
            this.phoneTxt,
            this.mailTxt,
            this.msgTxt
          )
          .subscribe((res: any) => {
            this.loadingContact = false;
            if (res.status == 'success') {
              this.successMsg = true;
            } else {
              this.errorMsg = true;
            }
          });
      }
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }
}
