import { isPlatformServer } from '@angular/common';
import { Directive, Inject, PLATFORM_ID, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';

@Directive({
    selector: "[appShellRender]"
})
export class AppShellRenderDirective implements OnInit {
    
    constructor(
            @Inject(PLATFORM_ID) private platformId, 
            private templateRef: TemplateRef<any>,
            private viewContainer: ViewContainerRef 
        ) {
        
        
        
    }

    ngOnInit() {
        if ( isPlatformServer(this.platformId) ) {
            // console.log(this.templateRef);
            // console.log('appShellRender - server');
            // console.log(this.templateRef);
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            // console.log('appShellRender -     browser');
            this.viewContainer.clear();
        }
    }

}