<div class="footer-container">
    <div class="logo-dtyt-footer">
        <a routerLink="./" target="_blank">
            <img src="/assets/imgs/dtyt_logo.svg" alt="">
        </a>
    </div>
    <div class="footer-btns desktop">
        <div class="footer-btn-item location-btn">
            <a routerLink="/privacidad" target="_blank">
                <mat-icon>security</mat-icon>
                <!-- <mat-icon>lock</mat-icon>-->
                 <div class="footer-btn-item-text">
                     Política de privacidad
                 </div>
            </a>
        </div>
        <div class="footer-btn-item location-btn">
            <img src="/assets/imgs/location.svg" alt="">
            <div class="footer-btn-item-text">
                21 de setiembre 2323
            </div>
        </div>
        <div class="footer-btn-item phone-btn">
            <a href="tel:+59824183898" target="_blank">
                <img src="/assets/imgs/phone.svg" alt="">
                <div class="footer-btn-item-text">
                    (+598) 2418 DTYT (3898)
                </div>
            </a>
        </div>
        <div class="footer-btn-item whatsapp-btn">
            <a href="https://wa.me/59892443898" target="_blank">
                <img src="/assets/imgs/wp.svg" alt="">
                <div class="footer-btn-item-text">
                    +598 92 443 898
                </div>
            </a>
        </div>
        <div class="footer-btn-item fb-btn">
            <a href="https://www.facebook.com/detoqueytoque" target="_blank">
                <img src="/assets/imgs/facebook.svg" alt="">
            </a>
        </div>
        <div class="footer-btn-item ig-btn">
            <a href="https://www.instagram.com/viajes.detoqueytoque" target="_blank">
                <img src="/assets/imgs/instagram.svg" alt="">
            </a>
        </div>
        <!-- <div class="footer-btn-item wp-btn">
            <a href="https://wa.me/59892003898" target="_blank">
                <img src="/assets/imgs/wp.svg" alt="">
            </a>
        </div> -->
    </div>
    <div class="footer-btns mobile">
        <div class="social-btns">
            <div class="footer-btn-item fb-btn">
                <a href="https://www.facebook.com/detoqueytoque" target="_blank">
                    <img src="/assets/imgs/facebook.svg" alt="">
                </a>
            </div>
            <div class="footer-btn-item ig-btn">
                <a href="https://www.instagram.com/detoqueytoque" target="_blank">
                    <img src="/assets/imgs/instagram.svg" alt="">
                </a>
            </div>
            <!-- <div class="footer-btn-item wp-btn">
                <a href="https://wa.me/59892003898" target="_blank">
                    <img src="/assets/imgs/wp.svg" alt="">
                </a>
            </div> -->
            <div class="clearfix"></div>
        </div>
        <div class="footer-text">
            <div class="footer-btn-item whatsapp-btn">
                <a href="https://wa.me/59892443898" target="_blank">
                    <img src="/assets/imgs/wp.svg" alt="">
                    <div class="footer-btn-item-text">
                        +598 92 443 898
                    </div>
                </a>
            </div>
            <div class="footer-btn-item location-btn">
                <img src="/assets/imgs/location.svg" alt="">
                <div class="footer-btn-item-text">
                    21 de setiembre 2323
                </div>
            </div>
            <div class="footer-btn-item phone-btn">
                <a href="https://wa.me/59892443898" target="_blank">
                    <img src="/assets/imgs/phone.svg" alt="">
                    <div class="footer-btn-item-text">
                        (+598) 2418 DTYT (3898)
                    </div>
                </a>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>