
<div *appShellNoRender>
    <app-header type="search-top" image="/assets/imgs/bg-main-banner.png"></app-header>
</div>
<div class="home-top-arrow" *appShellNoRender>
    <img src="/assets/imgs/icons/black-arrow.svg" alt="">
</div>
<div *appShellNoRender>
    <app-main-banner></app-main-banner>
</div>
<div *appShellNoRender>
    <div class="section-container card-section mobile" *appShellNoRender>
        <div class="section-wrapper" *appShellNoRender>
            <div class="section-title">
                Viajamos de 3 formas...
            </div>
            <div class="section-description">
                Con el paso del tiempo y de la etapa que atraviesa cada persona, las 3 formas de viajar tienen un sabor especial y particular...
            </div>
            <div class="swiper-box">
                <swiper [config]="globalConstants.getSwiperConfig()">
                    <div class="swiper-slide small-swiper-slide">
                        <div class="card">
                            <a routerLink="/calendario">
                                <app-card title="Expediciones" description="Viajemos juntos por el mundo. Mirá el calendario 2024" color="green" [cardimg]="pageImgs.cardExpediciones"></app-card>
                            </a>
                        </div>
                    </div>
                    <div class="swiper-slide small-swiper-slide">
                        <div class="card">
                            <a href="https://vuelanrecibidos.com/" target="_blank">
                                <app-card title="Vuelan Recibidos 2025" description="El viaje de todas las Facultades y Universidades" color="red" [cardimg]="pageImgs.cardUnivs"></app-card>
                            </a>
                        </div>
                    </div>

                    <div class="swiper-slide small-swiper-slide">
                        <div class="card">
                            <a routerLink="/pasajes360">
                                <app-card title="360" description="Personalizado y a medida. Pasajes, paquetes, viajes corporativos" color="yellow" [cardimg]="pageImgs.card360"></app-card>
                            </a>
                        </div>
                    </div>
                </swiper>
                <div class="clearfix "></div>
                <div class="swiper-custom-pagination "></div>
            </div>
        </div>
    </div>
    <div class="section-container card-section desktop">
        <div class="section-wrapper">
            <div class="section-title">
                Viajamos de 3 formas...
            </div>
            <div class="section-description">
                Con el paso del tiempo y de la etapa que atraviesa cada persona, las 3 formas de viajar tienen un sabor especial y particular...
            </div>
        </div>
        <div class="section-wrapper">
            <div class="card col-4">
                <a routerLink="/calendario">
                    <app-card title="Expediciones" description="Viajemos juntos por el mundo. Mirá el calendario 2024" color="green" [cardimg]="pageImgs.cardExpediciones"></app-card>
                </a>
            </div>
            <div class="card col-4">
                <a href="https://vuelanrecibidos.com/" target="_blank">
                    <app-card title="Vuelan Recibidos 2025" description="El viaje de todas las Facultades y Universidades" color="red" [cardimg]="pageImgs.cardUnivs"></app-card>
                </a>
            </div>
            <div class="card col-4">
                <a routerLink="/pasajes360">
                    <app-card title="360" description="Personalizado y a medida. Pasajes, paquetes, viajes corporativos" color="yellow" [cardimg]="pageImgs.card360"></app-card>
                </a>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="section-container standard-section dark">
        <div class="section-wrapper container section-wrapper-medium-desktop section-story">
            <div class="mosaic">
                <div class="col-2 col-small-screen-1">
                    <div class="section-title">
                        Una forma diferente de viajar
                    </div>
                    <div class="section-description">
                        Si te gusta viajar cómodo, con todo arreglado y previsto, si te gustan las piscinas y los buses con aire acondicionado, y en un viaje tener todo lo más parecido a lo que tienes en casa. Si no te gusta caminar y perderte, -casi casi por gusto-, si te gustan
                        los atajos a los caminos largos, si te gusta lo seguro, el colchón protector del hábito y la rutina, si no te gusta entreverarte con la gente local, ni probar sus comidas, si tu objetivo único es el descansar y ser pasivo, y no
                        tener nada que ver en organizaciones. Si no te gusta cambiar colores, ni combinarlos, ni crear nuevos, si no te importan los recuerdos que marcan -o los que te marcan no te dejan importar otros-, si estás tan lleno de estructuras
                        y esquemas que sólo con ellos te sientes protegido, si prefieres los latidos regulares a las emociones que te quitan el aliento, si no te gusta cansarte, ni perderte, ni ensuciarte, ni los andenes inciertos de los que -hay quienes-
                        llaman la aventura, entonces, no viajes con nosotros.<br><br>Pero, si en cambio, te gusta ser parte decisiva y decisoria en tus viajes. Si te gusta ir un poco más allá del afiche de la agencia, y quebrar
                        el famoso 80/20, si te interesa alterar la noria de tu rutina, llenarla de memorias indelebles, si te interesa conocer las maravillas del mundo, si te gusta caminar volcanes, ascender montañas, ir a playas remotas, perderte en
                        templos milenarios. Conocer gente nueva sintiendo que realmente la estás conociendo y a la vez haciéndote conocer terminas conociéndote un poco más. Si te gustan esas tardecitas de balcón, compartiendo cansancio, anécdotas y una
                        cerveza o un mate, si te llaman esas historias que vimos en las matinees, y, acaso, deseas comenzar otro capítulo, u otra serie, o terminar uno, levar anclas y perderte en callejones, llenos de incertezas, suspiros y momentos inesperados,
                        si apuestas a esos pocos momentos que hacen que valgan la pena tantos tránsitos, esos momentos que son los que atesoramos para siempre, que nos dan ese gozo interno de lo diferente, esos que nos hacen saltear latidos y mirar si
                        hay alguien al lado para compartirlos.<br><br>Si estás abierto mentalmente a una nueva forma de viajar, si te pica la curiosidad de qué es eso, si quieres ese encare diferente de una experiencia antropológica y estás dispuesto
                        a pagar el precio del cansansio, del caminar, de la exposición, del dinamismo extra y tienes esa actitud de creer que las mejores cosas en la vida muchas veces pasan cuando perdemos el avión de lo planificado, de lo cierto y de
                        lo imprevesible. Entonces, prepará tu mochila liviana, agéndate un par de championes cómodos, desempolva el pasaporte, desarreglá las emociones y prepará tus sentidos. – pero no te arregles el pelo –<br><br>Tenemos un viaje para
                        vos…
                    </div>
                </div>
                <div class="col-2 col-small-screen-1">
                    <div>
                        <div class="col-1 first-element">
                            <div class="btn-img no-pointer large-btn-img">

                                <app-picture [image]="pageImgs.homeBottom1"></app-picture>

                            </div>
                        </div>
                        <div>
                            <div class="col-2 col-2-small">
                                <div class="btn-img no-pointer small-btn-img">
                                    <app-picture [image]="pageImgs.homeBottom2"></app-picture>
                                </div>
                            </div>
                            <div class="col-2 col-2-small">
                                <div class="btn-img no-pointer small-btn-img">
                                    <app-picture [image]="pageImgs.homeBottom3"></app-picture>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-1">
                            <div class="btn-img no-pointer large-btn-img">
                                <app-picture [image]="pageImgs.homeBottom4"></app-picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="section-container standard-section full-wide-banner">
        <div class="section-wrapper container">
            <div id="contactForm" class="center-column">
                <div class="contact-boxx">
                    <app-loader *ngIf="loadingContact" type="small"></app-loader>
                    <!-- <div class="contact-content" *ngIf="!loading && !successMsg && !errorMsg"> -->
                    <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
                        <div class="contact-title section-title">¿Nos juntamos? Contanos cual es tu idea y coordinamos para charlar de tu viaje!</div>
                        <div class="contact-input">
                            <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
                        </div>
                        <div class="contact-input">
                            <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
                        </div>
                        <div class="contact-input">
                            <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
                        </div>
                        <div class="contact-input">
                            <textarea name="msgTxt" placeholder="Mensaje..." cols="" rows="" [(ngModel)]="msgTxt"></textarea>
                        </div>
                        <div class="fields-error" *ngIf="fieldsError != ''">
                            {{ fieldsError }}
                        </div>
                        <div class="recaptcha">
                            <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
                        </div>
                        <div class="contact-btn btn-green" (click)="send()">
                            ENVIAR
                        </div>
                    </div>
                    <div class="contact-content contact-message" *ngIf="!loadingContact && successMsg">
                        <div class="contact-title section-title">
                            <img src="/assets/imgs/icons/success.svg" alt=""> Gracias por comunicarte, queríamos avisarte que vamos a estar cerrados del 1 de enero al 9 inclusive.
                            Nos pondremos en contacto contigo a partir del martes 10. ¡¡¡Que tengas un muy feliz comienzo de año!!!
                        </div>
                    </div>
                    <div class="contact-content contact-message" *ngIf="!loadingContact && errorMsg">
                        <div class="contact-title section-title">
                            <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error! Llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898" target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
