<app-header type="search-top"></app-header>
<!--<app-top-full-banner [image]="pageImgs.cabezal" title="Política de privacidad " description=" ">
</app-top-full-banner>-->
<div class="banner"> </div>
<div class="texto-banner">
    Política de privacidad
</div>
<div id="test" class="section-container column-section">
    <div class="section-wrapper container section-wrapper-medium-desktop">
        <br><br>
        <div class="row">
            <div class="section-title">
                1. Política de Privacidad y Seguridad.
            </div>
            <div class="section-description">
                La siguiente información sobre nuestra Política de Privacidad refleja el compromiso de Experiencia al
                Mundo
                SA., por mantener y garantizar relaciones comerciales seguras mediante la protección de los datos
                personales, garantizando el derecho a la privacidad de cada uno de los usuarios de nuestros servicios.
                En
                este documento explicamos cómo utilizamos los datos personales de los usuarios del portal web.
                <br><br>
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                2. Definición de dato personal
            </div>
            <div class="section-description">
                Como “Dato Personal” debemos entender cualquier información concerniente a una persona física
                identificada o
                identificable. Entre otros, se incluyen el nombre, apellidos, la dirección postal y electrónica, así
                como el
                número de teléfono.
                <br><br>
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                3. Tratamientos y finalidades de los datos recogidos
            </div>
            <div class="section-description">
                Cualquier dato personal que nos facilite al visitar cualquiera de nuestros portales web o con la
                instalación
                de nuestra aplicación móvil, será tratado de conformidad con las normas de protección de datos y sólo
                serán
                recogidos, tratados y utilizados para fines lícitos, legítimos e informados. Por ello, detallamos todas
                las
                finalidades con las que utiliza datos personales Experiencia al Mundo SA. como responsable del
                tratamiento:
                <br><br>
                <div style="text-indent: 2em;">
                    • Gestionar los formularios de registro para poder convertirse en usuarios registrados y acceder a
                    los
                    servicios, además para contactarles y responderles sobre las cuestiones planteadas o recibir
                    información
                    comercial sobre nuevos productos y novedades de la empresa.
                </div>
                <div style="text-indent: 2em;">
                    • Otra finalidad para que se recopilan datos es para responder a las consultas mediante el envío de
                    formularios de contacto. Los datos obtenidos a través de esta vía son para responder a los propios
                    usuarios
                    y remitirle información comercial, además de incluirlos en la lista de distribución.
                </div>
                <br>
                <div style="text-indent: 2em;">
                    Desde Experiencia al Mundo SA. informaremos a todos los usuarios el carácter no obligatorio de la
                    recogida
                    de determinados datos de carácter personal, salvo en los campos que se indique lo contrario. No
                    obstante, la
                    no cumplimentación de dichos datos podrá impedir a Experiencia al Mundo SA. prestar todos aquellos
                    Servicios
                    vinculados a tales datos, liberándonos de toda responsabilidad por la no prestación o prestación
                    incompleta
                    de estos Servicios.
                </div>
                <br>
                <div style="text-indent: 2em;">
                    Corresponde al usuario la obligación de facilitar los datos de manera veraz y mantenerlos
                    actualizados, por
                    lo que Experiencia al Mundo SA. se reserva el derecho de excluir de los servicios y proceder a la
                    cancelación del servicio a todo usuario que haya facilitado datos falsos, sin perjuicio de las demás
                    acciones que procedan en Derecho.
                </div>
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                4. Legitimación para el tratamiento de sus datos
            </div>
            <div class="section-description">
                La base legal para el tratamiento de sus datos es la respuesta a las consultas planteadas a través de la
                página web, lo que supone la aplicación, a petición del interesado, de medidas pre-contractuales por
                parte
                del interesado.
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                5. Plazo de conservación de los datos
            </div>
            <div class="section-description">
                Los datos serán conservados el tiempo necesario para dar respuesta a las consultas.
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                6. Comunicación de sus datos personales a terceros
            </div>
            <div class="section-description">
                Sus datos personales no serán comunicados a ningún tercero. En el caso de que fuera necesario comunicar
                su
                información a algún tercero se solicitaría su consentimiento.
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                7. Seguridad de sus datos personales
            </div>
            <div class="section-description">
                Experiencia al Mundo SA. tiene una preocupación especial por garantizar la seguridad de sus datos
                personales. Sus datos son almacenados en nuestros sistemas de información, donde hemos adoptado e
                implantado
                medidas de seguridad, técnicas y organizativas, para prevenir cualquier pérdida o uso no autorizado por
                terceros, por ejemplo nuestros portales web utilizan protocolos de cifrado de datos Https.
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                8. Información sobre la Utilización de cookies
            </div>
            <div class="section-description">
                Por el mero hecho de visitar el presente portal web o utilizar los servicios de Experiencia al Mundo SA.
                no
                queda registrado de forma automática ningún dato de carácter personal que identifique a un Usuario. Sin
                embargo, le informamos que durante la navegación por el Sitio Web se utilizan “cookies”, pequeños
                ficheros
                de datos que se generan en el ordenador del internauta y que nos permiten obtener la siguiente
                información
                analítica:

                <br><br>
                <div style="text-indent: 2em;">
                    a) La fecha y hora de acceso a la Web, permitiendo saber las horas de más afluencia, y hacer los
                    ajustes
                    precisos para evitar problemas de saturación en nuestras horas punta.
                </div>
                <div style="text-indent: 2em;">
                    b) El número de visitantes diarios de cada sección, permitiendo conocer las áreas de más éxito y
                    aumentar
                    y mejorar su contenido, con el fin de que los usuarios obtengan un resultado más satisfactorio y
                    mejorar
                    el
                    diseño de los contenidos.
                </div>
                <div style="text-indent: 2em;">
                    c) La fecha y hora de la última vez que el usuario visitó el Sitio Web para realizar estudios
                    analíticos
                    y estadísticos sobre el uso que tiene la web.
                </div>
                <div style="text-indent: 2em;">
                    d) Elementos de seguridad que intervienen en el control de acceso a las áreas restringidas.
                    Para más información visite nuestra política de cookies
                </div>

            </div>
        </div>

        <div class="row">
            <div class="section-title">
                9. Derechos de los usuarios.
            </div>
            <div class="section-description">
                Todos los usuarios pueden ejercitar cualquier de los derechos otorgados por la normativa de protección
                de
                datos, como el derecho de acceso, rectificación, limitación del tratamiento, supresión, portabilidad de
                datos y oposición que le asisten mediante escrito dirigido a Experiencia al Mundo SA.
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                10. ¿No desea recibir información de nosotros o desea revocar su consentimiento?
            </div>
            <div class="section-description">
                Cualquier usuario puede oponerse al uso de su información para fines publicitarios, investigaciones de
                mercado o desarrollo de encuestas de satisfacción, así como revocar su consentimiento en cualquier
                momento
                (sin efecto retroactivo). Para ello, deberá enviar un correo electrónico a la dirección
                info@www.detoqueytoque.com. Cuando reciba publicidad por correo electrónico, también podrá oponer desde
                dicho correo electrónico, pinchando en el enlace incluido en el mismo y siguiendo las instrucciones que
                le
                sean facilitadas.
            </div>
        </div>

        <div class="row">
            <div class="section-title">
                11. Cambios a la Política de Privacidad.
            </div>
            <div class="section-description">
                Nuestra Política de Privacidad podrá sufrir actualizaciones, debidas a cambios y necesidades legales,
                así
                como debidas a mejoras y cambios incluidos en la forma de ofrecer y prestar nuestros servicios y
                utilidades
                de la aplicación. Por ello, le recomendamos que visite y acceda a nuestra Política de Privacidad
                periódicamente, para poder tener acceso y conocer los últimos cambios que hayan podido ser incorporados.
                En
                caso de que dichos cambios guarden relación con el consentimiento prestado por el usuario, en tal caso
                le
                será enviada una notificación independiente y por separado para recavarlo nuevamente.<br><br>
                Si durante la lectura le ha surgido alguna duda o cuestión sobre nuestra Política de Privacidad o quiere
                ejercitar algún derecho o acción relativa a sus datos personales, por favor póngase en contacto con
                nosotros.
            </div>
        </div>
        <div class="clearfix small-screens"></div>
        <p class="clearfix"></p>
    </div>
</div>

<app-footer></app-footer>