import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

// import { MatAutocompleteTrigger } from '@angular/material';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  stateForm: FormGroup = this._formBuilder.group({
    stateGroup: '',
  });

  public stateGroupOptions: Observable<any[]>;
  public openSearch = false;
  public searchValue = '';
  public mobile = false;
  @ViewChild('origin') private _myAutocompleteTrigger: MatAutocompleteTrigger;

  public matName = 'origin' + Math.floor(Math.random() * 6) + 1;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768) {
        this.mobile = true;
        document.body.classList.add('mobile-device');
      } else {
        document.body.classList.add('desktop-device');
        this.openSearch = true;
      }
    }

    this.stateGroupOptions = this.stateForm
      .get('stateGroup')!
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filterGroup(value))
      );

    // setInterval(() => console.log(this._myAutocomspleteTrigger.openPanel), 1000);
  }

  opened() {
    // alert('a');
    if (isPlatformBrowser(this.platformId)) {
      if (document.getElementsByClassName('cdk-overlay-container')[0]) {
        const overlay = <HTMLElement>(
          document.getElementsByClassName('cdk-overlay-container')[0]
        );
        document.getElementById(this.matName).append(overlay);
        console.log(this.matName);
      }
    }
  }

  openMobileSearch() {
    if (isPlatformBrowser(this.platformId)) {
      this.openSearch = true;
      document.body.classList.add('mobile-search-active');
    }
  }

  closeMobileSearch() {
    if (isPlatformBrowser(this.platformId)) {
      this.openSearch = false;
      document.body.classList.remove('mobile-search-active');
    }
  }

  searchAction() {
    // queryParams: { order: 'popular' }
    this.closeMobileSearch();
    this.router.navigate(['s'], { queryParams: { b: this.searchValue } });
  }

  private _filterGroup(value: string): any[] {
    if (value) {
      return this.stateGroups
        .map((group) => ({
          letter: group.letter,
          names: _filter(group.names, value),
        }))
        .filter((group) => group.names.length > 0);
    }

    return this.stateGroups;
  }

  stateGroups: any[] = [
    {
      letter: 'Expediciones',
      names: [
        // 'Arenas del Índico',
        // 'Avatares del Sudeste',
        // 'Culturas Orientales',
        // 'Ascenso al Kilimanjaro',
        // 'Camino de Santiago',
        // 'Caminos a Caminar',
        // 'EEUU Edición Especial',
        'Egipto Express',
        'Mundial con DTYT',
        // 'Latitud Cero',
        // 'Lazos Meridionales',
        // 'Mojones de Medio Oriente',
        // 'Postales de Oceanía',
        // 'Rumbo Cubano',
        // 'Rutas Ticas',
        // 'Sabanas Africanas',
        // 'Sendas Místicas',
        'Tanzania Otro viaje Express',
        'Viaje a Turquía',
      ],
    },
    {
      letter: 'Países',
      names: [
        'Australia',
        'Camboya',
        'China',
        'Costa Rica',
        'Ecuador',
        'Egipto',
        'Emiratos Árabes',
        'Filipinas',
        'Hong Kong',
        'India',
        'Indonesia',
        'Israel',
        'Japon',
        'Jordania',
        'Malasia',
        'Maldivas',
        'Marruecos',
        'Mongolia',
        'Nueva Zelanda',
        'Nepal',
        'Perú',
        'Rusia',
        'Singapur',
        'Sri Lanka',
        'Sudáfrica',
        'Tailandia',
        'Tanzania',
        'Tibet',
        'Turquía',
        'Vietnam',
        'Zimbabwe',
      ],
    },
  ];

  stateGroupsOLD: any[] = [
    {
      letter: 'A',
      names: ['Alabama', 'Alaska', 'Arizona', 'Arkansas'],
    },
    {
      letter: 'C',
      names: ['California', 'Colorado', 'Connecticut'],
    },
    {
      letter: 'D',
      names: ['Delaware'],
    },
    {
      letter: 'F',
      names: ['Florida'],
    },
    {
      letter: 'G',
      names: ['Georgia'],
    },
    {
      letter: 'H',
      names: ['Hawaii'],
    },
    {
      letter: 'I',
      names: ['Idaho', 'Illinois', 'Indiana', 'Iowa'],
    },
    {
      letter: 'K',
      names: ['Kansas', 'Kentucky'],
    },
    {
      letter: 'L',
      names: ['Louisiana'],
    },
    {
      letter: 'M',
      names: [
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
      ],
    },
    {
      letter: 'N',
      names: [
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
      ],
    },
    {
      letter: 'O',
      names: ['Ohio', 'Oklahoma', 'Oregon'],
    },
    {
      letter: 'P',
      names: ['Pennsylvania'],
    },
    {
      letter: 'R',
      names: ['Rhode Island'],
    },
    {
      letter: 'S',
      names: ['South Carolina', 'South Dakota'],
    },
    {
      letter: 'T',
      names: ['Tennessee', 'Texas'],
    },
    {
      letter: 'U',
      names: ['Utah'],
    },
    {
      letter: 'V',
      names: ['Vermont', 'Virginia'],
    },
    {
      letter: 'W',
      names: ['Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
    },
  ];
}
function queryParams(arg0: string[], queryParams: any, arg2: { b: string; }) {
  throw new Error('Function not implemented.');
}

