import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-viaje',
  templateUrl: './list-viaje.component.html',
  styleUrls: ['./list-viaje.component.scss']
})
export class ListViajeComponent implements OnInit {

  //items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`); // Carga Example SCROLL 
  public listNumbers: number[];
  //public paises;
  //public viajeWebType = 'paises';
  //public viaje;
  //public listPaises;

  // @Input() listNumbers = '';
  @Input() viaje;
  @Input() listPaises;
 // @Input() paises;
  @Input() viajeWebType = 'paises';

  constructor() { }

  ngOnInit() {
    this.listNumbers = [];
    /*for (let index = 0; index <= 100; index++) {
      this.listNumbers.push(index);
    }*/
    //const element = array[index];
   // console.log(this.listNumbers);

    console.log("VIAJE: " + this.viaje);

    console.log("PAISES: " + this.listPaises);


  }


}
