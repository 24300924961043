import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BuscadorService } from '../../services/buscador.service';
import { ContactService } from 'src/app/services/contact.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { isPlatformBrowser } from '@angular/common';  

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit, OnDestroy {

  public txtBusqueda = '';
  public successMsg = false;
  public errorMsg = false;
  public fieldsError = '';
  public loading = true;
  public loadingContact = false;
  public myRecaptcha = false;

  public showModal = false;
  public modalType = '';

  public grupos;
  public paises;
  public ciudades;
  public strictName = '';

  public nameTxt;
  public phoneTxt;
  public mailTxt;
  public msgTxt;

  public wrongSearchValue = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private buscadorService: BuscadorService,
    private contactService: ContactService,
    public globalConstants: GlobalConstants,
    @Inject(PLATFORM_ID) private platformId: any
    ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.add('search-page');
    }

    this.route.queryParams.subscribe(params => {
      this.txtBusqueda = params['b'];
      
      if (this.txtBusqueda == '' || this.txtBusqueda == undefined) {
        this.wrongSearchValue = true;
        this.loading = false;
      } else {
        this.buscadorService.buscar( this.txtBusqueda ).subscribe( (res:any) => {
          this.loading = false;
          if (res.status == 'success') {
            this.wrongSearchValue = false;
            this.grupos = res.data.grupos;
            this.paises = res.data.paises;
            this.ciudades = res.data.ciudades;
            
            if ( res.data.strictName.pais.length > 0 ) {
              this.strictName = res.data.strictName.pais[0].paisName
            } else if ( res.data.strictName.ciudad.length > 0 ) {
              this.strictName = res.data.strictName.ciudad[0].ciudadName
            } else if ( this.grupos.length == 0 ) {
              this.wrongSearchValue = true;
            }
          } else {
            // this.errorMsg = true;
          }
        });
      }
    });

  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.remove('search-page');
    }
  }

  navigate(link:string) {
    if (link != undefined) {
      this.router.navigate([link]);
    }
  }

  getSlug( pTxt, pSeparator = '_' ) {
    return pTxt
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join(pSeparator);
  }

  closeContactModal() {
    this.showModal = false;
    this.loadingContact = false;
    this.successMsg = false;
    this.errorMsg = false;
    this.nameTxt = '';
    this.mailTxt = '';
    this.phoneTxt = '';
    this.msgTxt = '';
    this.fieldsError = '';
  }
  
  showContactModal( type ) {
    this.showModal = true;

    this.modalType = type;
  }

  send() {
    this.fieldsError = '';
    
    if( this.nameTxt != '' && this.mailTxt != '' && this.phoneTxt != '' && this.msgTxt != '' && this.globalConstants.validateEmail( this.mailTxt ) && this.myRecaptcha ) {
      this.loadingContact = true;

      if ( this.modalType == 'tutoque' ) {
        this.contactService.sendContactTutoque(this.nameTxt, this.phoneTxt, this.mailTxt, this.msgTxt).subscribe( (res:any) => {
          this.loadingContact = false;
          if (res.status == 'success') {
            this.successMsg = true;
          } else {
            this.errorMsg = true;
          }
        });
      } else if ( this.modalType == '360' ) {
        this.contactService.sendContact360(this.nameTxt, this.phoneTxt, this.mailTxt, this.msgTxt).subscribe( (res:any) => {
          this.loadingContact = false;
          if (res.status == 'success') {
            this.successMsg = true;
          } else {
            this.errorMsg = true;
          }
        });
      } else if ( this.modalType == '' ) {
        this.contactService.sendContact(this.nameTxt, this.phoneTxt, this.mailTxt, this.msgTxt).subscribe( (res:any) => {
          this.loadingContact = false;
          if (res.status == 'success') {
            this.successMsg = true;
          } else {
            this.errorMsg = true;
          }
        });
      }
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }

}
