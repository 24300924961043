<div class="info-footer info-footer-simple">
    <div class="info-footer-container">
        <div class="info-header">
            <div class="info-header-text">
                <div class="info-header-right-small">
                    ¿A dónde te gustaría ir?
                </div>
                <div class="info-header-right-bigger">
                    Nosotros lo organizamos
                </div>
            </div>

            <div class="clearfix"></div>
        </div>
        <!-- <select class="select-itinerarios" (change)="changeSelectItinerarios($event)">
            <option [value]="i" [selected]="iti.selected" *ngFor="let iti of itinerariosDB; let i = index"><span *ngIf="iti.itinerario.itinerarioPrincipal == 0">{{ iti.itinerario.itinerarioName }}</span><span *ngIf="iti.itinerario.itinerarioPrincipal == 1">Todo el viaje</span></option>
            </select> -->
        <div class="info-footer-btns">
            <div class="sumate-al-viaje btn-standard btn-yellow btn-left" [class.btn-yellow]="color == 'yellow'" [class.btn-blue]="color == 'blue'" (click)="contact()">Escribinos</div>
            <a href="https://wa.me/59892443898" target="_blank">
                <div class="btn-square btn-right whatsapp-btn" (click)="contact()"><img src="/assets/imgs/wp-icon.svg" alt=""></div>
            </a>
        </div>
    </div>
</div>