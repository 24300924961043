<!--<div [class.collapsed]="false" [innerHTML]="description">
</div>-->
<!-- <div (click)="isCollapsed = !isCollapsed" class="see-more" *ngIf="isCollapsed">
    Ver más
</div>
<div (click)="isCollapsed = !isCollapsed" class="see-more" *ngIf="!isCollapsed">
    Ver menos
</div> -->

<span *ngIf="description.length <= wordLimit">
    <div [innerHTML]="description">
    </div>
</span>

<span *ngIf="description.length > wordLimit && !showMore">
   <!-- {{description.substring(0,wordLimit)}}... -->
    <div [innerHTML]="description.substring(0,wordLimit)">
    </div>
    <br>
    <span class="font-weight-bold text-primary see-more" (click)="showMore = true"> Leer Más...</span>
</span>

<span *ngIf="description.length > wordLimit && showMore">
    <div [innerHTML]="description">
    </div> 
    <br>
    <span class="font-weight-bold text-primary see-more" (click)="showMore = false"> Mostrar menos</span>
</span>