<div class="swiper-box swiper-small" [class.dark]="theme == 'dark'" [class.light]="theme == 'light'">
    <div class="swiper-title">
        {{ title }}
    </div>
    <div class="section-text">
        {{ description }}
    </div>
    <swiper [config]="configSwiper" uniqueNavElements="true">
        <div class="swiper-slide small-swiper-slide" *ngFor="let item of listadoItems">
            <div class="slide-image">
                <app-picture [image]="item.image"></app-picture>
                <!-- <img src="{{ item.image }}" alt=""> -->
            </div>
            <div class="small-slide-title" *ngIf="item.title != ''">
                {{ item.title }}
            </div>
            <div class=" small-slide-description " [innerHTML]="item.description">>
                <!-- {{ item.description }} -->
            </div>
        </div>
    </swiper>
    <div class="swiper-custom-prev swiper-custom-arrow swiper-custom-prev-{{randomNumber}}"></div>
    <div class="swiper-custom-next swiper-custom-arrow swiper-custom-next-{{randomNumber}}"></div>
    <div class="clearfix "></div>
    <div class="swiper-custom-pagination "></div>
</div>