import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
// import { StandardItem } from 'src/app/interfaces/interfaces';
import { ImageInfo } from '../../interfaces/image-info.interface';

@Component({
  selector: 'app-swiper-wide',
  templateUrl: './swiper-wide.component.html',
  styleUrls: ['./swiper-wide.component.scss'],
})
export class SwiperWideComponent implements OnInit {
  @Input()
  title = '';
  @Input()
  description = '';
  @Input()
  tipo = '';
  @Input()
  theme = 'light';
  @Input()
  mainColor = 'green';
  @Input()
  listadoItems: any[] = [];
  @Output()
  clickAction = new EventEmitter();

  public randomNumber = Math.floor(Math.random() * 100) + 1;

  public listadoItemsFiltered = []

  public configSwiper: SwiperConfigInterface;

  public configSwiperStandard: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 10,
    slidesOffsetAfter: 100,
    breakpoints: {
      640: {
        slidesPerView: 1.6,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2.2,
        spaceBetween: 10,
        centeredSlides: false,
      },
      1100: {
        slidesPerView: 3.2,
        spaceBetween: 10,
        centeredSlides: false,
      },
    },
    navigation: {
      nextEl: '.swiper-custom-next-'+this.randomNumber,
      prevEl: '.swiper-custom-prev-'+this.randomNumber,
    },
    pagination: true,
    uniqueNavElements: true
  };
  
  public configSwiperJsonType: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 10,
    slidesOffsetAfter: 100,
    breakpoints: {
      640: {
        slidesPerView: 1.6,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2.2,
        spaceBetween: 10,
        centeredSlides: false,
      },
    },
    navigation: {
      nextEl: '.swiper-custom-next-'+this.randomNumber,
      prevEl: '.swiper-custom-prev-'+this.randomNumber,
    },
    pagination: true,
    uniqueNavElements: true
  };

  constructor() {
  }
  
  ngOnInit() {
    if ( this.tipo != 'json' ) {
      this.configSwiper = this.configSwiperStandard;
      
      if ( this.tipo == 'Grupo' ) {
        this.listadoItemsFiltered = this.listadoItems
                                    .filter( el => el.tipo == this.tipo )
                                    .map( item => {

                                      let lImg: ImageInfo;
                                      //const lSlug = this.getSlug( item.item.viajemadre.viajemadreName, '-' );

                                      const lSlug = item.item.viajemadre.viajemadreSlug;

                                      console.log( "CASO DE SUGERENCIA" +lSlug);

                                      switch ( lSlug ) {
                                        case 'sabanas-africanas':
                                          lImg = new ImageInfo('/assets/imgs/grupos/sabanas_africanas/viaje-Africa', ['_xs','_sm'], '.png', 'Leones en un safari en África', '_sm');
                                          break;
                                        case 'eeuu-edicion-especial':
                                          lImg = new ImageInfo('/assets/imgs/grupos/eeuu-edicion-especial/viaje-a-estados-unidos', ['_xs','_sm'], '.jpg', 'Leones en un safari en África', '_sm');
                                          break;
                                        case 'tanzania-otro-viaje-express':
                                          lImg = new ImageInfo('/assets/imgs/grupos/tanzania-otro-viaje-express/viaje-a-tanzania', ['_xs','_sm'], '.jpg', 'Leones en un safari en África', '_sm');
                                          break;
                                        case 'ascenso-al-kilimanjaro':
                                          lImg = new ImageInfo('/assets/imgs/grupos/ascenso-al-kilimanjaro/viaje-kilimanjaro', ['_xs','_sm'], '.jpg', '', '_sm');
                                          break;
                                        case 'viaje-a-turquia':
                                          lImg = new ImageInfo(
                                            '/assets/imgs/grupos/viaje_a_turquia/viaje-a-turquia',
                                            ['_xs', '_sm'],
                                            '.jpg',
                                            '',
                                            '_sm'
                                          );
                                          break;

                                          case 'tailandia-camboya-singapur':
                                            lImg = new ImageInfo(
                                              '/assets/imgs/grupos/camino_de_santiago/camino-de-santiago',
                                              ['_xs', '_sm'],
                                              '.jpg',
                                              '',
                                              '_sm'
                                            );
                                            break;

                                        case 'camino-de-santiago':
                                          lImg = new ImageInfo('/assets/imgs/grupos/camino_de_santiago/camino-de-santiago', ['_xs','_sm'], '.jpg', '', '_sm');
                                          break;
                                        case 'egipto-edicion-especial':
                                          lImg = new ImageInfo('/assets/imgs/grupos/egipto-edicion-especial/viaje-a-egipto', ['_xs','_sm'], '.jpg', 'Leones en un safari en África', '_sm');
                                          break;
                                        case 'lazos-meridionales':
                                          lImg = new ImageInfo('/assets/imgs/grupos/lazos_meridionales/Viaje-Marruecos-Portugal', ['_xs','_sm'], '.png', 'Techo decorado con arabescos, ventanas con vitrales y una lampara de techo colgando', '_sm');
                                          break;
                                        case 'mojones-de-medio-oriente':
                                          lImg = new ImageInfo('/assets/imgs/grupos/mojones_de_medio_oriente/Viaje-Egipto-Jordania-Israel-Turquia', ['_xs','_sm'], '.png', 'Una de las 3 pirámides de Egipto en el Cairo', '_sm');
                                          break;
                                        case 'latitud-cero':
                                          lImg = new ImageInfo('/assets/imgs/grupos/latitud_cero/Viaje-Ecuador', ['_xs','_sm'], '.png', 'Viajero caminando por un camino de tierra en subida en el medio de la montaña hacia Lodge con un pico nevado de fondo', '_sm');
                                          break;
                                        case 'arenas-del-indico':
                                          lImg = new ImageInfo('/assets/imgs/grupos/arenas_del_indico/Viaje-Tailandia-Camboya-Indonesia-Singapur', ['_xs','_sm'], '.png', 'Acantilados y rocas con vegetación en el medio del mar', '_sm');
                                          break;
                                        case 'caminos-a-caminar':
                                          lImg = new ImageInfo('/assets/imgs/grupos/caminos_a_caminar/Viaje-Peru', ['_xs','_sm'], '.png', 'Vista de Machu Picchu', '_sm');
                                          break;
                                        case 'rumbo-cubano':
                                          lImg = new ImageInfo('/assets/imgs/grupos/rumbo_cubano/Viaje-Cuba', ['_xs','_sm'], '.png', 'Clasico auto antiguo cubano por las calle de La Habana Vieja', '_sm');
                                          break;
                                        case 'postales-de-oceania':
                                          lImg = new ImageInfo('/assets/imgs/grupos/postales_de_oceania/Viaje-Nueva-Zelanda-Australia', ['_xs','_sm'], '.png', 'Vista aérea de la serpenteante ruta sobre la costa en Nueva Zelanda', '_sm');
                                          break;
                                        case 'rutas-ticas':
                                          lImg = new ImageInfo('/assets/imgs/grupos/rutas_ticas/Viaje-Costa-Rica', ['_xs','_sm'], '.png', 'Catarata La Fortuna en medio de la tupida selva de Costa Rica', '_sm');
                                          break;
                                        case 'sendas-misticas':
                                          lImg = new ImageInfo('/assets/imgs/grupos/sendas_misticas/Viaje-India-Nepal-Tailandia', ['_xs','_sm'], '.png', 'Turistas ingresando al Taj Mahal', '_sm');
                                          break;
                                        case 'avatares-del-sudeste':
                                          lImg = new ImageInfo('/assets/imgs/grupos/avatares_del_sudeste/Viaje-Malasia-Singapur-Vietnam-Filipinas', ['_xs','_sm'], '.png', 'Bahía de Halong con rosado atardecer vista desde un bote entre las grandes rocas', '_sm');
                                          break;
                                        case 'culturas-orientales':
                                          lImg = new ImageInfo('/assets/imgs/grupos/culturas_orientales/Viaje-Japon-Corea-del-Sur-Hong-Kong-China', ['_xs','_sm'], '.png', 'Turistas posando para la foto en la Gran Muralla China', '_sm');
                                          break;
                                        default:
                                          break;
                                      }



                                      item.image = lImg; 
                                      return item;
                                    });
      } else if ( this.tipo == 'Paquete' ) {
        this.listadoItemsFiltered = this.listadoItems
                                      .filter( el => el.tipo == this.tipo )
                                      .map( item => {
                                        let lImg: ImageInfo;
                                        const lPais = item.item.pais.paisName;

                                        switch ( lPais ) {
                                          case 'Australia':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-australia', ['_sm', '_xs'], '.png', 'Playa paradisíaca de Australia donde viajeros realizan surf', '_sm');
                                            break;
                                          case 'Camboya':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-camboya', ['_sm', '_xs'], '.png', 'Templo principal de Angkor Wat al amanecer', '_sm');
                                            break;
                                          case 'China':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-china', ['_sm', '_xs'], '.png', 'Callejón en una ciudad China por la noche', '_sm');
                                            break;
                                          case 'Dubai':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-dubai', ['_sm', '_xs'], '.png', 'Paisaje típico de Dubai, con sus rascacielos y autopistas gigantescas', '_sm');
                                            break;
                                          case 'Egipto':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-egipto', ['_sm', '_xs'], '.png', 'Pirámides de Egipto junto a la Esfinge. Viajero admirando las pirámides.', '_sm');
                                            break;
                                          case 'Emiratos Arabes Unidos':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-dubai', ['_sm', '_xs'], '.png', 'Paisaje típico de Dubai, con sus rascacielos y autopistas gigantescas', '_sm');
                                            break;
                                          case 'Filipinas':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-filipinas', ['_sm', '_xs'], '.png', 'Big Lagoon de El Nido, Filipinas', '_sm');
                                            break;
                                          case 'Hong Kong':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-hong-kong', ['_sm', '_xs'], '.png', 'Autos en la noche de Hong Kong con sus luces características de los carteles ', '_sm');
                                            break;
                                          case 'India':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-india', ['_sm', '_xs'], '.png', 'Viajero contempla el Taj Mahal, una de las 7 maravillas del mundo', '_sm');
                                            break;
                                          case 'Indonesia':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-indonesia', ['_sm', '_xs'], '.png', 'Entrada a Templo de Indonesia', '_sm');
                                            break;
                                          case 'Israel':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-israel', ['_sm', '_xs'], '.png', 'Cúpula de la roca en Jerusalem', '_sm');
                                            break;
                                          case 'Japón':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-japon', ['_sm', '_xs'], '.png', 'Vista desde lo alto de Japón donde se puede ver el Monte Fuji', '_sm');
                                            break;
                                          case 'Jordania':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-jordania', ['_sm', '_xs'], '.png', 'Entrada a Petra en Jordania', '_sm');
                                            break;
                                          case 'Malasia':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-malasia', ['_sm', '_xs'], '.png', 'Torres Petronas en Kuala Lumpur', '_sm');
                                            break;
                                          case 'Maldivas':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-maldivas', ['_sm', '_xs'], '.png', 'Barco en aguas de Maldivas', '_sm');
                                            break;
                                          case 'Marruecos':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-marruecos', ['_sm', '_xs'], '.png', 'comercio callejero en Marruecos', '_sm');
                                            break;
                                          case 'Mongolia':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-mongolia', ['_sm', '_xs'], '.png', 'Viajero disfruta de la noche junto a un Ger, alojamientos clásicos de los mogoles', '_sm');
                                            break;
                                          case 'Nepal':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-nepal', ['_sm', '_xs'], '.png', 'Montañas del Himalaya vistas desde campamento junto con los banderines nepalies', '_sm');
                                            break;
                                          case 'Nueva Zelanda':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-nueva-zelanda', ['_sm', '_xs'], '.png', 'Ruta vista desde Motor Home con paisajes típicos de Nueva Zelanda', '_sm');
                                            break;
                                          case 'Rusia':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-rusia', ['_sm', '_xs'], '.png', 'Foto donde viajeros en la plaza roja de Moscú, visitan la Catedral de San Basilio', '_sm');
                                            break;
                                          case 'Singapur':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-singapur', ['_sm', '_xs'], '.png', 'jardines de Singapur frente a la marina Bay', '_sm');
                                            break;
                                          case 'Sri Lanka':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-sri-lanka', ['_sm', '_xs'], '.png', 'Barcos a las orillas de las playas de Sri Lanka', '_sm');
                                            break;
                                          case 'Tailandia':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-tailandia', ['_sm', '_xs'], '.png', 'Barcaza característica de Tailandia en aguas de Islas Phi Phi', '_sm');
                                            break;
                                          case 'Tanzania':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-tanzania', ['_sm', '_xs'], '.png', 'Masai, tribu característica de Tanzania saltando en un Safari', '_sm');
                                            break;
                                          case 'Tibet':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-tibet', ['_sm', '_xs'], '.png', 'Monasterio Tibetano observado desde lo alto', '_sm');
                                            break;
                                          case 'Turquía':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-turquia', ['_sm', '_xs'], '.png', 'viajera en Turquía donde observa el paseo en Globo Aerostático', '_sm');
                                            break;
                                          case 'Turquia':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-turquia', ['_sm', '_xs'], '.png', 'viajera en Turquía donde observa el paseo en Globo Aerostático', '_sm');
                                            break;
                                          case 'Vietnam':
                                            lImg = new ImageInfo('/assets/imgs/paises/viaja-a-vietnam', ['_sm', '_xs'], '.png', 'Calles de Vietnam junto a sus características bicicletas', '_sm');
                                            break;
                                          default:
                                            break;
                                        }
                                        item.image = lImg; 
                                        return item;
                                      });


    
                                      
    
      } else {
        // this.listadoItemsFiltered = this.listadoItems;
        
      }
    } else {
      this.configSwiper = this.configSwiperJsonType;
      this.listadoItemsFiltered = this.listadoItems;
    }
  }

  getImg( pTxt ) {
    const lTxt = this.getSlug( pTxt );

    

  }

  contact( country = '') {
    this.clickAction.emit( country );
  }

  getSlug( pTxt, pSeparator = '_' ) {
    let lTxt = pTxt;
    lTxt = lTxt.toLowerCase();

    // lTxt = lTxt.replaceAll()


    return lTxt
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join(pSeparator);
  }

}
