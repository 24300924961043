<div class="swiper-box" #swiperAnimationIn [class.dark]="theme == 'dark'" [class.light]="theme == 'light'">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="section-description">
        {{ description }}
    </div>
    <!-- {{ listadoItemsFiltered | json }} -->
    <swiper id="swiper{{ title }}" class="swiper-{{mainColor}}" [config]="configSwiper" uniqueNavElements="true">
        <div class="swiper-slide image-text-swiper-slide" *ngFor="let item of listadoItemsFiltered">
            <div class="sugerencia-item" *ngIf="item.tipo == 'Grupo' && tipo == 'Grupo'">
                <a routerLink="/grupos/{{getSlug(item.item.viajemadre.viajemadreName, '-')}}">
                    <div class="slide-large-image">
                        <app-picture [image]="item.image"></app-picture>
                        <!-- <img src="/assets/imgs/grupos/{{getSlug(item.item.viajemadre.viajemadreName)}}/desktop_portada_1.png" alt="">-->
                    </div>
                    <div class="small-slide-title" *ngIf="item.title != ''">
                        {{ item.item.viajemadre.viajemadreName }}
                    </div>
                    <div class="small-slide-description">
                        {{ item.cantDias }} días -
                        <span *ngFor="let pais of item.paises; let i = index">
                            <span *ngIf="i>0"> / </span> {{ pais.pais.paisName }}
                        </span>
                    </div>
                </a>
            </div>
            <div class="sugerencia-item" *ngIf="item.tipo == 'Paquete' && tipo == 'Paquete'" (click)="contact( item.item.pais.paisName )">
                <div class="slide-large-image">
                    <app-picture [image]="item.image"></app-picture>
                    <!-- <img src="/assets/imgs/paises/{{getSlug(item.item.pais.paisName)}}.png" alt=""> -->
                </div>
                <div class="small-slide-title" *ngIf="item.title != ''">
                    {{ item.item.pais.paisName }}
                </div>
            </div>
            <div class="sugerencia-item" *ngIf="tipo == 'json'">
                <!-- {{ item | json }} -->
                <div class="slide-large-image">
                    <app-picture [image]="item.img"></app-picture>
                    <!-- <img src="/assets/imgs/paises/{{getSlug(item)}}.png" alt=""> -->
                </div>
                <div class="small-slide-title">
                    {{ item.country }}
                </div>
            </div>
        </div>
    </swiper>
    <div class="swiper-custom-prev swiper-custom-arrow swiper-custom-prev-{{randomNumber}}"></div>
    <div class="swiper-custom-next swiper-custom-arrow swiper-custom-next-{{randomNumber}}"></div>
    <div class="clearfix "></div>
    <div class="swiper-custom-pagination "></div>
</div>