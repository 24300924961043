
<mat-drawer-container class="menu-container" [ngClass]="drawer.opened ? 'menu-open' : 'menu-close'" [hasBackdrop]="true">
    <mat-drawer #drawer mode="over" position="end">
        <div class="menu-item">
            <div class="img-icon">
                <img src="/assets/imgs/icons/inicio.svg" alt="">
            </div>
            <a routerLink="/">
                Inicio
            </a>
        </div>
        <div class="menu-item">
            <div class="img-icon">
                <img src="/assets/imgs/icons/rifa.svg" alt="">
            </div>
            <a routerLink="/rifas">
                Rifas
            </a>
        </div>
        <div class="menu-title">
            Viajes
        </div>
        <div class="menu-item">
            <div class="img-icon">
                <div class="circle-icon green-circle">&nbsp;</div>
            </div>
            <a routerLink="/calendario">
                Expediciones
            </a>
        </div>
        <div class="menu-item">
            <div class="img-icon">
                <div class="circle-icon blue-circle">&nbsp;</div>
            </div>
            <a routerLink="/tu-toque">
                Tu Toque
            </a>
        </div>
        <div class="menu-item">
            <div class="img-icon">
                <div class="circle-icon red-circle">&nbsp;</div>
            </div>
            <a href="https://experienciaalmundo.com">
                Universidades
            </a>
        </div>
        <div class="menu-item">
            <div class="img-icon">
                <div class="circle-icon yellow-circle">&nbsp;</div>
            </div>
            <a routerLink="/pasajes360">
                Pasajes 360
            </a>
        </div>
        <div class="bottom-menu-items">
            <div class="menu-title">
                Contacto
            </div>
            <div class="menu-item">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/whatsapp-icon.svg" alt="">
                </div>
                <a href="https://wa.me/59892443898" target="_blank">
                    (+598) 92 443 898
                </a>
            </div>
            <div class="menu-item">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/phone-icon.svg" alt="">
                </div>
                <a href="tel:+59824183898" target="_blank">
                    (+598) 2418 3898
                </a>
            </div>
            <div class="menu-item">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/mail-icon.svg" alt="">
                </div>
                <a href="mailto:info@detoqueytoque.com" target="_blank">
                    info@detoqueytoque.com
                </a>
            </div>
            <div class="menu-item">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/instagram-icon.svg" alt="">
                </div>
                <a href="https://www.instagram.com/detoqueytoque" target="_blank">
                    @detoqueytoque
                </a>
            </div>
            <div class="menu-item">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/location-icon.svg" alt="">
                </div>
                <a href="https://goo.gl/maps/1EqCYWjNJ7kD39F7A" target="_blank">
                    21 de Setiembre 2323
                </a>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content class="menu-drawer">
        <div class="menu-icon-container">
            <div id="nav-icon" (click)="drawer.toggle()" [ngClass]="drawer.opened ? 'open' : 'close'">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
