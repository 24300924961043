import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuscadorService {

  constructor(private http: HttpClient) { }

  buscar( pTxt ) {
    const json = {
      txt: pTxt
    };

    const params =
      'json=' +  
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/search/web',
      params,
      { headers }
    );
  }
}
 