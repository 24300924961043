import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[appSlug]'
})
export class SlugDirective {

  constructor(el: ElementRef) {
  }

}
