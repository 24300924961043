import { Component, HostListener, OnInit, ElementRef, ViewChild, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GruposService } from '../../services/grupos.service';
import { SugerenciaService } from '../../services/sugerencia.service';
import { ContactService } from '../../services/contact.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { isPlatformBrowser } from '@angular/common';
import { ImageInfo } from 'src/app/interfaces/image-info.interface';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-viajes360',
  templateUrl: './viajes360.component.html',
  styleUrls: ['./viajes360.component.scss'],
})
export class Viajes360Component implements OnInit, OnDestroy {
  public description360 = 'Es atención personalizada, con experiencia y compromiso las 24 hrs.<br>Es conexión junto al que viaje por trabajo o por placer junto a su familia, amigas o amigos<br>Encontramos las mejores opciones en vuelos, paquetes y servicios para personas y empresas.<br>Es desentenderte y disfrutar... del viaje y del proceso.';
  
  public testt = false;

  public loading = true;
  public loadingContact = false;
  public viajePortada = '360-portada.png';
  public icon: string;
  public itinerarioSelected;
  public showInfoFooter = false;
  public itinerariosDB;
  public itinerarioPrincipal;
  public viaje;
  public viajeName;
  public incluyes;
  public paises;
  public sugerencias = [];
  public ciudades = [];
  public paisNames = '';
  public showContact = false;
  public mobile = false;
  public changeItinerarioAnimation = false;

  public nameTxt;
  public phoneTxt;
  public mailTxt;
  public msgTxt;
  public successMsg = false;
  public errorMsg = false;
  public myRecaptcha = false;
  public fieldsError = '';

  public pageImgs = {
    'cabezal': new ImageInfo('/assets/imgs/pasajes-baratos', ['_xs', '_sm', '_xl', '_md', '_lg'], '.png', 'Avión estacionado en un aeropuerto con vista al atardecer', '_xl'),
    'paso1': new ImageInfo('/assets/imgs/tu-toque/Comienza-organizar-tu-viaje', ['_xs', '_sm'], '.png', 'Artículos que son necesarios para organizar un viaje. Mapa, lápiz, cámara de fotos', '_xs'),
    'paso2': new ImageInfo('/assets/imgs/tu-toque/Elije-tu-destino', ['_xs', '_sm'], '.png', 'Persona eligiendo el destino al que quiere viajar', '_sm'),
    'paso3': new ImageInfo('/assets/imgs/tu-toque/Realiza-tu-viaje-planeado', ['_xs', '_sm'], '.png', 'Mochilero viajando por la montaña', '_sm'),
    'tuToqueFooter': new ImageInfo('/assets/imgs/viajes-a-medida', ['_xs', '_sm', '_md', '_lg'], '.png', '', '_lg'),
  };

  @ViewChild('contactForm') contactFormEl : ElementRef; 

  public beneficiosItems: any[] = [
    {
      image: '/assets/imgs/beneficios/beneficios_dhl.jpg',
      title: 'DHL',
      description:
        'Sin salir del hotel, mandá tus compras a Uruguay por DHL y a precios especiales para viajeros de dTyT.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_openapp.jpg',
      title: 'Beneficios 2x1',
      description:
        'Disfrutá de beneficios 2x1 en varios lugares de montevideo con la app “dTyT Beneficios”',
    },
    // {
    //   image: '/assets/imgs/beneficios/beneficios_ramon.jpg',
    //   title: 'Cervecería Ramón',
    //   description:
    //     'Vení a casa y disfrutá de un 2x1 en cerveza todos los días.',
    // },
    // {
    //   image: '/assets/imgs/beneficios/beneficios_trota.jpg',
    //   title: '“Trotamundos: la tienda del viajero”',
    //   description:
    //     'Una tienda de accesorios pensada para viajerxs a disposición para nosotros y a los grupos. Tenemos descuento en todos sus accesorios entre 15% y 50% dependiendo del accesorio. Ubicada en: Bulevar España 2085 esq Maldonado.',
    // },
    {
      image: '/assets/imgs/beneficios/beneficios_appviaje.jpg',
      title: 'APP de viaje',
      description:
        'Una app desarrollada especialmente para los viajeros de dTyT con los vuelos actualizados, información de las ciudades, piques de cada lugar entre muchas otras cosas!',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_buceo.jpg',
      title: 'Curso de Buceo',
      description:
        'Andate de Uruguay con el curso de buceo realizado y buceá en las mejores aguas del mundo con certificado, ahorrando tiempos y dinero en los destinos además de disfrutar mucho mas de la experiencia!',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_ingles.jpg',
      title: 'Curso de Inglés',
      description:
        'Para aprender o practicar, cursos de inglés enfocados 100% a viajes. Un plus a la hora de viajar y poder sumar mas de esos momentos únicos.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_fotografia.jpg',
      title: 'Curso de fotografía para viajes',
      description:
        'Un curso de fotografía enfocado a los viajes… para que los recuerdos que nos quedan en la memoria sean acompañados por imágenes increíbles ya sea en nuestra cámara o en nuestro celular!',
    },
  ];

  constructor(
    private _grupoService: GruposService,
    private _sugerenciaService: SugerenciaService,
    private _contactService: ContactService,
    private route: ActivatedRoute,
    public globalConstants: GlobalConstants,
    private router: Router,
    public el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any,
    private title: Title,
    private meta: Meta
    ) { 
      this.title.setTitle( this.globalConstants.getTitlePrefix() + 'Pasajes 360' );  
      this.meta.updateTag({name: 'description', content: 'Pensando en todo y en vos. Pasajes, paquetes, viajes corporativos y todo lo que precises, personalizado y a medida.'});
      this.meta.updateTag({name: 'keywords', content: 'viajes a medida, viajes de aventura, destinos exóticos, organización de viajes, como organizar mi viaje, destinos baratos'});
    }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768) {
        this.mobile = true;
      }
    }


    this.route.params.subscribe(async (params) => {
      const param = params['viaje'];

      this._grupoService.getViaje( param ).subscribe((res: any) => {
  
        if (res.status == 'success') {
          this.getSugerencias();
          this.itinerariosDB = res.data.itinerarios;
          this.viaje = this.itinerariosDB[0].itinerario.viaje;
          this.viajeName = this.viaje.viajemadre.viajemadreName;
          this.itinerarioPrincipal = res.data.itinerarioPrincipal;
          this.incluyes = res.data.incluye;
          this.itinerarioSelected = this.itinerarioPrincipal;
          this.paises = res.data.paises;
          if ( this.mobile ) {
            this.viajePortada = '/assets/imgs/grupos/' + this.getSlug( this.viaje.viajemadre.viajemadreName ) + '/mobile_portada_1.png'; 
          } else {
            this.viajePortada = '/assets/imgs/grupos/' + this.getSlug( this.viaje.viajemadre.viajemadreName ) + '/desktop_portada_1.png'; 
          }
          this.icon = '/assets/imgs/grupos/' + this.getSlug( this.viaje.viajemadre.viajemadreName ) + '/icon.svg'; 

          let cc = 0;
          this.paises.forEach(el => {
            if (cc === 0) {
              this.paisNames += el.pais.paisName;
            } else {
              this.paisNames += ' - ' + el.pais.paisName;
            }
            
            cc++;
            
            el.ciudades.forEach( ciu => {
              
              if( !this.ciudades.some(e => e.ciudad.ciudadName === ciu.ciudad.ciudadName) ) {
                this.ciudades.push(ciu);
              }
            });
          });
  
          this.itinerariosDB.forEach(element => {
            if(element.itinerario.itinerarioPrincipal === 1) {
              element.selected = true;
            } else {
              element.selected = false;
            }
          });
        }
        
  
        this.loading = false;
      });
    });

  }

  

  contactState(event = 'desktop') {
    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('#contactForm').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }
  }
  
  getSlug( pTxt ) {
    return pTxt
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join('_');
  }

  @HostListener('window:scroll', ['$event'])
  infoFooter(event) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset > 150) {
        this.showInfoFooter = true;
        if (
          window.innerHeight + window.scrollY + 100 >=
          document.body.offsetHeight
        ) {
          this.showInfoFooter = false;
        } else {
          this.showInfoFooter = true;
        }
      } else {
        this.showInfoFooter = false;
      }
  
      let scrollTop = window.pageYOffset;
      let elementOffset = document.getElementById('test').getBoundingClientRect().top;
      let distance = (elementOffset - scrollTop);
  
      
     
      if (elementOffset < 100) {
        document.body.classList.add('fixed-column');
      } else {
        document.body.classList.remove('fixed-column');
      }
    }
  }

  ngOnDestroy(): void {
    {
      document.body.classList.remove('fixed-column');
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // fixedHeaderAction(event) {
    
   
  // }

  changeSelectItinerarios(event){
    const val = event.target.value;
    let aux = 0;
    
    this.itinerariosDB.forEach(element => {
      element.selected = false;
    
      if ( val == aux) {
        
        this.itinerarioSelected = element;
        element.selected = true;
    
      }

      aux++;
    });

  }

  getCantDias( pais ) {
    let sum = 0;

    pais.ciudades.forEach(ciu => {
      sum += ciu.itinerariociudadCantdias;  
    });

    if (sum == 1) {
      return sum + ' día';
    } else {
      return sum + ' días';
    }

  }

  getSquareHorizontalImg(paisName) {
    let str = paisName
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join('_');

    return '/assets/imgs/grupos/square-horizontal/'+ str + '.png';
  }

  getSquareSmallImg(ciudadName) {
    let str = ciudadName
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join('_');
    
    return '/assets/imgs/grupos/square-small/'+ str + '.png';
  }

  getSugerencias() {
    this.route.params.subscribe(async (params) => {
      const param = params['viaje'];

      this._sugerenciaService.getSugerencias( param ).subscribe((res: any) => {
        if (res.status == 'success') {
          this.sugerencias = res.data;
        }
      });
    });
  }

  send() {
    this.fieldsError = '';
    if( this.nameTxt != '' && this.mailTxt != '' && this.phoneTxt != '' && this.msgTxt != '' && this.globalConstants.validateEmail( this.mailTxt ) && this.myRecaptcha ) {
      this.loadingContact = true;
      this._contactService.sendContact360(this.nameTxt, this.phoneTxt, this.mailTxt, this.msgTxt).subscribe( (res:any) => {
        this.loadingContact = false;
        if (res.status == 'success') {
          this.successMsg = true;
        } else {
          this.errorMsg = true;
        }
      });
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }
}
