import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paquete-exp',
  templateUrl: './paquete-exp.component.html',
  styleUrls: ['./paquete-exp.component.scss']
})
export class PaqueteExpComponent implements OnInit {
  @Input() itinerariosDB;
  @Output() itinerarioSelected = new EventEmitter();
  @Output() itinerarioDBoutput = new EventEmitter();
  @Output() actionStart = new EventEmitter();
  
  public itinerario;

  public currentAloj;

  constructor() { }

  ngOnInit(): void {
    this.itinerariosDB.forEach(element => {
      if( element.itinerarioPrincipal == 1 ) {
        this.itinerario = element;
      }
    });
  }

  changeSelectItinerarios(event){
    const val = event;
    let aux = 0;

    this.itinerariosDB.forEach(element => {
      element.selected = false;
    
      if ( val == aux) {
        this.itinerario = element.itinerario;
        
        element.selected = true;
        this.itinerarioSelected.emit( element );
      }
      
      aux++;
    });
    this.itinerarioDBoutput.emit( this.itinerariosDB );
    this.actionStart.emit(true);
    setTimeout(()=>{
      this.actionStart.emit(false);
    }, 750)
  }

  getCantDias( pais ) {
    let sum = 0;

    pais.ciudades.forEach(ciu => {
      sum += ciu.itinerariociudadCantdias;  
    });

    if (sum == 1) {
      return sum + ' día';
    } else {
      return sum + ' días';
    }

  }
}
