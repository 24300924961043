<div [class.collapsed]="isCollapsed">
    <div class="container-imgs">
        <div class="ciudad-item col-3 col-mobile-2" [ngClass]="{'first': (i+1)%3==1, 'last': (i+1)%3==0, 'first-mobile': (i+1)%2==1, 'last-mobile': (i+1)%2==0}" *ngFor="let ciu of ciudades; let i = index;">
            <app-picture [image]="ciu.image"></app-picture>
            <!-- <img appSlug [src]="getSquareSmallImg(ciu.ciudad.ciudadName.trim())" alt=""> -->
            <div class="city">
                {{ ciu.ciudad.ciudadName }}
            </div>
        </div>
    </div>
</div>
<div (click)="isCollapsed = !isCollapsed" class="see-more" *ngIf="isCollapsed">
    <img src="/assets/imgs/icons/photo-library.svg" alt=""> Ver más
</div>
<div (click)="isCollapsed = !isCollapsed" class="see-more" *ngIf="!isCollapsed">
    <img src="/assets/imgs/icons/photo-library.svg" alt=""> Ver menos
</div>