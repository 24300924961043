import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-footer',
  templateUrl: './info-footer.component.html',
  styleUrls: ['./info-footer.component.scss']
})
export class InfoFooterComponent {
  @Input() itinerariosDB;
  @Input() itinerario;
  @Input() icon;
  @Output() itinerarioSelected = new EventEmitter();
  @Output() itinerarioDBoutput = new EventEmitter();
  @Input() showContact = false;
  @Output() contactSate = new EventEmitter();
  @Input() changeItinerarioAnimation = false;
  
  constructor() { }

  contact() {
    this.showContact = true;
    this.contactSate.emit( 'mobile' );
  }

  changeSelectItinerarios(event){
    const val = event.target.value;
    let aux = 0;
    
    this.itinerariosDB.forEach(element => {
      element.selected = false;
    
      if ( val == aux) {
        this.itinerario = element.itinerario;
        
        element.selected = true;
        this.itinerarioSelected.emit( element );
      }
      
      aux++;
    });
    this.itinerarioDBoutput.emit( this.itinerariosDB );
  }

}
