import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-full-banner-rifas',
  templateUrl: './top-full-banner-rifas.component.html',
  styleUrls: ['./top-full-banner-rifas.component.scss']
})
export class TopFullBannerRifasComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() generalClass = '';
  @Input() image = '';


  constructor() { }

}
