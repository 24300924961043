import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ImageInfo } from 'src/app/interfaces/image-info.interface';
import { ContactService } from 'src/app/services/contact.service';
import { GlobalConstants } from '../../common/global-constants';
import { SugerenciaService } from '../../services/sugerencia.service';

export interface SorteoElement {
  nombre: string;
  numero: number;
  dia: string;
  mes: string;
  fecha: string;
  premios: any[];
}

const PREMIOS_DATA_1: any[] = [
  { posicion: 1, premio: '1 Renault Kwid 0KM', ganador: '3440' },
  { posicion: 2, premio: '1 viaje grupales a Egipto para 2 personas', ganador: '7644' },
  { posicion: 3, premio: '1 viaje a un all inclusive en Punta Cana para 2 personas', ganador: '12778' },
  { posicion: 4, premio: '1 viaje en crucero por Sudamérica para 2 personas', ganador: '12840' },
  { posicion: 5, premio: '1 viaje a Río de Janeiro para 2 personas', ganador: '7282' },
  { posicion: 6, premio: '1 viaje grupal a Perú para 2 personas', ganador: '7515' },
  { posicion: 7, premio: '1 Macbook Air', ganador: '8332' },
  { posicion: 8, premio: '1 MacBook Air', ganador: '10537' },
  { posicion: 9, premio: '1 MacBook Air', ganador: '1136' },
  { posicion: 10, premio: '1 MacBook Air', ganador: '6909' },
  { posicion: 11, premio: '1 combo de audio y video de última generación', ganador: '13777' },
  { posicion: 12, premio: '1 Ipad Apple 128G', ganador: '11545' },
  { posicion: 13, premio: '1 Bicicleta eléctrica Loop Slim', ganador: '8277' },
  { posicion: 'Extra', premio: '1 renault Kwid 0 Km', ganador: '2537' },

];

const PREMIOS_DATA_2: any[] = [
  { posicion: 1, premio: '1 Renault Kwid 0KM', ganador: '13058' },
  { posicion: 2, premio: '1 viaje grupales a Egipto para 2 personas', ganador: '6273' },
  { posicion: 3, premio: '1 viaje a un all inclusive en Punta Cana para 2 personas', ganador: '13576' },
  { posicion: 4, premio: '1 viaje en crucero por Sudamérica para 2 personas', ganador: '3429' },
  { posicion: 5, premio: '1 viaje a Río de Janeiro para 2 personas', ganador: '6649' },
  { posicion: 6, premio: '1 viaje grupal a Perú para 2 personas', ganador: '4093' },
  { posicion: 7, premio: '1 Macbook Air', ganador: '11469' },
  { posicion: 8, premio: '1 MacBook Air', ganador: '7950' },
  { posicion: 9, premio: '1 MacBook Air', ganador: '1119' },
  { posicion: 10, premio: '1 MacBook Air', ganador: '7875' },
  { posicion: 11, premio: '1 combo de audio y video de última generación', ganador: '4524' },
  { posicion: 12, premio: '1 Ipad Apple 128G', ganador: '15176' },
  { posicion: 13, premio: '1 Bicicleta eléctrica Loop Slim', ganador: '9721' },
];

const PREMIOS_DATA_3: any[] = [
  { posicion: 1, premio: '1 Renault Kwid 0KM', ganador: '6874' },
  { posicion: 2, premio: '1 viaje grupales a Egipto para 2 personas', ganador: '6024' },
  { posicion: 3, premio: '1 viaje a un all inclusive en Punta Cana para 2 personas', ganador: '2426' },
  { posicion: 4, premio: '1 viaje en crucero por Sudamérica para 2 personas', ganador: '13078' },
  { posicion: 5, premio: '1 viaje a Río de Janeiro para 2 personas', ganador: '12748' },
  { posicion: 6, premio: '1 viaje grupal a Perú para 2 personas', ganador: '1683' },
  { posicion: 7, premio: '1 Macbook Air', ganador: '14022' },
  { posicion: 8, premio: '1 MacBook Air', ganador: '4363' },
  { posicion: 9, premio: '1 MacBook Air', ganador: '7178' },
  { posicion: 10, premio: '1 MacBook Air', ganador: '8241' },
  { posicion: 11, premio: '1 combo de audio y video de última generación', ganador: '1622' },
  { posicion: 12, premio: '1 Ipad Apple 128G', ganador: '2355' },
  { posicion: 13, premio: '1 Bicicleta eléctrica Loop Slim', ganador: '1933' },
];

const PREMIOS_DATA_4: any[] = [
  { posicion: 1, premio: '1 Renault Kwid 0KM', ganador: '2827' },
  { posicion: 2, premio: '1 viaje grupales a Egipto para 2 personas', ganador: '13337' },
  { posicion: 3, premio: '1 viaje a un all inclusive en Punta Cana para 2 personas', ganador: '11688' },
  { posicion: 4, premio: '1 viaje en crucero por Sudamérica para 2 personas', ganador: '2894' },
  { posicion: 5, premio: '1 viaje a Río de Janeiro para 2 personas', ganador: '1040' },
  { posicion: 6, premio: '1 viaje grupal a Perú para 2 personas', ganador: '4060' },
  { posicion: 7, premio: '1 Macbook Air', ganador: '4119' },
  { posicion: 8, premio: '1 MacBook Air', ganador: '5161' },
  { posicion: 9, premio: '1 MacBook Air', ganador: '8042' },
  { posicion: 10, premio: '1 MacBook Air', ganador: '5960' },
  { posicion: 11, premio: '1 combo de audio y video de última generación', ganador: '13285' },
  { posicion: 12, premio: '1 Ipad Apple 128G', ganador: '14387' },
  { posicion: 13, premio: '1 Bicicleta eléctrica Loop Slim', ganador: '15967' },
];

const PREMIOS_DATA_5: any[] = [
  { posicion: 1, premio: '1 viaje a Maldivas para 2 personas', ganador: '4274' },
  { posicion: 2, premio: '1 Renault Kwid 0KM', ganador: '11953' },
  { posicion: 3, premio: '1 viaje grupales a Egipto para 2 personas', ganador: '4626' },
  { posicion: 4, premio: '1 viaje a un all inclusive en Punta Cana para 2 personas', ganador: '11533' },
  { posicion: 5, premio: '1 viaje en crucero por Sudamérica para 2 personas', ganador: '5323' },
  { posicion: 6, premio: '1 viaje a Río de Janeiro para 2 personas', ganador: '12173' },
  { posicion: 7, premio: '1 viaje grupal a Perú para 2 personas', ganador: '5043' },
  { posicion: 8, premio: '1 Macbook Air', ganador: '5237' },
  { posicion: 9, premio: '1 MacBook Air', ganador: '2256' },
  { posicion: 10, premio: '1 MacBook Air', ganador: '13635' },
  { posicion: 11, premio: '1 MacBook Air', ganador: '15522' },
  { posicion: 12, premio: '1 combo de audio y video de última generación', ganador: '11767' },
  { posicion: 13, premio: '1 Ipad Apple 128G', ganador: '1137' },
  { posicion: 14, premio: '1 Bicicleta eléctrica Loop Slim', ganador: '11928' },
];

const SORTEOS_DATA: SorteoElement[] = [
  { numero: 1, nombre: 'Sorteo 1', dia: '30', mes: 'Agosto', fecha: '30 de Agosto', premios: PREMIOS_DATA_1 },
  { numero: 2, nombre: 'Sorteo 2', dia: '27', mes: 'Setiembre', fecha: '27 de Setiembre', premios: PREMIOS_DATA_2 },
  { numero: 3, nombre: 'Sorteo 3', dia: '25', mes: 'Octubre', fecha: '25 de Octubre', premios: PREMIOS_DATA_3 },
  { numero: 4, nombre: 'Sorteo 4', dia: '29', mes: 'Noviembre', fecha: '29 de Noviembre', premios: PREMIOS_DATA_4 },
  { numero: 5, nombre: 'Sorteo 5', dia: '27', mes: 'Diciembre', fecha: '27 de Diciembre', premios: PREMIOS_DATA_5 },
];

@Component({
  selector: 'app-rifas',
  templateUrl: './rifas.component.html',
  styleUrls: ['./rifas.component.scss']
})
export class RifasComponent implements OnDestroy, OnInit {

  public sugerencias = [];
  public hasSugerenciaGrupo = false;
  public hasSugerenciaPaquete = false;
  public showModal = false;
  public modalType = '';
  public tutoqueCountrySelected = '';

  public loadingContact = false;
  public nameTxt;
  public phoneTxt;
  public mailTxt;
  public msgTxt;
  public successMsg = false;
  public errorMsg = false;
  public fieldsError = '';
  public myRecaptcha = false;

  public pageImgs =
    {
      'cabezal': new ImageInfo('/assets/imgs/rifas-universitarias-detoqueytoque2', ['_xs', '_sm', '_md', '_lg', '_xl'], '.png', '', '_xl'),
      //'cabezal': new ImageInfo('/assets/imgs/rifas-universitarias-detoqueytoque', ['_xs', '_sm', '_md', '_lg', '_xl'], '.png', 'Vista de ángulo bajo del edificio Capitol en gran vía Madrid España', '_xl'),
      'afiche': new ImageInfo('/assets/imgs/premios-rifa-detoqueytoque', ['_xs', '_sm', '_md'], '.png', 'Diferentes atracciones turísticas y premios de rifas', '_md'),
      'tuToqueFooter': new ImageInfo('/assets/imgs/viajes-a-medida', ['_xs', '_sm', '_md', '_lg'], '.png', '', '_lg'),
    };

  sorteos = SORTEOS_DATA;

  constructor(
    private globalConstants: GlobalConstants,
    private sugerenciasService: SugerenciaService,
    private router: Router,
    private contactService: ContactService,
    private title: Title,
    private meta: Meta
  ) {
    this.globalConstants.setPageWithWhatsappBtn(true);
    this.getSugerencias();
  }

  ngOnInit() {
    this.title.setTitle(this.globalConstants.getTitlePrefix() + 'Rifas Universitarias');
    this.meta.updateTag({ name: 'description', content: 'Viajá cuando y cómo querés! Vos ponés la fecha, vos ponés los destinos, vos elegís tu presupuesto. Nosotros nos encargamos de organizar tu viaje con nuestro toque.' });
    this.meta.updateTag({ name: 'keywords', content: 'viajes a medida, viajes de aventura, destinos exóticos, organización de viajes, como organizar mi viaje, destinos baratos' });
  }

  ngOnDestroy(): void {
    this.globalConstants.setPageWithWhatsappBtn(false);

  }

  showContactModal(type, country = '') {
    this.showModal = true;

    this.modalType = type;

    this.tutoqueCountrySelected = country;

  }

  getSugerencias() {
    // this.sugerenciasService.getSugerencias( 'mojones-de-medio-oriente' ).subscribe((res: any) => {
    this.sugerenciasService.getSugerencias('lazos-meridionales').subscribe((res: any) => {
      if (res.status == 'success') {
        this.sugerencias = res.data;

        this.sugerencias.forEach(sug => {
          if (sug.tipo == 'Grupo') {
            this.hasSugerenciaGrupo = true;
          } else if (sug.tipo == 'Paquete') {
            this.hasSugerenciaPaquete = true;
          }
        });

      }
    });
  }

  closeContactModal() {
    this.showModal = false;
    this.loadingContact = false;
    this.successMsg = false;
    this.errorMsg = false;
    this.nameTxt = '';
    this.mailTxt = '';
    this.phoneTxt = '';
    this.msgTxt = '';
    this.fieldsError = '';
  }

  sendModal() {
    this.fieldsError = '';

    if (this.nameTxt != '' && this.mailTxt != '' && this.phoneTxt != '' && this.msgTxt != '' && this.globalConstants.validateEmail(this.mailTxt) && this.myRecaptcha) {
      this.loadingContact = true;

      if (this.modalType == 'tutoque') {
        this.contactService.sendContactTutoque(this.nameTxt, this.phoneTxt, this.mailTxt, this.msgTxt).subscribe((res: any) => {
          this.loadingContact = false;
          if (res.status == 'success') {
            this.successMsg = true;
          } else {
            this.errorMsg = true;
          }
        });
      }
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }

}
