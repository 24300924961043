<!-- <div class="tramo-item" [class.accordion-on]="paq.isActive"> -->
<div class="itinerarios">
    <div class="tabs-itinerarios">
        <div class="small-text" *ngIf="itinerariosDB.length > 1">
            Elegí que parte del viaje querés hacer:
        </div>
        <select *ngIf="itinerariosDB.length > 1" class="select-itinerarios" (change)="changeSelectItinerarios($event.target.value)">
            <option [value]="i" [selected]="iti.selected" *ngFor="let iti of itinerariosDB; let i = index"><span *ngIf="iti.itinerario.itinerarioPrincipal == 0">{{ iti.itinerario.itinerarioName }}</span><span *ngIf="iti.itinerario.itinerarioPrincipal == 1">{{iti.itinerario.itinerarioName}}</span></option>
        </select>
        <!-- <div class="tab-itinerario" (click)="changeSelectItinerarios( i )" *ngFor="let iti of itinerariosDB; let i = index" [class.active]="iti.selected" [class.tab-1]="itinerariosDB.length == 1" [class.tab-2]="itinerariosDB.length == 2" [class.tab-3]="itinerariosDB.length == 3"
            [class.tab-4]="itinerariosDB.length == 4" [class.tab-5]="itinerariosDB.length == 5" [class.last-tab]="itinerariosDB.length == (i+1)">
            <span *ngIf="iti.itinerario.itinerarioPrincipal == 1">
                Todo el viaje
            </span>
            <span *ngIf="iti.itinerario.itinerarioPrincipal == 0">
                {{ iti.itinerario.itinerarioName }}
            </span>
        </div> -->
        <div class="clearfix"></div>
    </div>
    <div class="itinerarios-tramos" *ngFor="let item of itinerariosDB">
        <div class="tramo-item" *ngIf="item.selected">
            <div class="top-container">
                <div class="cities-container" [ngClass]="{'city-1': item.paises.length == 1, 'city-2': item.paises.length == 2, 'city-3': item.paises.length == 3, 'city-4': item.paises.length == 4, 'city-5': item.paises.length == 5}">
                    <div class="city-item" *ngFor="let pais of item.paises; let i = index" [class.last-item]="item.paises.length == (i+1)">
                        <div class="city-name">
                            {{pais.pais.paisName}}
                        </div>
                        <div class="city-days">
                            {{ getCantDias(pais) }}
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="itinerarios-footer" *ngIf="item.selected && item.itinerarioPrincipal?.itinerarioSalida != null">
            {{ item.itinerarioPrincipal.itinerarioSalida }}
        </div>
    </div>
</div>