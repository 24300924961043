<div id="interna-viaje">
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!loading">
        <app-header type="search-top" [image]="pageImgs.cabezal"></app-header>

        <app-top-full-banner-slide [showInfo]="viaje.viajeShowinfoweb" [image]="getSanitizedUrl(viajeName)" [imageMobile]="imageMobile" [title]="viajeName" [description]="paisNames" [precio]="precio" smallDescription="{{ itinerarioPrincipal.itinerario.itinerarioCantdias }} días - {{ itinerarioPrincipal.itinerario.itinerarioSalida }}">

        </app-top-full-banner-slide>

        <div id="test" class="section-container column-section">
            <span class="greenSpan">
            <mat-toolbar id="header2" class="greenSpan mat-toolbar " [title]="viajeName" [precio]="precio">

                <mat-toolbar-row  >

                  <mat-tab-group class="mat-toolbar-row2 ">
                    <span class="mat-toolbar-row1">{{ viajeName }} </span>
            <mat-button (click)="sobreViaje()"> Sobre el Viaje </mat-button>
            <mat-button (click)="sobreItinerario()"> Itinerario </mat-button>
            <mat-button (click)="sobreCombinalos()"> Combinalos </mat-button>
            </mat-tab-group>

            <mat-tab-group class="mat-toolbar-row3 ">
                <mat-tab> <b> USD {{ precio }} </b> | {{ itinerarioPrincipal.itinerario.itinerarioCantdias }} días</mat-tab>
                <span class="sumate-al-viaje btn-sumate " (click)="contactState()">SUMATE</span>
            </mat-tab-group>

            </mat-toolbar-row>

            </mat-toolbar>
            </span>

            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div class="left-column">
                    <div id="sobreViaje" class="section-title">
                        {{ itinerarioPrincipal.itinerario.viaje.viajeSubtitulo }}
                    </div>
                    <div class="section-description">
                        <app-read-more [description]="itinerarioPrincipal.itinerario.viaje.viajeDesc" [wordLimit]="900">
                        </app-read-more>
                    </div>
                    <div class="row small-screens" *ngIf="itinerariosDB.length > 1">
                        <div class="col-1">
                            <div class="section-title">
                                Qué parte del viaje querés hacer?
                            </div>
                            <div class="section-description">
                                Encontrate con todos los destinos y distintos presupuestos para que las opciones se adapten al viaje que tenes soñado.
                            </div>
                            <app-paquete-exp (itinerarioSelected)="itinerarioSelected = $event" [itinerariosDB]="itinerariosDB" (actionStart)="changeItinerarioAnimation = $event;">
                            </app-paquete-exp>
                        </div>
                    </div>
                    <div class="row" *ngIf="viajeWebType != 'ciudades' && viajeWebType != 'combinado'">
                        <div class="col-1">
                            <div class="section-title">
                                Fotos
                            </div>
                        </div>
                    </div>

                </div>
                <div class="right-column">
                    <br>
                    <app-list-viaje [listPaises]="paises" [viaje]="viaje" [viajeName]="viajeName" [viajeWebType]="viajeWebType">
                    </app-list-viaje>

                </div>
                <!-- <div class="clearfix small-screens"></div> -->
                <p class="clearfix"></p>
            </div>
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div class="left-column">
                    <div class="row" *ngIf="incluyes.length > 0">
                        <div class="col-1">
                            <div class="section-title-incluye">
                                Incluye
                            </div>
                            <br>
                            <div class="section-list">
                                <div class="list-item" *ngFor="let inc of incluyes">
                                    <div *ngIf="inc.item.incluyeMulti == 0">
                                        <div class="list-item-text">
                                            <div class="list-item-title">
                                                {{ inc.item.incluyeNombre }}
                                            </div>
                                            <div class="list-item-description">
                                                {{ inc.item.incluyeDescripcion }}
                                            </div>
                                        </div>
                                        <div class="list-item-icon">
                                            <img src="/assets/imgs/icons/{{inc.item.incluyeIcono}}.svg" alt="">
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div *ngIf="inc.item.incluyeMulti == 1">
                                        <div class="accordion-subincluye">
                                            <app-accordion [data]="inc"></app-accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right-column">
                    <div class="row">
                        <div class="col-1">
                            <div class="google-map-container" *ngIf="viajeName != 'Tanzania + Egipto + Turquía'">
                                <!-- RUTA: {{getSanitizedUrl(viajeName, 'grupos', 'mapa_des.png')}} Prueba-->
                                <img [src]="getSanitizedUrl(viajeName, 'grupos', 'mapa_des.png')" alt="">
                                <!-- <img class="mobile" [src]="getSanitizedUrl(viajeName, 'grupos', 'mapa_mob.png')" alt="">-->
                            </div>
                            <ng-container *ngIf="viajeName == 'Tanzania + Egipto + Turquía'">
                                <div class="google-map-container">
                                    <img class="desktop" [src]="getSanitizedUrl(slug, 'grupos', 'mapa_des_tanzania.png')" alt="">
                                    <img class="mobile" [src]="getSanitizedUrl(slug, 'grupos', 'mapa_mob_tanzania.png')" alt="">
                                </div>
                                <div class="google-map-container">
                                    <img class="desktop" [src]="getSanitizedUrl(slug, 'grupos', 'mapa_des_egipto.png')" alt="">
                                    <img class="mobile" [src]="getSanitizedUrl(slug, 'grupos', 'mapa_mob_egipto.png')" alt="">
                                </div>
                                <div class="google-map-container">
                                    <img class="desktop" [src]="getSanitizedUrl(slug, 'grupos', 'mapa_des_turquia.png')" alt="">
                                    <img class="mobile" [src]="getSanitizedUrl(slug, 'grupos', 'mapa_mob_turquia.png')" alt="">
                                </div>
                            </ng-container>
                            <!-- <app-google-map [imageDesktop]="pageImgs.mapaDesktop" [imageMobile]="pageImgs.mapaMobile"></app-google-map> -->
                        </div>
                    </div>
                </div>
                <p class="clearfix"></p>
            </div>
            <div class="row" *ngIf="showVideo">
                <div class="video-player-container">
                    <video controls="controls" [poster]="videoScreenshot" tabindex="0" class="video-player-container">
                        <source [src]="getSanitizedUrlVideo(viajeName)" />
                    </video>
                </div>
                <p class="clearfix"></p>
            </div>

            <div class="section-container standard-section">
                <div class="section-wrapper container section-wrapper-medium-desktop">
                    <div class="row">
                        <div class="col-1">
                            <div class="section-title">
                                Itinerario
                            </div>
                            <div class="section-description" id="sobreItinerario">
                                Un poco sobre las ciudades que vamos a recorrer Luego, en las reuniones informativas entraremos más en los detalles de qué haremos, cómo, alojamientos, etc.

                            </div>

                            <div class="container-ciudad" *ngFor="let c of ciudades; let i = index">
                                <div class="imgen-ciudad" [ngClass]="{'float-right': i%2!=0, 'float-left': i%2==0 || i==0}">
                                    <!--<img src="/assets/imgs/grupos/tailandia_camboya_singapur/banner.png" alt="">-->
                                    <img [src]="getSanitizedUrlImgCiudad(c.ciudad.ciudadName, viajeName)">
                                    <!-- <img class="desktop" [src]="getSanitizedUrl(slug, 'grupos', 'mapa_des_tanzania.png')" alt="">-->
                                </div>
                                <div class="descripcion-ciudad" [ngClass]="{'float-left': i%2!=0, 'float-right': i%2==0 || i==0 }">
                                    <div class="titulo-itinerario">
                                        {{ c.ciudad.ciudadName }} <span> / {{ c.itinerariociudadCantdias}} dias </span>
                                    </div>
                                    <br>
                                    <!--<div class="parrafo">
                                        <div [innerHTML]="c.ciudad.ciudadDescription | html"></div>
                                    </div>-->
                                    <app-read-more [description]="c.ciudad.ciudadDescription" [wordLimit]="wordLimit">
                                    </app-read-more>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <p class="clearfix"></p>
        </div>
        <!-- FIN DIV TEST -->
        <div class="section-container standard-section dark">
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div class="row" id="sugerencias" *ngIf="sugerencias.length > 0">
                    <div *ngIf="hasSugerenciaGrupo">
                        <!--  <app-swiper-wide tipo="Grupo" title="Tenemos otras sugerencias para viajar en grupo:" [listadoItems]="sugerencias"></app-swiper-wide>-->
                        <div class="col-1">
                            <div class="section-title">
                                ¿Querés seguir de viaje?
                            </div>
                            <div id="sobreCombinalos" class="section-description">
                                Aprovechá el pasaje y seguí de viaje. Son experiencias y oportunidades en un momento de la vida. Al seguir de viaje sin volver a Uruguay, uno se ahorra aprox unos 2000 usd y realiza exactamente el mismo viaje. Si contás con los días para seguir de viaje,
                                ¡es una excelente oportunidad para ahorrar tiempos y dinero!

                            </div>

                            <div class="seguir-de-viaje-item" *ngFor="let sug of sugerencias">
                                <a [href]="'/grupos/' + sug.item.viajemadre.viajemadreSlug">
                                    <img [src]="getSanitizedUrlSugerenciaPng( getSlug(sug.item.viajemadre.viajemadreName) )" alt="">
                                    <div class="sug-info-container">
                                        <div class="sugerencia-title">
                                            {{ sug.item.viajemadre.viajemadreName}}
                                        </div>
                                        <!-- <div class="sugerencia-price">
                                             USD {{ sug.precio }}
                                        </div> -->
                                        <div class="sugerencia-dias">
                                            {{ sug.cantDias }} días
                                            <!--{{ sug.item.viajeStartdate.timestamp }}-->
                                        </div>
                                        <div class="sugerencia-btn">
                                            Ver info
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="hasSugerenciaPaquete">
                        <app-swiper-wide tipo="Paquete" title="Te gustó {{ viajeName }}?" description="Genial. Antes y/o después del viaje, agregá mas destinos y viajá con nuestro toque!" [listadoItems]="sugerencias" (clickAction)="showContactModal('tutoque', $event)">
                        </app-swiper-wide>
                    </div>
                </div>
                <div class="row" *ngIf="hasSugerenciaCombinado">
                    <div class="col-1">
                        <div class="section-title">
                            ¿Querés seguir de viaje?
                        </div>
                        <div id="sobreCombinalos" class="section-description">
                            Aprovechá el pasaje y seguí de viaje.<br> Son experiencias y oportunidades en un momento de la vida.
                        </div>

                        <div class="seguir-de-viaje-item" *ngFor="let sug of sugerencias">
                            <a [href]="'/grupos/' + sug.item.viajemadre.viajemadreSlug">
                                <img [src]="getSanitizedUrlPng( getSlug(sug.item.viajemadre.viajemadreName) )" alt="">
                                <div class="sug-info-container">
                                    <div class="sugerencia-title">
                                        {{ sug.item.viajemadre.viajemadreName}}
                                    </div>
                                    <div class="sugerencia-price">
                                        USD {{ sug.precio }}
                                    </div>
                                    <div class="sugerencia-dias">
                                        {{ sug.cantDias }} días - {{ sug.item.viajeStartdate.timestamp }}
                                    </div>
                                    <div class="sugerencia-btn">
                                        Ver info
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>

        <div class="section-container standard-section">
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div class="row">
                    <div class="col-1">
                        <div class="section-title">
                            Alianzas y Convenios
                        </div>
                        <div class="section-description">
                            A continuación les presentamos los convenios que tenemos con diferentes proveedores para agregarle valor al viaje. Son proveedores que sí o sí van a ser utilizados por distintas personas dependiendo de sus gustos y necesidades y con los cuales hemos generado
                            una relación de trabajo muy práctica y efectiva.
                        </div>
                        <div class="countries-swiper-container">
                            <app-swiper-small title="" [listadoItems]="beneficiosItems" theme="light">
                            </app-swiper-small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>

        <div class="section-container standard-section dark">
            <div class="section-wrapper container section-wrapper-medium-desktop">
                <div class="left-column">
                    <div class="row">
                        <div id="contact" class="col-1">
                            <div id="contactForm" class="contact-containerr">
                                <div class="contact-boxx">
                                    <app-loader *ngIf="loadingContact" type="small"></app-loader>
                                    <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
                                        <div class="contact-title section-title">Participá de las reuniones o preguntanos detalles por acá:</div>
                                        <div class="contact-input">
                                            <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
                                        </div>
                                        <div class="contact-input">
                                            <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
                                        </div>
                                        <div class="contact-input">
                                            <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
                                        </div>
                                        <div class="contact-input">
                                            <textarea name="msgTxt" placeholder="Mensaje..." cols="" rows="" [(ngModel)]="msgTxt"></textarea>
                                        </div>
                                        <div class="fields-error" *ngIf="fieldsError != ''">
                                            {{ fieldsError }}
                                        </div>
                                        <div class="recaptcha">
                                            <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
                                        </div>
                                        <div class="contact-btn btn-green" (click)="send()">
                                            ENVIAR
                                        </div>
                                    </div>
                                    <div class="contact-content contact-message" *ngIf="!loadingContact && successMsg">
                                        <div class="contact-title section-title">
                                            <img src="/assets/imgs/icons/success.svg" alt=""> Gracias por comunicarte, en breve nos ponemos en contacto!<br> Te recordamos que estaremos cerrados hasta el lunes 9 de enero inclusive, por lo que la respuesta
                                            será luego de está fecha.
                                        </div>
                                    </div>
                                    <div class="contact-content contact-message" *ngIf="!loadingContact && errorMsg">
                                        <div class="contact-title section-title">
                                            <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898"
                                                target="_blank">WhatsApp al
                                                (+598)&nbsp;92&nbsp;443&nbsp;898</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-column">
                    <div class="contact-img ">
                        <!--<app-picture [image]="pageImgs.homeBottom4"></app-picture>-->
                        <img src="/assets/imgs/img-contact.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="contact-modal" *ngIf="showModal">
            <div id="contactForm" class="contact-containerr" #contactForm>
                <div class="close-btn" (click)="closeContactModal()">
                    <img src="/assets/imgs/close.svg" alt="">
                </div>
                <app-loader *ngIf="loadingContact" type="small"></app-loader>
                <div class="contact-content" *ngIf="!loadingContact && !successMsg && !errorMsg">
                    <div class="contact-title section-title">Escribinos, nosotros te organizamos tu viaje a {{ tutoqueCountrySelected }}.</div>
                    <div class="contact-input">
                        <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
                    </div>
                    <div class="contact-input">
                        <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
                    </div>
                    <div class="contact-input">
                        <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
                    </div>
                    <div class="contact-input">
                        <textarea name="msgTxt" placeholder="¿Qué lugar del mundo te gustaría conocer?" cols="" rows="" [(ngModel)]="msgTxt"></textarea>
                    </div>
                    <div class="fields-error" *ngIf="fieldsError != ''">
                        {{ fieldsError }}
                    </div>
                    <div class="recaptcha">
                        <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
                    </div>
                    <div class="contact-btn btn-blue" [class.btn-blue]="modalType == 'tutoque'" [class.btn-yellow]="modalType == '360'" (click)="sendModal()">
                        ENVIAR CONSULTA
                    </div>
                </div>
                <div class="contact-content contact-message" *ngIf="!loadingContact && (successMsg || errorMsg)">
                    <div class="contact-title section-title" *ngIf="successMsg">
                        <img src="/assets/imgs/icons/success.svg" alt="">
                        Gracias por comunicarte, queríamos avisarte que vamos a estar cerrados del 1 de enero al 9 inclusive.
                        Nos pondremos en contacto contigo a partir del martes 10. ¡¡¡Que tengas un muy feliz comienzo de año!!!
                    </div>
                    <div class="contact-title section-title" *ngIf="errorMsg">
                        <img src="/assets/imgs/icons/error.svg" alt=""> Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por
                        <a href="https://wa.me/59892443898" target="_blank">WhatsApp al
                            (+598)&nbsp;92&nbsp;443&nbsp;898</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- FIN *ngIf="!loading" -->
</div>

<app-contacto title="Participá de las reuniones o preguntanos detalles por acá:" description="" [showContact]="showContact" (contactState)="showContact = $event" [viaje]="viajeName"></app-contacto>
<app-info-footer *ngIf="showInfoFooter && !loading" [changeItinerarioAnimation]="changeItinerarioAnimation" [itinerario]="itinerarioSelected.itinerario" [itinerariosDB]="itinerariosDB" (itinerarioSelected)="itinerarioSelected = $event" (itinerarioDBoutput)="itinerariosDB = $event"
    [icon]="icon" [showContact]="showContact" (contactSate)="contactState($event)"></app-info-footer>
<app-footer *ngIf="!loading"></app-footer>
<!-- <app-test></app-test> -->
<!-- <app-search-bar></app-search-bar> -->
