import { Component, ElementRef, HostListener, OnInit, ViewChild, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { GruposService } from 'src/app/services/grupos.service';
import { Router } from '@angular/router';
import { ContactService } from '../../services/contact.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { isPlatformBrowser } from '@angular/common';
import { ImageInfo } from 'src/app/interfaces/image-info.interface';

@Component({
  selector: 'app-tutoque',
  templateUrl: './tutoque.component.html',
  styleUrls: ['./tutoque.component.scss']
})
export class TutoqueComponent implements OnInit, OnDestroy {

  @ViewChild('contactForm') contactFormEl : ElementRef; 

  public viajes = [];
  public showInfoFooter = false;
  public viajeDestacado;
  public loading = true;
  public loadingContact = false;
  public fieldsError = '';
  public successMsg = false;
  public errorMsg = false;
  public showContact = false;
  public myRecaptcha = false;
  public nameTxt;
  public phoneTxt;
  public mailTxt;
  public msgTxt;

  public pageImgs = {
    'cabezal': new ImageInfo('/assets/imgs/tu-toque/viajes-a-medida', ['_xs', '_sm', '_xl', '_md', '_lg'], '.png', 'Mochilero viajando por la montaña', '_sm'),
    'paso1': new ImageInfo('/assets/imgs/tu-toque/Comienza-organizar-tu-viaje', ['_xs', '_sm'], '.png', 'Artículos que son necesarios para organizar un viaje. Mapa, lápiz, cámara de fotos', '_xs'),
    'paso2': new ImageInfo('/assets/imgs/tu-toque/Elije-tu-destino', ['_xs', '_sm'], '.png', 'Persona eligiendo el destino al que quiere viajar', '_sm'),
    'paso3': new ImageInfo('/assets/imgs/tu-toque/Realiza-tu-viaje-planeado', ['_xs', '_sm'], '.png', 'Mochilero viajando por la montaña', '_sm'),
  };
  
  // public categorias = [
  //   {
  //     categoria: 'Playa',
  //     items: ['Filipinas', 'Maldivas', 'Indonesia', 'Tailandia', 'Malasia', 'Tanzania', 'Sri Lanka', 'Egipto', 'Vietnam', 'Nueva Zelanda', 'Dubai']
  //   },
  //   {
  //     categoria: 'Montaña / Paisajes',
  //     items: ['Nueva Zelanda',
  //           'Nepal',
  //           'Mongolia',
  //           'Sri Lanka',
  //           'Indonesia',
  //           'Australia',
  //           'Vietnam',
  //           'Marruecos',
  //           'Dubai',
  //           'Filipinas',
  //           'Maldivas',
  //           'Japón']
  //   },
  //   {
  //     categoria: 'Cultura',
  //     items: ['Egipto',
  //           'Vietnam',
  //           'Japón',
  //           'China',
  //           'Nepal',
  //           'India',
  //           'Tibet',
  //           'Marruecos',
  //           'Turquía',
  //           'Israel',
  //           'Tanzania'
  //         ]
  //   },
  //   {
  //     categoria: 'Ciudades',
  //     items: ['Singapur',
  //           'Japón',
  //           'China',
  //           'Hong Kong',
  //           'Tailandia',
  //           'Australia',
  //           'Rusia',
  //           'India',
  //           'Dubai',
  //           'Malasia',
  //           'Egipto']
  //   }
  // ];

  public categorias = [
    {
      categoria: 'Playa',
      items:
        [
            {
                country: 'Filipinas',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-filipinas', ['_lg', '_md', '_sm', '_xs'], '.png', 'Big Lagoon de El Nido, Filipinas', '_sm'),
            },
            {
                country: 'Maldivas',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-maldivas', ['_lg', '_md', '_sm', '_xs'], '.png', 'Barco en aguas de Maldivas', '_sm'),
            },
            {
                country: 'Indonesia',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-indonesia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Entrada a Templo de Indonesia', '_sm'),
            },
            {
                country: 'Tailandia',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-tailandia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Barcaza característica de Tailandia en aguas de Islas Phi Phi', '_sm'),
            },
            {
                country: 'Malasia',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-malasia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Torres Petronas en Kuala Lumpur', '_sm'),
            },  
            {
                country: 'Tanzania',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-tanzania', ['_lg', '_md', '_sm', '_xs'], '.png', 'Masai, tribu característica de Tanzania saltando en un Safari', '_sm'),
            },  
            {
                country: 'Sri Lanka',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-sri-lanka', ['_lg', '_md', '_sm', '_xs'], '.png', 'Barcos a las orillas de las playas de Sri Lanka', '_sm'),
            },  
            {
                country: 'Egipto',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-egipto', ['_lg', '_md', '_sm', '_xs'], '.png', 'Pirámides de Egipto junto a la Esfinge. Viajero admirando las pirámides.', '_sm'),
            },  
            {
                country: 'Vietnam',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-vietnam', ['_lg', '_md', '_sm', '_xs'], '.png', 'Calles de Vietnam junto a sus características bicicletas', '_sm'),
            },  
            {
                country: 'Nueva Zelanda',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-nueva-zelanda', ['_lg', '_md', '_sm', '_xs'], '.png', 'Ruta vista desde Motor Home con paisajes típicos de Nueva Zelanda', '_sm'),
            },    
            {
                country: 'Dubai',
                img: new ImageInfo('/assets/imgs/paises/viaja-a-dubai', ['_lg', '_md', '_sm', '_xs'], '.png', 'Paisaje típico de Dubai, con sus rascacielos y autopistas gigantescas', '_sm'),
            }
        ] // fin de Item

    }, // fin de categoría
    {
      categoria: 'Montaña / Paisajes',
      items:
        [
            {
              country: 'Nueva Zelanda',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-nueva-zelanda', ['_lg', '_md', '_sm', '_xs'], '.png', 'Ruta vista desde Motor Home con paisajes típicos de Nueva Zelanda', '_sm'),
            },
            {
              country: 'Nepal',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-nepal', ['_lg', '_md', '_sm', '_xs'], '.png', 'Montañas del Himalaya vistas desde campamento junto con los banderines nepalies', '_sm'),
            },
            {
              country: 'Mongolia',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-mongolia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Viajero disfruta de la noche junto a un Ger, alojamientos clásicos de los mogoles', '_sm'),
            },
            {
              country: 'Sri Lanka',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-sri-lanka', ['_lg', '_md', '_sm', '_xs'], '.png', 'Barcos a las orillas de las playas de Sri Lanka', '_sm'),  
            },
            {
              country: 'Indonesia',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-indonesia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Entrada a Templo de Indonesia', '_sm'),
            },
            {
              country: 'Australia',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-australia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Playa paradisíaca de Australia donde viajeros realizan surf', '_sm'),
            },
            {
              country: 'Vietnam',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-vietnam', ['_lg', '_md', '_sm', '_xs'], '.png', 'Calles de Vietnam junto a sus características bicicletas', '_sm'),
            },
            {
              country: 'Marruecos',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-marruecos', ['_lg', '_md', '_sm', '_xs'], '.png', 'comercio callejero en Marruecos', '_sm'),
            },
            {
              country: 'Dubai',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-dubai', ['_lg', '_md', '_sm', '_xs'], '.png', 'Paisaje típico de Dubai, con sus rascacielos y autopistas gigantescas', '_sm'),
            },
            {
              country: 'Filipinas',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-filipinas', ['_lg', '_md', '_sm', '_xs'], '.png', 'Big Lagoon de El Nido, Filipinas', '_sm'),
            },
            {
              country: 'Maldivas',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-maldivas', ['_lg', '_md', '_sm', '_xs'], '.png', 'Barco en aguas de Maldivas', '_sm'),
            },
            {
              country: 'Japón',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-japon', ['_lg', '_md', '_sm', '_xs'], '.png', 'Vista desde lo alto de Japón donde se puede ver el Monte Fuji', '_sm'),
            }
        ] // fin de Item
    }, // fin de categoría

    {
      categoria: 'Cultura',
      items:
        [
            {
              country: 'Egipto',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-egipto', ['_lg', '_md', '_sm', '_xs'], '.png', 'Pirámides de Egipto junto a la Esfinge. Viajero admirando las pirámides.', '_sm'),
            },
            {
              country: 'Vietnam',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-vietnam', ['_lg', '_md', '_sm', '_xs'], '.png', 'Calles de Vietnam junto a sus características bicicletas', '_sm'),
            },
            {
              country: 'Japón',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-japon', ['_lg', '_md', '_sm', '_xs'], '.png', 'Vista desde lo alto de Japón donde se puede ver el Monte Fuji', '_sm'),
            },
            {
              country: 'China',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-china', ['_lg', '_md', '_sm', '_xs'], '.png', 'Callejón en una ciudad China por la noche', '_sm'),
            },
            {
              country: 'Nepal',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-nepal', ['_lg', '_md', '_sm', '_xs'], '.png', 'Montañas del Himalaya vistas desde campamento junto con los banderines nepalies', '_sm'),  
            },
            {
              country: 'India',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-india', ['_lg', '_md', '_sm', '_xs'], '.png', 'Viajero contempla el Taj Mahal, una de las 7 maravillas del mundo', '_sm'),
            },
            {
              country: 'Tibet',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-tibet', ['_lg', '_md', '_sm', '_xs'], '.png', 'Monasterio Tibetano observado desde lo alto', '_sm'),
            },
            {
              country: 'Marruecos',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-marruecos', ['_lg', '_md', '_sm', '_xs'], '.png', 'comercio callejero en Marruecos', '_sm'),
            },
            {
              country: 'Turquía',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-turquia', ['_lg', '_md', '_sm', '_xs'], '.png', 'viajera en Turquía donde observa el paseo en Globo Aerostático', '_sm'),
            },
            {
              country: 'Israel',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-israel', ['_lg', '_md', '_sm', '_xs'], '.png', 'Cúpula de la roca en Jerusalem', '_sm'),
            },
            {
              country: 'Tanzania',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-tanzania', ['_lg', '_md', '_sm', '_xs'], '.png', 'Masai, tribu característica de Tanzania saltando en un Safari', '_sm'),  
            }
        ]
    },

    {
      categoria: 'Ciudades',
      items:
        [
            {
              country: 'Singapur',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-singapur', ['_lg', '_md', '_sm', '_xs'], '.png', 'jardines de Singapur frente a la marina Bay', '_sm'),
            },
            {
              country: 'Japón',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-japon', ['_lg', '_md', '_sm', '_xs'], '.png', 'Vista desde lo alto de Japón donde se puede ver el Monte Fuji', '_sm'),
            },
            {
              country: 'China',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-china', ['_lg', '_md', '_sm', '_xs'], '.png', 'Callejón en una ciudad China por la noche', '_sm'),
            },
            {
              country: 'Hong Kong',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-hong-kong', ['_lg', '_md', '_sm', '_xs'], '.png', 'Autos en la noche de Hong Kong con sus luces características de los carteles ', '_sm'),
            },
            {
              country: 'Tailandia',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-tailandia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Barcaza característica de Tailandia en aguas de Islas Phi Phi', '_sm'),
            },
            {
              country: 'Australia',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-australia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Playa paradisíaca de Australia donde viajeros realizan surf', '_sm'),
            },
            {
              country: 'Rusia',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-rusia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Foto donde viajeros en la plaza roja de Moscú, visitan la Catedral de San Basilio', '_sm'),
            },
            {
              country: 'India',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-india', ['_lg', '_md', '_sm', '_xs'], '.png', 'Viajero contempla el Taj Mahal, una de las 7 maravillas del mundo', '_sm'),
            },
            {
              country: 'Dubai',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-dubai', ['_lg', '_md', '_sm', '_xs'], '.png', 'Paisaje típico de Dubai, con sus rascacielos y autopistas gigantescas', '_sm'),
            },
            {
              country: 'Malasia',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-malasia', ['_lg', '_md', '_sm', '_xs'], '.png', 'Torres Petronas en Kuala Lumpur', '_sm'),
            },
            {
              country: 'Egipto',
              img: new ImageInfo('/assets/imgs/paises/viaja-a-egipto', ['_lg', '_md', '_sm', '_xs'], '.png', 'Pirámides de Egipto junto a la Esfinge. Viajero admirando las pirámides.', '_sm'),
            },
        ]
    }
  ];


  constructor(
    private _grupoService: GruposService,
    private contactService: ContactService,
    public sanitizer: DomSanitizer,
    public globalConstants: GlobalConstants,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private title: Title,
    private meta: Meta
    ) { 
      this.title.setTitle( this.globalConstants.getTitlePrefix() + 'Tu Toque: Tu viaje, Nuestro Toque' );  
      this.meta.updateTag({name: 'description', content: 'Viajá cuando y cómo querés! Vos ponés la fecha, vos ponés los destinos, vos elegís tu presupuesto. Nosotros nos encargamos de organizar tu viaje con nuestro toque'});
      this.meta.updateTag({name: 'keywords', content: 'viajes a medida, viajes de aventura, destinos exóticos, organización de viajes, como organizar mi viaje, destinos baratos'});
    }

  ngOnInit(): void {
    this._grupoService.getViajes().subscribe((res: any) => {
  
        if (res.status == 'success') {
          res.data.forEach(element => {
            if (element.viaje.viajeDestacado) {
              this.viajeDestacado = element;
            } else {
              this.viajes.push(element);  
            }
          });

          this.loading = false;
        }
    });
  }

  getSanitizedUrl( pTxt ) {
    const txt = this.getSlug(pTxt);
    return "/assets/imgs/grupos/" + txt + "/banner.png";
  }

  getSlug( pTxt, separator = '_' ) {
    return pTxt
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join(separator);
  }

  navigate(link:string) {
    if (link != undefined) {
      this.router.navigate([link]);
    }
  }

  getCantDias( pPaises ) {

    let cant = 0;

    pPaises.forEach(pais => {
      pais.ciudades.forEach(ciudad => {
        cant += ciudad.itinerariociudadCantdias;
      });
    });
    
    return cant;
  }

  send() {
    this.fieldsError = '';
    
    if( this.nameTxt != '' && this.mailTxt != '' && this.phoneTxt != '' && this.msgTxt != '' && this.globalConstants.validateEmail( this.mailTxt ) && this.myRecaptcha ) {
      this.loadingContact = true;
      this.contactService.sendContactTutoque(this.nameTxt, this.phoneTxt, this.mailTxt, this.msgTxt).subscribe( (res:any) => {
        this.loadingContact = false;
        if (res.status == 'success') {
          this.successMsg = true;
        } else {
          this.errorMsg = true;
        }
      });
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }


  contactState(event = 'desktop') {
    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('#contactForm').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }
  }

  @HostListener('window:scroll', ['$event'])
  infoFooter(event) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset > 150) {
        this.showInfoFooter = true;
        if (
          window.innerHeight + window.scrollY + 100 >=
          document.body.offsetHeight
        ) {
          this.showInfoFooter = false;
        } else {
          this.showInfoFooter = true;
        }
      } else {
        this.showInfoFooter = false;
      }
  
      let scrollTop = window.pageYOffset;
      let elementOffset:any = document.getElementById('test');
      if ( elementOffset != null && elementOffset != undefined ) {
        elementOffset = elementOffset.getBoundingClientRect().top;
        let distance = (elementOffset - scrollTop);
       
        if (elementOffset < 100) {
          document.body.classList.add('fixed-column');
        } else {
          document.body.classList.remove('fixed-column');
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.remove('fixed-column');
    }
  }
}
